<template>
  <div class="rate-table">
    <iframe
      id="ConstellationAR"
      width="100%"
      height="100%"
      src="https://docs.google.com/spreadsheets/d/1T80Cv0xbh0_Vg-iSJSgwf2QesgyT6IYsT_iW171YJyQ/edit?usp=sharing"></iframe>
  </div>
</template>
<script>
export default {
  name: "ConstellationApprovedRates",
  data() {
    return {};
  },
  mounted() {
    //const intervalId = setInterval(this.refreshRates, 60000);
  },
  methods: {
    refreshRates() {
      document.getElementById("ConstellationAR").src =
        document.getElementById("ConstellationAR").src;
    }
  }
};
</script>
<style>
.rate-table {
  height: 1000px;
}
</style>
