<template>
  <section class="my-customer">
    <div id="utility_loader" style="text-align: center; margin-top: 120px">
      <img src="/images/exp_loader.gif" alt="" style="height: 80px; width: 80px" />
    </div>
    <div class="container" id="utilityDataTable" style="display: none">
      <div class="row">
        <div class="col-md-6 col-6">
          <h3>REFRENCES RATECODES</h3>
        </div>

        <div class="col-md-12 col-12">
          <div class="add-remv">
            <button>
              <router-link
                style="color: white"
                to="/admin/parentratecodes"
                :class="$route.name === '/admin/parentratecodes' ? 'active' : 'inactive'"
                >Parent Ratecodes
              </router-link>
            </button>
          </div>
        </div>

        <div class="col-md-12">
          <div class="cus-table">
            <div class="alert alert-success message" v-if="addmessage" role="alert">
              {{ addmessage }}
            </div>
            <table style="text-align: center" id="myTable">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>RateCode</th>
                  <th>Utility</th>
                  <th>Supplier</th>
                  <th>Parent</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Rate Code Merge Modal Start -->
    <div
      class="modal fade common-pop"
      id="mergeModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="alert alert-success message"
            v-if="successMessagePopup"
            role="alert"
          >
            {{ successMessagePopup }}
          </div>
          <div
            class="alert alert-danger message"
            id="errorLoading"
            v-if="errorMessagePopup"
            role="alert"
          >
            {{ errorMessagePopup }}
          </div>
          <div class="modal-header">
            <h2>Select RateCodes For Merge With</h2>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="p-q">
              <form id="editForm">
                <input
                  type="hidden"
                  class="form-control"
                  name="UtilityRateCodeId"
                  id="UtilityRateCodeId"
                />

                <input
                  type="hidden"
                  class="form-control"
                  name="ChildRateCodeId"
                  id="ChildRateCodeId"
                />

                <div class="row">
                  <div class="form-group col-md-12">
                    <VueMultiselect
                      v-model="selected"
                      :options="rateCodeMerge"
                      :multiple="true"
                      :close-on-select="true"
                      placeholder="Pick Parent RateCodes"
                      label="RateCode"
                      track-by="RateCode"
                      @select="optionSelected"
                      @remove="removeSelected"
                    />

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.parentRateCodeId"
                    >
                      {{ error.parentRateCodeId }}
                    </span>
                  </div>

                  <div class="form-group col-md-12">
                    <div class="c-btn">
                      <button class="delt" @click="closeModal" type="button">
                        Cancel
                      </button>
                      <button class="cncl" type="submit" @click="mergeRateCode">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Rate Code Merge Modal End -->
  </section>
</template>

<script>
import axios from "axios";
import VueMultiselect from "vue-multiselect";
import constant from "../../constant";

export default {
  name: "RateCodes",
  components: {
    VueMultiselect,
  },
  data() {
    return {
      utilityData: [],
      delete_id: "",
      addmessage: null,
      utilityId: "",
      errors: [],
      successMessagePopup: null,
      errorMessagePopup: null,
      states: [],
      newstates: [],
      rateCodeMerge: [],
      edit_status: true,
      delete_id: null,
      editutilitie_name: null,
      edit_product: true,
      selected: null,
      addParentRateCodes: [],
    };
  },

  created() {
    this.getChildRateCodes();
  },

  mounted: function () {
    const table = $(this.$refs.table).dataTable();
    const self = this;

    $("tbody", this.$refs.table).on("click", ".merge-data", function (e) {
      const id = $(this).attr("data-id");
      const parentRateCodeIds = $(this).attr("data-parent-id");
      var childRateCodeId = $(this).attr("data-childRateCodeId");
      self.mergeModel(id, childRateCodeId, parentRateCodeIds);
    });

    $("tbody", this.$refs.table).on("click", ".remove-merge-data", function (e) {
      const RateCodeId = $(this).attr("data-id");
      if ($(this).attr("data-parent-id") === "0") {
        alert("There is no Parent RateCode found");
        return false;
      }
      self.removeMergeRateCode(RateCodeId);
    });
  },

  methods: {
    getParentRateCodeForMerge(id, childRateCodeId, parentId) {
      axios({
        method: "get",
        url: constant.apiUrl + "/getParentRateCodeForMerge",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      })
        .then((result) => {
          if (result.status === 200) {
            if (parentId !== "0") {
              var arraydata = parentId.split(",");
              let parentDataFilter = [];
              let parentIdsFilter = [];
              arraydata.map((item) => {
                const filterdata = result.data.data.filter((element) => {
                  return element.RateCodeId == item;
                });
                if (filterdata[0] !== undefined) {
                  parentDataFilter.push(filterdata[0]);
                }
                parentIdsFilter.push(parseInt(item));
              });

              this.selected = parentDataFilter;

              this.addParentRateCodes = parentIdsFilter;
            }
            // selecting mutliple parent data from dropdown end here

            this.rateCodeMerge = result.data.data;
            $("#UtilityRateCodeId").val(id);
            $("#ChildRateCodeId").val(childRateCodeId);
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    optionSelected(option, id) {
      this.errors = [];
      const addParentRateCodes = this.addParentRateCodes;
      addParentRateCodes.push(option.RateCodeId);
      this.addParentRateCodes = addParentRateCodes;
    },

    removeSelected(option, id) {
      const parentRateCodes = this.addParentRateCodes;
      const index = parentRateCodes.indexOf(option.RateCodeId);
      if (index > -1) {
        // only splice array when item is found
        parentRateCodes.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.addParentRateCodes = parentRateCodes;
    },

    mergeRateCode(e) {
      e.preventDefault();
      this.errors = [];
      var utilityRateCodeId = parseInt($("#UtilityRateCodeId").val());
      var childRateCodeId = parseInt($("#ChildRateCodeId").val());
      const parentRateCodeIds = this.addParentRateCodes;

      if (parentRateCodeIds.length === 0) {
        this.errors.push({ parentRateCodeId: "RateCode is required" });
        return false;
      }

      let text = "Are you sure, you want to merge these Parent RateCodes?";
      if (confirm(text) == false) {
        return false;
      }

      axios({
        method: "post",
        url: constant.apiUrl + "/mergeRateCode",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          utilityRateCodeId: utilityRateCodeId,
          childRateCodeId: childRateCodeId,
          parentRateCodeIds: parentRateCodeIds,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);

            $("#mergeModel").modal("hide");
            $("#myTable").DataTable().destroy();

            this.getChildRateCodes();
            this.resetData();
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    removeMergeRateCode(UtilityRateCodeId) {
      let text = "Are you sure, you want to remove these Parent RateCode?";
      if (confirm(text) == false) {
        return false;
      }

      axios({
        method: "post",
        url: constant.apiUrl + "/removeMergeRate",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },

        data: {
          UtilityRateCodeId: UtilityRateCodeId,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);

            $("#myTable").DataTable().destroy();

            this.getChildRateCodes();
          }

          if (result.status === 202) {
            this.errors = result.data.errors;
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    getChildRateCodes(e) {
      axios({
        method: "get",
        url: constant.apiUrl + "/getChildRateCodes",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {},
      })
        .then((result) => {
          if (result.status === 200) {
            let a = 1;

            $("#utility_loader").hide();
            $("#utilityDataTable").show();

            $("#myTable").DataTable({
              lengthChange: false,
              language: {
                search: "",
              },

              bInfo: false,
              data: result.data.data,
              columns: [
                {
                  render: function (data, type, row, meta) {
                    return a++;
                  },
                },

                {
                  data: "RateCode",
                },

                {
                  data: "Utility",
                },
                {
                  data: "providername",
                },

                {
                  data: "parentRateCode",
                },

                {
                  data: "",
                  name: "",
                  orderable: false,
                  searchable: false,
                  render: function (data, type, row, meta) {
                    let button =
                      `<div class="e-d"> </a> 

                                            <a href="#" class="merge-data" data-id=` +
                      row.UtilityRateCodeId +
                      ` data-childRateCodeId=` +
                      row.RateCodeId +
                      ` data-parent-id=` +
                      row.ParentRateCodeId +
                      ` title="merging" ><img src="/images/merge.png" /></a>

                                            <a href="#" class="remove-merge-data" data-id=` +
                      row.UtilityRateCodeId +
                      ` data-parent-id=` +
                      row.ParentRateCodeId +
                      ` title="remove merging">
                <img src="/images/seperate.png" style="width:38px;"/></a> 
                                            
                                            </div>`;
                    return button;
                  },
                },
              ],
            });
            if ($(window).width() < 1260) {
              $("#myTable").wrapAll('<div style="overflow: scroll;width: 100%">');
            }
            $(".dataTables_filter").addClass("search");
            $(".dataTables_length").addClass("search");
          } else {
            this.errormessage = result.data;
            setTimeout(() => (this.errormessage = false), 3000);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },

    mergeModel(id, childRateCodeId, parentRateCodeIds) {
      this.selected = null;
      this.addParentRateCodes = [];

      $("#mergeModel").modal("show");
      this.getParentRateCodeForMerge(id, childRateCodeId, parentRateCodeIds);
    },

    closeModal() {
      $("#mergeModel").modal("hide");
      $(".form-control").val("");
      this.errors = [];
      this.resetData();
    },

    resetData() {
      this.errors = [];
      $("#utilitie ,#edit_utilitie_name").val();
      $("#display_name, #edit_display_name").val();
      $("#notes_add, #edit_notes").val();
      $("#states_add, #edit_State_ID").val();
      $("#status_add, #edit_status").val();

      $("#UserIdAdded").val("");
      $("#utilityId").val("");
      $("#merge_utility_1").val("");
      $("#merge_utility").val("");
    },
  },
};
</script>

<style>
.dataTables_wrapper .dataTables_filter input {
  background-color: white;
}

.dataTables_wrapper .dataTables_length select {
  background-color: white;
}

div.dataTables_paginate {
  text-align: center;
}

.merge-data img {
  width: 39px;
  height: 40px;
  margin-right: 5px;
  margin-left: 5px;
}
</style>
