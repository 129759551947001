<template>
  <section class="new-quote step-2 step-3">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-40">
          <div v-if="userRole == 1" class="add-remv" style="float: right">
            <router-link :to="{ path: `/admin/customers` }" class="backbutton"
              >Back to Customers
            </router-link>
          </div>

          <div v-if="userRole == 2" class="add-remv" style="float: right">
            <router-link :to="{ path: `/customers` }" class="backbutton"
              >Back to My Customers
            </router-link>
          </div>
          <h3>Customer Details</h3>

          <div class="p-q">
            <form>
              <div class="row mb-40">
                <div class="col-md-12">
                  <h1 v-if="customerData.Product === 1" class="main-head">
                    Contract For Electric Service
                  </h1>
                  <h1 v-else class="main-head">Contract For Gas Service</h1>
                </div>
                <div class="form-group col-md-6">
                  <label>Start Date</label>
                  <input type="text" disabled class="form-control" id="StartDate" />
                </div>
                <div class="form-group col-md-6">
                  <label>End Date</label>
                  <input type="text" disabled class="form-control" id="EndDate" />
                </div>
                <div class="form-group col-md-6">
                  <label>Term</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="customerData.Term"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Supplier</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="customerData.providername"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Utility</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="customerData.DisplayName"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Rate</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="customerData.Rate"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Coustomer Rate(kwh)</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="customerData.CustomersRate"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Margin</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="customerData.Margin"
                  />
                </div>
              </div>
              <div class="row mb-40">
                <div class="col-md-12">
                  <h1 class="main-head">Customer Information</h1>
                </div>
                <div class="form-group col-md-6">
                  <label>Company Name</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="customerData.CompanyName"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Contact</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="
                      customerData.ContactFirstName + ' ' + customerData.ContactLastName
                    "
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Phone</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="customerData.Phone"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Email</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="customerData.Email"
                  />
                </div>
              </div>
              <div class="row mb-40">
                <div class="col-md-12">
                  <h1 class="main-head">Notes</h1>
                </div>
                <div class="form-group col-md-12">
                  <label>Notes</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    id="desc-Notes"
                    :value="customerData.Notes"
                  />
                </div>
              </div>
              <div class="row mb-40">
                <div class="col-md-12">
                  <h1 class="main-head">Billing Information</h1>
                </div>
                <div class="form-group col-md-6">
                  <label>Address</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="
                      customerData.Street +
                      ' ' +
                      '' +
                      customerData.City +
                      ', ' +
                      customerData.StateCode +
                      ' ' +
                      customerData.Zip
                    "
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>City/State/Zip</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="
                      customerData.City +
                      ', ' +
                      customerData.StateCode +
                      ' ' +
                      customerData.Zip
                    "
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <h1 class="main-head">Service Address & Account Number</h1>
                </div>

                <div class="col-md-12" v-for="item in addressData">
                  <div class="tbl-2">
                    <table id="serviceAddressTable">
                      <tr>
                        <th>Number</th>
                        <th v-if="customerData.Product === 1">Rate Class</th>
                        <th>Usage</th>
                        <th v-if="accountOrEsid == '1'">Account Number</th>
                        <th v-else>Account Number</th>
                        <th>Address</th>
                        <th>City/State/Zip</th>
                        <th>Bill Copy Image</th>
                      </tr>
                      <tr>
                        <td>{{ item.sno }}</td>
                        <td v-if="customerData.Product === 1">{{ item.ratetext }}</td>
                        <td>{{ item.annualusage }}</td>
                        <td :id="`service-account-${item.sno}`">{{ item.esid }}</td>
                        <td :id="`service-address-${item.sno}`">{{ item.address }}</td>
                        <td
                          :id="`service-citystatezip-${item.sno}`"
                          v-if="item.city !== ''"
                        >
                          {{ item.city }}, {{ item.statetext }} {{ item.zip }}
                        </td>
                        <td :id="`service-citystatezip-${item.sno}`" v-else></td>

                        <td v-if="item.billcopyimage === ''">No Image</td>

                        <td v-else>
                          <div>
                            <a
                              href="javascript:void(0)"
                              @click="billCopyImage(item.billcopyimage)"
                              ><img
                                :src="item.billcopyimage"
                                style="width: 47px"
                                alt="bllcopyimage"
                            /></a>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Add Bill Copy Model Start -->
  <div
    class="modal fade common-pop"
    id="BillCopyImageModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
      style="
        max-width: 100%;
        margin: 50px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 88vh;
        display: flex;
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h2>Bill Copy Image</h2>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <div class="p-q">
            <div class="row">
              <div class="form-group col-md-12">
                <img class="image-box" style="max-width: 100%" />
              </div>
            </div>
            <br />
            <div class="row">
              <div class="form-group col-md-12">
                <div class="c-btn">
                  <button class="delt" @click="closeModal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Bill Copy model End -->
</template>

<script>
import axios from "axios";
import constant from "../../constant";
import moment from "moment";
export default {
  name: "CustomerViewPage",

  data() {
    return {
      delete_id: "",
      addmessage: null,
      CompanyName: "",
      allAccountRateClassAnnualUsageData: [],
      CustomerId: "",
      customerData: [],
      addressData: [],
    };
  },

  props: {
    CustomerId: String,
  },
  created() {
    const role = JSON.parse(localStorage.getItem("role"));
    this.userRole = role;
  },

  mounted: function () {
    var CustomerId = localStorage.getItem("Customer_new_id");
    this.viewCustomer(CustomerId);
  },

  methods: {
    viewCustomer(id) {
      this.CustomerId = id;
      axios({
        method: "post",
        url: constant.apiUrl + "/editCustomer",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          CustomerId: this.CustomerId,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.customerData = result.data.CustomerData;
            this.addressData = result.data.serviceBilCopyData;
            $("#StartDate").val(
              moment(String(result.data.CustomerData.StartDate))
                .utcOffset(330)
                .format("DD-MM-YYYY")
            );
            $("#EndDate").val(
              moment(String(result.data.CustomerData.EndDate))
                .utcOffset(330)
                .format("DD-MM-YYYY")
            );
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    checkbox() {
      var value = $("#topcheckbox").val();
      if (value == 0) {
        $(".allcheck").prop("checked", true);
        $("#topcheckbox").val("1");
      } else {
        $(".allcheck").prop("checked", false);

        $("#topcheckbox").val("0");
      }
    },

    closeModal() {
      $("#BillCopyImageModel").modal("hide");
    },
    billCopyImage(imageSrc) {
      $(".image-box").attr("src", imageSrc);
      $("#BillCopyImageModel").modal("show");
    },
  },
};
</script>

<style>
.dataTables_wrapper .dataTables_filter input {
  background-color: white;
}

.dataTables_wrapper .dataTables_length select {
  background-color: white;
}
div.dataTables_paginate {
  text-align: center;
}
</style>
