import { createWebHistory, createRouter } from "vue-router";
import Login from "@/components/Login.vue";
import Adminlogin from "@/components/Admin/Adminlogin.vue";
import Dashboard from "@/components/Dashboard.vue";
import Profile from "@/components/Profile.vue";
import Users from "@/components/Admin/Users.vue";
import Imports from "@/components/Admin/Imports.vue";
import Customers from "@/components/Admin/Customers.vue";
import Utilities from "@/components/Admin/Utilities.vue";
import ParentUtilities from "@/components/Admin/ParentUtilities.vue";
import ParentRateCodes from "@/components/Admin/ParentRateCodes.vue";
import RateCodes from "@/components/Admin/RateCodes.vue";
import ManageMatrix from "@/components/Admin/ManageMatrix.vue";
import Providers from "@/components/Admin/Providers.vue";
import Pricequote from "@/components/Agent/Pricequote.vue";
import NotFound from "@/components/NotFound.vue";
import CustomerViewPage from "@/components/Admin/CustomerViewPage.vue";
import SupplierUtilityApprovedRate from "@/components/Admin/SupplierUtilityApprovedRate.vue";
import UtilitiesAccount from "@/components/Admin/UtilitiesAccount.vue";
import ContractPackages from "@/components/Admin/ContractPackages.vue";
import SantannaApprovedRates from "@/components/ApprovedRates/SantannaApproved.vue"
import ConstellationApprovedRates from "@/components/ApprovedRates/ConstellationApproved.vue"
import AEPApprovedRates from "@/components/ApprovedRates/AEPApproved.vue"
import EngieApprovedRates from "@/components/ApprovedRates/EngieApproved.vue"
import SFEApprovedRates from "@/components/ApprovedRates/SFEApproved.vue"
import SparkApprovedRates from "@/components/ApprovedRates/SparkApproved.vue"
import ProviderUtility from "@/components/Admin/ProviderUtility.vue"


const routes = [
  {
    path: "/",
    name: "Home",
    component: Dashboard
  },

  {
    path: "/dashboard",
    name: "/agent/dashboard",
    component: Dashboard
  },

  {
    path: "/profile",
    name: "/agent/profile",
    component: Profile
  },

  {
    path: "/pricequote",
    name: "/agent/pricequote",
    component: Pricequote,
    props: (route) => ({ ...route.query })
  },

  {
    path: "/customers",
    name: "/agent/customers",
    component: Customers
  },

  {
    path: "/customerViewPage",
    name: "/agent/customerViewPage",
    component: CustomerViewPage
  },

  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/ApprovedRates/Santanna",
    name: "Santanna Approved Rates",
    component: SantannaApprovedRates
  },
  {
    path: "/ApprovedRates/SFE",
    name: "SFE Approved Rates",
    component: SFEApprovedRates
  },
  {
    path: "/ApprovedRates/Spark",
    name: "Spark Approved Rates",
    component: SparkApprovedRates
  },
  {
    path: "/ApprovedRates/Engie",
    name: "Engie Approved Rates",
    component: EngieApprovedRates
  },
  {
    path: "/ApprovedRates/Constellation",
    name: "Constellation Approved Rates",
    component: ConstellationApprovedRates
  },
  {
    path: "/ApprovedRates/AEP",
    name: "AEP Approved Rates",
    component: AEPApprovedRates
  },

  // Admin Routes Start here
  {
    path: "/admin/dashboard",
    name: "/admin/dashboard",
    component: Dashboard
  },

  {
    path: "/admin/profile",
    name: "/admin/profile",
    component: Profile
  },

  {
    path: "/admin/users",
    name: "/admin/users",
    component: Users
  },

  {
    path: "/admin/customers",
    name: "/admin/customers",
    component: Customers
  },

  {
    path: "/admin/utilities",
    name: "/admin/utilities",
    component: Utilities
  },

  {
    path: "/admin/parentutilities",
    name: "/admin/parentutilities",
    component: ParentUtilities
  },
  {
    path: "/admin/providerutility",
    name: "/admin/providerutility",
    component: ProviderUtility
  },

  {
    path: "/admin/manageMatrix",
    name: "/admin/manageMatrix",
    component: ManageMatrix
  },

  {
    path: "/admin/providers",
    name: "/admin/providers",
    component: Providers
  },

  {
    path: "/admin/customerViewPage",
    name: "/admin/customerViewPage",
    component: CustomerViewPage
  },

  {
    path: "/admin/parentratecodes",
    name: "/admin/parentratecodes",
    component: ParentRateCodes
  },

  {
    path: "/admin/ratecodes",
    name: "/admin/ratecodes",
    component: RateCodes
  },

  {
    path: "/admin/imports",
    name: "/admin/imports",
    component: Imports
  },

  {
    path: "/admin/login",
    name: "adminlogin",
    component: Adminlogin
  },

  {
    path: "/admin/approverate",
    name: "/admin/approverate",
    component: SupplierUtilityApprovedRate
  },

  {
    path: "/admin/utilitiesAccount",
    name: "/admin/utilitiesAccount",
    component: UtilitiesAccount
  },
  {
    path: "/admin/contractpackages",
    name: "/admin/contractpackages",
    component: ContractPackages
  },
  // Admin Routes End here

  { path: "/:catchAll(.*)", name: "pagenotfound", component: NotFound } // 404 Page Not found Routing
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  let web = ["login", "adminlogin"];
  let admin = [
    "/admin/users",
    "/admin/utilities",
    "/admin/parentutilities",
    "/admin/providerutility",
    "/admin/dashboard",
    "/admin/profile",
    "/admin/manageMatrix",
    "/admin/imports",
    "/admin/ratecodes",
    "/admin/parentratecodes",
    "/admin/providers",
    "/admin/customers",
    "/admin/customerViewPage",
    "/admin/approverate",
    "/admin/utilitiesAccount",
    "/admin/contractpackages"
  ];

  if (web.includes(to.name)) {
    next();
  } else if (admin.includes(to.name)) {
    if (localStorage.getItem("role") == "1" && localStorage.getItem("token")) {
      next();
    } else if (
      localStorage.getItem("role") == "2" &&
      localStorage.getItem("token")
    ) {
      next({ name: "/agent/dashboard" });
    } else {
      next({ name: "login" });
    }
  } else {
    if (localStorage.getItem("token")) {
      next();
    } else {
      next({ name: "login" });
    }
  }
});

export default router;
