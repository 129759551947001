<template>
  <section class="d-head">
    <div class="container">
      <div class="header">
        <div class="logo" v-if="userRole == 2">
          <router-link to="/dashboard"
            ><img src="/images/logo.png" class="img-fluid"
          /></router-link>
        </div>
        <div class="logo" v-if="userRole == 1">
          <router-link to="/admin/dashboard"
            ><img src="/images/logo.png" class="img-fluid"
          /></router-link>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <i class="fas fa-bars" aria-hidden="true"></i>
        </button>
        <div
          class="dash-menu collapse navbar-collapse"
          id="navbarSupportedContent">
          <ul>
            <li v-if="userRole == 2">
              <router-link
                to="/dashboard"
                :class="
                  $route.name === '/agent/dashboard' ? 'active' : 'inactive'
                "
                >Dashboard</router-link
              >
            </li>
            <li v-if="userRole == 2">
              <a
                href="javascript:void(0)"
                :class="
                  $route.name === '/agent/pricequote' ? 'active' : 'inactive'
                "
                @click="redirectPriceQuote">
                Price Quote</a
              >
            </li>

            <li v-if="userRole == 2">
              <router-link
                to="/customers"
                :class="
                  $route.name === '/agent/customers' ? 'active' : 'inactive'
                "
                >My Customers</router-link
              >
            </li>
            <li v-if="userRole == 1">
              <router-link
                to="/admin/dashboard"
                :class="
                  $route.name === '/admin/dashboard' ? 'active' : 'inactive'
                "
                >Dashboard</router-link
              >
            </li>
            <li v-if="userRole == 1">
              <router-link
                to="/admin/users"
                :class="$route.name === '/admin/users' ? 'active' : 'inactive'"
                >Users</router-link
              >
            </li>
            <li v-if="userRole == 1">
              <router-link
                to="/admin/imports"
                :class="
                  $route.name === '/admin/imports' ? 'active' : 'inactive'
                "
                >Imports</router-link
              >
            </li>
            <li v-if="userRole == 1">
              <router-link
                to="/admin/providers"
                :class="
                  $route.name === '/admin/providers' ? 'active' : 'inactive'
                "
                >Suppliers</router-link
              >
            </li>

            <li v-if="userRole == 1">
              <router-link
                to="/admin/customers"
                :class="
                  $route.name === '/admin/customers' ? 'active' : 'inactive'
                "
                >Customers</router-link
              >
            </li>

            <li v-if="userRole == 1">
              <router-link
                to="/admin/utilities"
                :class="
                  $route.name === '/admin/utilities' ? 'active' : 'inactive'
                "
                >Utilities</router-link
              >
            </li>

            <div class="nav-item-toggle-container">
              <button
                class="toggle-nav-item"
                v-if="userRole == 1"
                @click="toggleNavItem()">
                {{ toggleText }}
              </button>
              <div :class="isToggleOpen ? 'nav-item-open' : 'nav-item-closed'">
                <li class="toggle-item" v-if="userRole == 1">
                  <router-link
                    class="toggle-link"
                    to="/admin/providerutility?id=1"
                    >Electric Utilities</router-link
                  >
                </li>
                <li class="toggle-item" v-if="userRole == 1">
                  <router-link
                    class="toggle-link"
                    to="/admin/providerutility?id=2"
                    >Gas Utilities</router-link
                  >
                </li>
              </div>
            </div>

            <li v-if="userRole == 1">
              <router-link
                to="/admin/ratecodes"
                :class="
                  $route.name === '/admin/ratecodes' ? 'active' : 'inactive'
                "
                >RateCodes</router-link
              >
            </li>
            <li v-if="userRole == 1">
              <router-link
                to="/admin/contractpackages"
                :class="
                  $route.name === '/admin/contractpackages'
                    ? 'active'
                    : 'inactive'
                "
                >Contracts</router-link
              >
            </li>
          </ul>
        </div>
        <div class="profile">
          <div class="profile-detail">
            <div class="p-name">
              <h6>
                Hello,
                <div class="profile-name">{{ firstName }}</div>
              </h6>
              <img src="/images/sample.png" />
            </div>
            <div class="p-drop">
              <ul>
                <li v-if="userRole == 1">
                  <router-link
                    to="/admin/profile"
                    :class="
                      $route.name === '/admin/profile' ? 'active' : 'inactive'
                    "
                    >Profile</router-link
                  >
                </li>

                <li v-if="userRole == 2">
                  <router-link
                    to="/profile"
                    :class="
                      $route.name === '/agent/profile' ? 'active' : 'inactive'
                    "
                    >Profile</router-link
                  >
                </li>
                <li>
                  <a href="javascript:void(0);" @click="logout">Logout</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import constant from "../../constant";
export default {
  name: "Navbar",
  data() {
    return {
      firstName: "",
      baseURL: constant.baseUrl,
      isToggleOpen: false,
      toggleText: this.isToggleOpen ? "Toggle Utilities▲" : "Toggle Utilities▼"
    };
  },
  created() {
    const role = JSON.parse(localStorage.getItem("role"));
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.userRole = role;
  },
  mounted() {
    $(document).on("click", "li", function (e) {
      e.preventDefault();
      $(".navbar-toggler").addClass("collapsed");
      $(".navbar-toggler").attr("aria-expanded", "false");
      $("#navbarSupportedContent").removeClass("show");
      $("#navbarSupportedContent").addClass("collapse");
    });

    this.getUserProfile();
  },
  methods: {
    redirectPriceQuote() {
      location.href = "/pricequote";
    },
    toggleNavItem() {
      this.isToggleOpen = !this.isToggleOpen;
    },
    getUserProfile() {
      axios({
        method: "post",
        url: constant.apiUrl + "/getUserProfile",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token")
        },
        data: {
          user_id: localStorage.getItem("userId"),
          user_role: localStorage.getItem("role")
        }
      })
        .then((result) => {
          if (result.status === 200) {
            this.firstName = result.data.data.firstName;
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    logout(e) {
      e.preventDefault();
      localStorage.removeItem("token");
      if (localStorage.getItem("role") == "1") {
        this.$router.push("/admin/login");
      } else {
        this.$router.push("/login");
      }
      localStorage.removeItem("role");
      localStorage.removeItem("userId");
      localStorage.removeItem("userData");
      localStorage.removeItem("finalData");
      localStorage.removeItem("serviceAddressData");
      localStorage.removeItem("Customer_new_id");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
    }
  }
};
</script>

<style>
.nav-item-toggle-container {
  display: flex;
  flex-direction: column;
  height: auto;
}

.nav-item-open {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 140px;
  margin-top: 5px;
  background-color: #f3f3f3;
}
.nav-item-closed {
  display: none;
}

.toggle-nav-item {
  color: #515c5c;
  font-size: 14px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif !important;
  background: none !important;
  border: none !important;
}
.toggle-nav-item:hover {
  cursor: pointer;
  border: none !important;
}

.toggle-item {
  margin-top: 15px;
}
.toggle-link {
  padding: 0 !important;
  height: fit-content !important;
}
</style>
