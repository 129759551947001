<template>
  <section class="my-customer">
    <div id="pageLoader" style="text-align: center; margin-top: 120px">
      <img src="/exp_loader.gif" style="height: 80px; width: 80px" />
    </div>
    <div class="container" id="containerHide" style="display: none">
      <div class="row">
        <div class="col-md-6 col-6">
          <h3>USERS</h3>
        </div>

        <div class="col-md-6 col-6">
          <div class="add-remv">
            <button @click="showModal">+ Add User</button>
            <a href="javascript:void(0)" @click="multiDelete"
              ><img src="/images/remove.png"
            /></a>
          </div>
        </div>

        <div class="col-md-12">
          <div class="cus-table">
            <div class="alert alert-success message" v-if="addmessage" role="alert">
              {{ addmessage }}
            </div>
            <table style="text-align: center" id="myTable">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      @change="checkbox()"
                      id="topcheckbox"
                      value="0"
                    />
                  </th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>User Name</th>
                  <th>User Type</th>
                  <th>Status</th>
                  <th>Allow LCE Look Up</th>
                  <th>Show Customer Rate</th>
                  <th>Show Margin</th>
                  <th>Show Sale Type</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- delete model -->
  <div
    class="modal fade"
    id="deletemodel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <h4>Are you sure, you want to delete User?</h4>
          <div class="c-btn">
            <button class="cncl" @click="closeModal">Cancel</button>
            <button class="delt" @click="userDelete">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end delete model -->

  <!-- Modal -->
  <!-- edit Modal -->
  <div
    class="modal fade common-pop add-us"
    id="editModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update User Details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="closeModal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="p-q">
            <form id="editForm">
              <div class="row">
                <div class="form-group col-md-6">
                  <label>First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Please Enter First Name"
                    id="firstName"
                    ref="firstName"
                  />
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.firstName"
                  >
                    {{ error.firstName }}
                  </span>
                </div>

                <div class="form-group col-md-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Please Enter Last Name"
                    id="lastName"
                    ref="lastName"
                  />
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.lastName"
                  >
                    {{ error.lastName }}
                  </span>
                </div>

                <div class="form-group col-md-6">
                  <label>User Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Please Enter User Name"
                    id="userName"
                    ref="userName"
                  />
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.userName"
                  >
                    {{ error.userName }}
                  </span>
                </div>

                <div class="form-group col-md-6">
                  <label>Password</label>
                  <input
                    type="password"
                    class="form-control changePassword"
                    placeholder="Please Enter Password"
                    id="password"
                    ref="password"
                  />
                  <span
                    toggle="#password"
                    class="fa fa-fw fa-eye field-icon toggle-password"
                  ></span>
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.password"
                  >
                    {{ error.password }}
                  </span>
                </div>

                <div class="form-group col-md-4">
                  <label>Email</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Please Enter Email"
                    id="email"
                    ref="email"
                  />
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.email"
                  >
                    {{ error.email }}
                  </span>
                </div>

                <div class="form-group col-md-4">
                  <label>Phone</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Please Enter Phone"
                    id="phone"
                    ref="phone"
                  />
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.phone"
                  >
                    {{ error.phone }}
                  </span>
                </div>

                <div class="form-group col-md-4">
                  <label>Fax</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Please Enter Fax"
                    id="fax"
                    ref="fax"
                  />
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.fax"
                  >
                    {{ error.fax }}
                  </span>
                </div>

                <div class="form-group col-md-6" id="userTypeSelect">
                  <label>User Type</label>
                  <select
                    class="form-control"
                    id="usertype"
                    ref="userType"
                    :value="userRole"
                    @change="editgetAndSetOnChange($event)"
                  >
                    <option value="1">Admin</option>
                    <option value="2">Agent</option>
                  </select>
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.userType"
                  >
                    {{ error.userType }}
                  </span>
                </div>

                <div class="form-group col-md-6" id="statusSelect">
                  <label>Status</label>
                  <select
                    class="form-control"
                    id="status"
                    ref="status"
                    :value="userStatus"
                  >
                    <option value="1">Enabled</option>
                    <option value="0">Disabled</option>
                  </select>
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.status"
                  >
                    {{ error.status }}
                  </span>
                </div>

                <div class="form-group col-md-12">
                  <div class="form-check" style="display: none" id="allowLCELookUpDiv">
                    <input type="checkbox" id="allowLCELookUp" />
                    <label class="checkboxLebel">Allow LCE Lookup</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" id="showCustomerRate" />
                    <label class="checkboxLebel">Show Customer Rate</label>
                  </div>

                  <div class="form-check">
                    <input type="checkbox" id="showMargin" />
                    <label class="checkboxLebel">Show Margin</label>
                  </div>

                  <div class="form-check">
                    <input type="checkbox" id="showSaleType" />
                    <label class="checkboxLebel">Show Sale Type</label>
                  </div>
                </div>

                <div class="form-group col-md-12">
                  <div class="c-btn">
                    <button class="delt" @click="closeModal" type="button">Cancel</button>
                    <button class="cncl" type="submit" @click="updateUser">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- edit Modal -->

  <!-- Add Modal -->
  <div
    class="modal fade common-pop add-us"
    id="addModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add User Details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="closeAddModal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="p-q">
            <form id="addForm">
              <div class="row">
                <div class="form-group col-md-6">
                  <label>First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Please Enter First Name"
                    id="addfirstName"
                    ref="addfirstName"
                  />
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.addfirstName"
                  >
                    {{ error.addfirstName }}
                  </span>
                </div>

                <div class="form-group col-md-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="please Enter Last Name"
                    id="addlastName"
                    ref="addlastName"
                  />
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.addlastName"
                  >
                    {{ error.addlastName }}
                  </span>
                </div>

                <div class="form-group col-md-6">
                  <label>User Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Please Enter User Name"
                    id="adduserName"
                    ref="adduserName"
                  />
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.adduserName"
                  >
                    {{ error.adduserName }}
                  </span>
                </div>

                <div class="form-group col-md-6">
                  <label>Password</label>
                  <input
                    type="password"
                    class="form-control changePassword"
                    placeholder="Please Enter Password"
                    id="addpassword"
                    ref="addpassword"
                  />
                  <span
                    toggle="#addpassword"
                    class="fa fa-fw fa-eye field-icon toggle-password"
                  ></span>

                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.addpassword"
                  >
                    {{ error.addpassword }}
                  </span>
                </div>

                <div class="form-group col-md-4">
                  <label>Email</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Please Enter Email"
                    id="addemail"
                    ref="addemail"
                  />
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.addemail"
                  >
                    {{ error.addemail }}
                  </span>
                </div>

                <div class="form-group col-md-4">
                  <label>Phone</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Please Enter Phone"
                    id="addphone"
                    ref="addphone"
                  />
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.addphone"
                  >
                    {{ error.addphone }}
                  </span>
                </div>

                <div class="form-group col-md-4">
                  <label>Fax</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Please Enter Fax"
                    id="addfax"
                    ref="addfax"
                  />
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.addfax"
                  >
                    {{ error.addfax }}
                  </span>
                </div>

                <div class="form-group col-md-6" id="userTypeSelect">
                  <label>User Type</label>
                  <select
                    class="form-control"
                    id="adduserType"
                    ref="adduserType"
                    @change="getAndSetOnChange($event)"
                  >
                    <option selected value="0">Choose User Type</option>
                    <option value="1">Admin</option>
                    <option value="2">Agent</option>
                  </select>
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.adduserType"
                  >
                    {{ error.adduserType }}
                  </span>
                </div>

                <div class="form-group col-md-6" id="statusSelect">
                  <label>Status</label>
                  <select class="form-control" id="addstatus" ref="addstatus">
                    <option selected value="1">Enabled</option>
                    <option value="0">Disabled</option>
                  </select>
                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.addstatus"
                  >
                    {{ error.addstatus }}
                  </span>
                </div>

                <div class="form-group col-md-12">
                  <div class="form-check" style="display: none" id="addallowLCELookUpDiv">
                    <input type="checkbox" id="addallowLCELookUp" class="addcheckbox" />
                    <label class="checkboxLebel">Allow LCE Lookup</label>
                  </div>
                  <div
                    class="form-check"
                    style="display: none"
                    id="addshowCustomerRateDiv"
                  >
                    <input type="checkbox" id="addshowCustomerRate" class="addcheckbox" />
                    <label class="checkboxLebel">Show Customer Rate</label>
                  </div>

                  <div class="form-check" style="display: none" id="addshowMarginpDiv">
                    <input type="checkbox" id="addshowMargin" class="addcheckbox" />
                    <label class="checkboxLebel">Show Margin</label>
                  </div>

                  <div class="form-check" style="display: none" id="addshowSaleTypeDiv">
                    <input type="checkbox" id="addshowSaleType" class="addcheckbox" />
                    <label class="checkboxLebel">Show Sale Type</label>
                  </div>
                </div>

                <div class="form-group col-md-12">
                  <div class="c-btn">
                    <button class="delt" @click="closeAddModal" type="button">
                      Cancel
                    </button>
                    <button class="cncl" type="submit" @click="addUser">Add</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- edit Modal -->
</template>

<script>
import axios from "axios";
import constant from "../../constant";

export default {
  name: "Users",

  data() {
    return {
      usersdata: [],
      delete_id: "",
      addmessage: null,
      userId: "",
      errors: [],
      userStatus: "",
      userRole: "",
      editUserName: "",
      editEmail: "",
      baseURL: constant.baseUrl,
    };
  },

  created() {
    this.getUsers();
  },
  mounted: function () {
    const table = $(this.$refs.table).dataTable();
    const self = this;

    $("tbody", this.$refs.table).on("click", ".delete-data", function (e) {
      const role = $(this).attr("data-role").split(",");
      if (role == "1") {
        alert("Admin can not be deleted!");
        return false;
      }
      const id = $(this).attr("data-id").split(",");
      self.deleteUser(id);
    });

    $("tbody", this.$refs.table).on("click", ".edit-data", function (e) {
      const id = $(this).attr("data-id").split(",");
      self.editUser(id);
    });

    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  },
  methods: {
    getUsers(e) {
      axios({
        method: "get",
        url: constant.apiUrl + "/getusers",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {},
      })
        .then((result) => {
          if (result.status === 200) {
            $("#pageLoader").hide();
            $("#containerHide").show();

            $("#myTable").DataTable({
              lengthChange: false,
              language: { search: "" },
              bInfo: false,
              data: result.data.data,
              columns: [
                {
                  data: "",
                  name: "",
                  orderable: false,
                  searchable: false,
                  render: function (data, type, row, metaa) {
                    if (row.role === 1) {
                      var checkbox =
                        `<input type="checkbox" class="a"disabled  data-role=` +
                        row.role +
                        `
                       value=` +
                        row.id +
                        ` />`;
                    } else {
                      var checkbox =
                        `<input type="checkbox" class="allcheck"  data-role=` +
                        row.role +
                        `
                       value=` +
                        row.id +
                        ` />`;
                    }
                    return checkbox;
                  },
                },
                { data: "firstName" },
                { data: "lastName" },
                { data: "userName" },

                {
                  render: function (data, type, row, meta) {
                    let role = "";
                    if (row.role === 1) {
                      role = "Admin";
                    } else {
                      role = "Agent";
                    }
                    return role;
                  },
                },
                {
                  render: function (data, type, row, meta) {
                    let status = "";
                    if (row.enabled === true) {
                      status = "Enabled";
                    } else {
                      status = "Disabled";
                    }
                    return status;
                  },
                },

                {
                  render: function (data, type, row, meta) {
                    let allowLCELookUp = "";
                    if (row.allowLCELookUp === true) {
                      allowLCELookUp = "Yes";
                    } else {
                      allowLCELookUp = "No";
                    }
                    return allowLCELookUp;
                  },
                },
                {
                  render: function (data, type, row, meta) {
                    let showCustomerRate = "";
                    if (row.showCustomerRate === true) {
                      showCustomerRate = "Yes";
                    } else {
                      showCustomerRate = "No";
                    }
                    return showCustomerRate;
                  },
                },
                {
                  render: function (data, type, row, meta) {
                    let showMargin = "";
                    if (row.showMargin === true) {
                      showMargin = "Yes";
                    } else {
                      showMargin = "No";
                    }
                    return showMargin;
                  },
                },

                {
                  render: function (data, type, row, meta) {
                    let showSaleType = "";
                    if (row.showSaleType === true) {
                      showSaleType = "Yes";
                    } else {
                      showSaleType = "No";
                    }
                    return showSaleType;
                  },
                },

                {
                  data: "",
                  name: "",
                  orderable: false,
                  searchable: false,
                  render: function (data, type, row, meta) {
                    let button =
                      `<div class="e-d">
                      <a href="#"class="edit-data" data-id=` +
                      row.id +
                      `><img src="/images/edit.png" /></a
                      ><a href="#" class="delete-data" data-id=` +
                      row.id +
                      ` data-role=` +
                      row.role +
                      `><img src="/images/dlt.png" /></a>
                    </div>`;
                    return button;
                  },
                },
              ],
            });
            if ($(window).width() < 1260) {
              $("#myTable").wrapAll('<div style="overflow: scroll;width: 100%">');
            }
            $(".dataTables_filter").addClass("search");
            $(".dataTables_length").addClass("search");
          } else {
            this.errormessage = result.data;
            setTimeout(() => (this.errormessage = false), 3000);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    checkbox() {
      var value = $("#topcheckbox").val();
      if (value == 0) {
        $(".allcheck").prop("checked", true);
        $("#topcheckbox").val("1");
      } else {
        $(".allcheck").prop("checked", false);
        $("#topcheckbox").val("0");
      }
    },

    closeModal() {
      $("#deletemodel").modal("hide");
      $("#editModel").modal("hide");
      this.errors = [];
      $(".toggle-password").removeClass("fa-eye-slash").addClass("fa-eye");
      $(".changePassword").attr("type", "password");
    },
    closeAddModal() {
      $("#addModel").modal("hide");
      $("#addForm").trigger("reset");
      this.errors = [];
      $(
        "#addallowLCELookUpDiv,#addshowCustomerRateDiv,#addshowMarginpDiv,#addshowSaleTypeDiv"
      ).css("display", "none");
      $(".addcheckbox").prop("checked", false); // Unchecks it
      $(".toggle-password").removeClass("fa-eye-slash").addClass("fa-eye");
      $(".changePassword").attr("type", "password");
    },

    //delete User//
    deleteUser(id) {
      $("#deletemodel").modal("show");
      this.delete_id = id;
    },
    userDelete(e) {
      axios({
        method: "delete",
        url: constant.apiUrl + "/deleteUser",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: { id: this.delete_id, deletedById: localStorage.getItem("userId") },
      })
        .then((result) => {
          if (result.status == 200) {
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);
            $("#deletemodel").modal("hide");
            $("#myTable").DataTable().destroy();
            this.getUsers();
          }
        })
        .catch((error) => {
          if (error) {
            this.error = error;
          }
        });
    },

    multiDelete(e) {
      var deleteids_arr = [];
      // Read all checked checkboxes
      $("input:checkbox[class=allcheck]:checked").each(function () {
        deleteids_arr.push($(this).val());
      });
      // Check checkbox checked or not
      if (deleteids_arr.length > 0) {
        this.deleteUser(deleteids_arr);
      } else {
        alert("Please select at least one user!");
      }
    },
    editUser(id) {
      this.userId = id;
      axios({
        method: "post",
        url: constant.apiUrl + "/editUser",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          userId: this.userId,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.editEmail = result.data.data.email;
            this.editUserName = result.data.data.userName;
            this.userRole = result.data.data.role;
            if (result.data.data.enabled == true) {
              this.userStatus = 1;
            } else if (result.data.data.enabled == false) {
              this.userStatus = 0;
            }
            $("#firstName").val(result.data.data.firstName);
            $("#lastName").val(result.data.data.lastName);
            $("#userName").val(result.data.data.userName);
            $("#password").val(result.data.data.password);
            $("#email").val(result.data.data.email);
            $("#phone").val(result.data.data.phone);
            $("#fax").val(result.data.data.fax);

            if (result.data.data.role === 1) {
              $("#allowLCELookUpDiv").css("display", "none");
            } else if (result.data.data.role === 2) {
              $("#allowLCELookUpDiv").css("display", "block");
            }

            $("#allowLCELookUp").prop("checked", result.data.data.allowLCELookUp);
            $("#showCustomerRate").prop("checked", result.data.data.showCustomerRate);
            $("#showMargin").prop("checked", result.data.data.showMargin);
            $("#showSaleType").prop("checked", result.data.data.showSaleType);
            $("#editModel").modal("show");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    updateUser(e) {
      e.preventDefault();
      this.errors = [];
      if (!this.$refs["firstName"].value) {
        this.errors.push({ firstName: "First Name is required" });
        return false;
      }
      if (!this.$refs["lastName"].value) {
        this.errors.push({ lastName: "Last Name is required" });
        return false;
      }
      if (!this.$refs["userName"].value) {
        this.errors.push({ userName: "User Name is required" });
        return false;
      }
      if (!this.$refs["password"].value) {
        this.errors.push({ password: "Password is required" });
        return false;
      }
      if (!this.$refs["email"].value) {
        this.errors.push({ email: "Email is required" });
        return false;
      }

      var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      if (!pattern.test(this.$refs["email"].value)) {
        this.errors.push({ email: "Email must be valid format" });
        return false;
      }

      if (this.$refs["userType"].value == "0") {
        this.errors.push({ userType: "UserType is required" });
        return false;
      }
      if (this.$refs["status"].value == "3") {
        this.errors.push({ status: "Status is required" });
        return false;
      }
      this.userStatus = this.$refs["status"].value;
      this.userRole = this.$refs["userType"].value;

      axios({
        method: "post",
        url: constant.apiUrl + "/updateUser",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          userId: this.userId,
          firstName: this.$refs["firstName"].value,
          lastName: this.$refs["lastName"].value,
          userName: this.$refs["userName"].value,
          password: this.$refs["password"].value,
          email: this.$refs["email"].value,
          phone: this.$refs["phone"].value,
          fax: this.$refs["fax"].value,
          role: this.$refs["userType"].value,
          enabled: this.$refs["status"].value,
          allowLCELookUp: $("#allowLCELookUp").is(":checked"),
          showCustomerRate: $("#showCustomerRate").is(":checked"),
          showMargin: $("#showMargin").is(":checked"),
          showSaleType: $("#showSaleType").is(":checked"),
          editUserName: this.editUserName,
          editEmail: this.editEmail,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);
            $("#editForm").trigger("reset");
            $("#editModel").modal("hide");
            $("#myTable").DataTable().destroy();
            $(".toggle-password").removeClass("fa-eye-slash").addClass("fa-eye");
            $(".changePassword").attr("type", "password");
            this.getUsers();
          } else if (result.status === 202) {
            this.errors.push({ userName: "User Name must be unique" });
            return false;
            // }
          } else if (result.status === 203) {
            this.errors.push({ email: "Email must be unique" });
            return false;
          } else {
            this.errorMessagePopup = result.data.message;
            setTimeout(() => (this.errorMessagePopup = false), 3000);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    getAndSetOnChange(event) {
      if (event.target.value == "2") {
        $(
          "#addallowLCELookUpDiv,#addshowCustomerRateDiv,#addshowMarginpDiv,#addshowSaleTypeDiv"
        ).css("display", "block");
        $(".addcheckbox").prop("checked", false); // Unchecks it
      } else if (event.target.value == "1") {
        $("#addallowLCELookUpDiv").css("display", "none");
        $("#addshowCustomerRateDiv,#addshowMarginpDiv,#addshowSaleTypeDiv").css(
          "display",
          "block"
        );
        $(".addcheckbox").prop("checked", false); // Unchecks it
      } else {
        $(
          "#addallowLCELookUpDiv,#addshowCustomerRateDiv,#addshowMarginpDiv,#addshowSaleTypeDiv"
        ).css("display", "none");
        $(".addcheckbox").prop("checked", false); // Unchecks it
      }
    },

    editgetAndSetOnChange(event) {
      if (event.target.value == "2") {
        $("#allowLCELookUpDiv").css("display", "block");
      } else if (event.target.value == "1") {
        $("#allowLCELookUpDiv").css("display", "none");
      }
    },

    showModal() {
      $("#addModel").modal("show");
    },

    addUser(e) {
      e.preventDefault();
      this.errors = [];
      if (!this.$refs["addfirstName"].value) {
        this.errors.push({ addfirstName: "First Name is required" });
        return false;
      }
      if (!this.$refs["addlastName"].value) {
        this.errors.push({ addlastName: "Last Name is required" });
        return false;
      }
      if (!this.$refs["adduserName"].value) {
        this.errors.push({ adduserName: "User Name is required" });
        return false;
      }
      if (!this.$refs["addpassword"].value) {
        this.errors.push({ addpassword: "Password is required" });
        return false;
      }
      if (!this.$refs["addemail"].value) {
        this.errors.push({ addemail: "Email is required" });
        return false;
      }

      var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      if (!pattern.test(this.$refs["addemail"].value)) {
        this.errors.push({ addemail: "Email must be valid format" });
        return false;
      }

      if (this.$refs["adduserType"].value == "0") {
        this.errors.push({ adduserType: "UserType is required" });
        return false;
      }

      if (this.$refs["addstatus"].value == "3") {
        this.errors.push({ addstatus: "Status is required" });
        return false;
      }
      axios({
        method: "post",
        url: constant.apiUrl + "/createUser",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          createdById: localStorage.getItem("userId"),
          firstName: this.$refs["addfirstName"].value,
          lastName: this.$refs["addlastName"].value,
          userName: this.$refs["adduserName"].value,
          password: this.$refs["addpassword"].value,
          email: this.$refs["addemail"].value,
          phone: this.$refs["addphone"].value,
          fax: this.$refs["addfax"].value,
          role: this.$refs["adduserType"].value,
          enabled: this.$refs["addstatus"].value,
          allowLCELookUp: $("#addallowLCELookUp").is(":checked"),
          showCustomerRate: $("#addshowCustomerRate").is(":checked"),
          showMargin: $("#addshowMargin").is(":checked"),
          showSaleType: $("#addshowSaleType").is(":checked"),
        },
      })
        .then((result) => {
          if (result.status === 201) {
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);
            $(".form-control").val("");
            $("#addModel").modal("hide");
            $("#myTable").DataTable().destroy();
            $(".toggle-password").removeClass("fa-eye-slash").addClass("fa-eye");
            $(".changePassword").attr("type", "password");
            this.getUsers();
          } else if (result.status === 202) {
            this.errors.push({ adduserName: "User Name must be unique" });
            return false;
          } else if (result.status === 203) {
            this.errors.push({ addemail: "Email must be unique" });
            return false;
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
  },
};
</script>
<style>
.dataTables_wrapper .dataTables_filter input {
  background-color: white;
}
.dataTables_wrapper .dataTables_length select {
  background-color: white;
}

div.dataTables_paginate {
  text-align: center;
}
.e-d {
  display: flex;
}
.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}
#topcheckbox {
  margin-left: 12px;
}
</style>
