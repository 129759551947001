<template>
  <section class="my-customer">
    <div id="customer_loader" style="text-align: center; margin-top: 120px">
      <img
        src="/images/exp_loader.gif"
        alt=""
        style="height: 80px; width: 80px" />
    </div>

    <div class="container" id="customerDataTable" style="display: none">
      <div class="row">
        <div class="col-md-6 col-6">
          <h3>CUSTOMERS</h3>
        </div>

        <div class="col-md-6 col-6">
          <div class="add-remv">
            <a href="javascript:void(0)" @click="multiDelete"
              ><img src="/images/remove.png"
            /></a>
          </div>
        </div>

        <div class="col-md-12">
          <div class="cus-table">
            <div
              class="alert alert-success message"
              v-if="addmessage"
              role="alert">
              {{ addmessage }}
            </div>

            <table style="text-align: center" id="myTable">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      @change="checkbox()"
                      id="topcheckbox"
                      value="0" />
                  </th>

                  <th>Business Name</th>
                  <th>Contract Start Date</th>
                  <th>Contract End Date</th>
                  <th>Annual Usage</th>
                  <th>Account No</th>
                  <th>Utility</th>
                  <th>Energy Type</th>
                  <th>Sales Force Account</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tr>
                <td></td>

                <td>
                  <div class="top">
                    <input
                      type="text"
                      class="tbl_input"
                      placeholder="Business Name"
                      id="bussiness_name" />
                  </div>
                </td>

                <td>
                  <div class="top">
                    <input
                      type="text"
                      class="date tbl_input"
                      id="datepicker1"
                      data-date-format="MM-DD-YYYY"
                      placeholder="Start Date" />
                  </div>
                </td>

                <td>
                  <div class="top">
                    <input
                      type="text"
                      class="date tbl_input"
                      id="datepicker2"
                      data-date-format="MM-DD-YYYY"
                      placeholder="End Date" />
                  </div>
                </td>

                <td>
                  <div class="top">
                    <input
                      type="number"
                      class="tbl_input"
                      placeholder="Annual Usage"
                      id="annual_usage" />
                  </div>
                </td>

                <td>
                  <div class="top">
                    <input
                      type="number"
                      class="tbl_input"
                      placeholder="Account"
                      id="account_no" />
                  </div>
                </td>

                <td>
                  <div class="top">
                    <input
                      type="text"
                      class="tbl_input"
                      placeholder="Utility"
                      id="utility_name" />
                  </div>
                </td>

                <td>
                  <div class="top">
                    <input
                      type="text"
                      class="tbl_input"
                      placeholder="Energy"
                      id="energy_type" />
                  </div>
                </td>

                <td></td>

                <td>
                  <div class="top">
                    <input
                      type="text"
                      class="tbl_input"
                      placeholder="Status"
                      id="cust_status" />
                  </div>
                </td>

                <td>
                  <div class="top">
                    <a
                      href="javascript:void(0)"
                      class="filt"
                      @click="filterSerch">
                      <img src="/images/filter-icon.png" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="filt"
                      @click="clearButton">
                      <img src="/images/clear.png" />
                    </a>
                  </div>
                </td>
              </tr>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- delete model -->

    <div
      class="modal fade"
      id="deletemodel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>

          <div class="modal-body">
            <h4>Are you sure, you want to delete Customer?</h4>

            <div class="c-btn">
              <button class="cncl" @click="closeModal">Cancel</button>

              <button class="delt" @click="deleteCustomer">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end delete model -->
  </section>
</template>

<script>
import axios from "axios";
import constant from "../../constant";
import moment from "moment";

export default {
  name: "Customers",

  data() {
    return {
      delete_id: "",
      addmessage: null,
      CompanyName: "",
      UtilityName: "",
      view_id: "",
      view_items: "",
      search_Data: [],
      serch_val: 0,
      bussiness_name: null,
      utility_name: null,
      account_no: null,
      annual_usage: null,
      billCopyData: [],
      backendURL: null
    };
  },

  created() {
    this.getCustomers();
    this.reservationBy();
  },

  mounted: function () {
    $(".ui-datepicker-calendar").show();
    const table = $(this.$refs.table).dataTable();
    const self = this;

    $("tbody", this.$refs.table).on("click", ".delete-data", function (e) {
      const id = $(this).attr("data-id").split(",");
      self.dataDelete(id);
    });

    $("tbody", this.$refs.table).on("click", ".view-customer", function (e) {
      var id = $(this).attr("data-id");
      self.viewCustomerData(id);
    });

    $("tbody", this.$refs.table).on("click", ".edit-data", function (e) {
      const id = $(this).attr("data-id").split(",");
      const utilityId = $(this).attr("data-utilityid").split(",");
      const productId = $(this).attr("data-productId").split(",");
      var companyStatus = $("#companyStatus-" + id).text();
      self.editCustomerData(id, utilityId, productId, companyStatus);
    });

    $("tbody", this.$refs.table).on(
      "click",
      ".download-contract",
      function (e) {
        const sfId = $(this).attr("data-sf");
        if (sfId.length === 0) {
          alert("Opportunity Not Found !!");
          return false;
        }
        self.downloadContract(sfId);
      }
    );

    $(function () {
      $(".date").datetimepicker({
        language: "en-gb",
        pickTime: false,
        format: "m-d-Y H:i:s",
        formatDate: "m-d-Y",
        useCurrent: false
      });
    });
  },

  methods: {
    getCustomers(e) {
      axios({
        method: "post",
        url: constant.apiUrl + "/getCustomers",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token")
        },

        data: {
          userId: localStorage.getItem("userId"),
          role: localStorage.getItem("role")
        }
      })
        .then((result) => {
          if (result.status === 200) {
            $("#customer_loader").hide();
            $("#customerDataTable").show();

            this.search_Dataa = result.data.data;
            var datatable = [];

            if (this.serch_val == 1) {
              datatable = this.search_Data;
            } else {
              datatable = result.data.data;
            }

            $("#myTable").DataTable({
              lengthChange: false,

              language: {
                search: ""
              },

              bInfo: false,

              data: datatable,

              columns: [
                {
                  render: function (data, type, row, meta) {
                    let checkbox =
                      `<input type="checkbox" class="allcheck" data-id=` +
                      row.CustomerId +
                      ` value=` +
                      row.CustomerId +
                      ` />`;

                    return checkbox;
                  }
                },

                {
                  data: "CompanyName"
                },

                {
                  render: function (data, type, row, meta) {
                    return moment(String(row.StartDate))
                      .utcOffset(330)
                      .format("MM-DD-YYYY");
                  }
                },

                {
                  render: function (data, type, row, meta) {
                    return moment(String(row.EndDate))
                      .utcOffset(330)
                      .format("MM-DD-YYYY");
                  }
                },

                {
                  data: "Quantity"
                },

                {
                  data: "AccountNumber"
                },

                {
                  data: "UtilityName"
                },

                {
                  render: function (data, type, row, meta) {
                    let Product = "";
                    if (row.Product === 1) {
                      Product = "Electric";
                    } else {
                      Product = "Gas";
                    }
                    return Product;
                  }
                },

                {
                  data: "SalesForceAccountId"
                },

                {
                  render: function (data, type, row, meta) {
                    let status = "";
                    if (row.Status === 1) {
                      status = "Submitted";
                    } else {
                      status = "Pending";
                    }
                    return status;
                  }
                },

                {
                  render: function (data, type, row, meta) {
                    if (row.SalesForceOpportunityId !== "") {
                      var sf =
                        `<a href="javascript:void(0)" id=` +
                        row.SalesForceOpportunityId +
                        ` class="download-contract" data-id=` +
                        row.CustomerId +
                        ` data-sf=` +
                        row.SalesForceOpportunityId +
                        `>Download Contract</a>`;
                    } else {
                      var sf = "";
                    }
                    let button =
                      `<div class="e-d">
                      <a href="#" class="view-customer" data-id=` +
                      row.CustomerId +
                      `><img src="/images/view.png" /></a>

                      <a href="#"class="edit-data" data-utilityid=` +
                      row.UtilityId +
                      ` data-productId=` +
                      row.Product +
                      ` data-id=` +
                      row.CustomerId +
                      `><img src="/images/edit.png" /></a
                      >
                      <span id=companyStatus-` +
                      row.CustomerId +
                      ` style="display:none;">` +
                      row.Status +
                      `</span>

                      <a href="#" class="delete-data" data-id=` +
                      row.CustomerId +
                      `><img src="/images/dlt.png" /></a> ` +
                      sf +
                      `
                      </div>`;

                    return button;
                  }
                }
              ]
            });

            $(".dataTables_filter").addClass("search");

            $(".dataTables_length").addClass("search");
          } else {
            $("#addedit_loader").css("display", "none");

            this.errormessage = result.data;
            setTimeout(() => (this.errormessage = false), 3000);
          }
        })

        .catch((err) => {
          console.log("error", err);
        });
    },

    reservationBy() {
      $(".ui-datepicker-calendar").show();
      $("#datepicker2, #datepicker1").datepicker({
        dateFormat: "mm/dd/yy"
      });
    },

    clearButton() {
      $("#bussiness_name").val("");
      $("#datepicker1").val("");
      $("#datepicker2").val("");
      $("#annual_usage").val("");
      $("#account_no").val("");
      $("#utility_name").val("");
      $("#energy_type").val("");
      $("#cust_status").val("");

      this.serch_val = 0;
      $("#myTable").DataTable().destroy();
      this.getCustomers();
      return false;
    },

    filterSerch() {
      var bussiness_name = $("#bussiness_name").val();
      var start_date = $("#datepicker1").val();
      var end_date = $("#datepicker2").val();
      var annual_usage = $("#annual_usage").val();
      var account_no = $("#account_no").val();
      var utility_name = $("#utility_name").val();

      var energy_type = $("#energy_type").val();
      var cust_status = $("#cust_status").val();

      if (start_date) {
        var dte = start_date.split("-");

        var start_date = dte[2] + "-" + dte[1] + "-" + dte[0];
      }

      if (end_date) {
        var dte = end_date.split("-");
        var end_date = dte[2] + "-" + dte[1] + "-" + dte[0];
      }

      if (energy_type != "") {
        if (energy_type == "Electric" || energy_type == "electric") {
          var energy_type = 1;
        } else {
          var energy_type = 2;
        }
      }

      if (cust_status != "") {
        if (cust_status == "Submitted" || cust_status == "submitted") {
          var cust_status = "1";
        } else {
          var cust_status = "0";
        }
      }

      if (
        bussiness_name == "" &&
        start_date == "" &&
        end_date == "" &&
        account_no == "" &&
        utility_name == "" &&
        annual_usage == "" &&
        energy_type == "" &&
        cust_status == ""
      ) {
        this.serch_val = 0;
        $("#myTable").DataTable().destroy();
        this.getCustomers();
        return false;
      } else {
        this.serch_val = 1;
        var searchData = this.search_Dataa;

        if (
          bussiness_name != "" &&
          annual_usage == "" &&
          account_no == "" &&
          utility_name == "" &&
          start_date == "" &&
          end_date == "" &&
          energy_type == "" &&
          cust_status == ""
        ) {
          var searchData = searchData.filter((e) => {
            const CompanyName = `${e.CompanyName}`.toLowerCase();
            const trimmedSearchValue = `${bussiness_name}`.toLowerCase();
            return CompanyName.includes(trimmedSearchValue);
          });
        } else if (
          bussiness_name != "" &&
          annual_usage != "" &&
          account_no == "" &&
          utility_name == "" &&
          start_date == "" &&
          end_date == "" &&
          energy_type == "" &&
          cust_status == ""
        ) {
          var searchData = searchData.filter(function (e) {
            const CompanyName = `${e.CompanyName}`.toLowerCase();
            const trimmedSearchValue = `${bussiness_name}`.toLowerCase();
            return (
              CompanyName.includes(trimmedSearchValue) &&
              e.Quantity == annual_usage
            );
          });
        } else if (
          bussiness_name != "" &&
          annual_usage != "" &&
          account_no !== "" &&
          utility_name == "" &&
          start_date == "" &&
          end_date == "" &&
          energy_type == "" &&
          cust_status == ""
        ) {
          var searchData = searchData.filter(function (e) {
            const CompanyName = `${e.CompanyName}`.toLowerCase();
            const trimmedSearchValue = `${bussiness_name}`.toLowerCase();
            return (
              CompanyName.includes(trimmedSearchValue) &&
              e.Quantity == annual_usage &&
              e.AccountNumber == account_no
            );
          });
        } else if (
          bussiness_name != "" &&
          annual_usage != "" &&
          account_no != "" &&
          utility_name != "" &&
          start_date == "" &&
          end_date == "" &&
          energy_type == "" &&
          cust_status == ""
        ) {
          var searchData = searchData.filter(function (e) {
            const CompanyName = `${e.CompanyName}`.toLowerCase();
            const trimmedSearchValue = `${bussiness_name}`.toLowerCase();
            return (
              CompanyName.includes(trimmedSearchValue) &&
              e.Quantity == annual_usage &&
              e.AccountNumber == account_no &&
              e.UtilityName == utility_name
            );
          });
        } else if (
          bussiness_name != "" &&
          account_no != "" &&
          utility_name != "" &&
          annual_usage != "" &&
          start_date != "" &&
          end_date == "" &&
          energy_type == "" &&
          cust_status == ""
        ) {
          var searchData = searchData.filter(function (e) {
            const StartDate = e.StartDate.split("T");
            const CompanyName = `${e.CompanyName}`.toLowerCase();
            const trimmedSearchValue = `${bussiness_name}`.toLowerCase();
            return (
              CompanyName.includes(trimmedSearchValue) &&
              e.AccountNumber == account_no &&
              e.UtilityName == utility_name &&
              e.Quantity == annual_usage &&
              StartDate[0] == start_date
            );
          });
        } else if (
          bussiness_name != "" &&
          account_no != "" &&
          utility_name != "" &&
          annual_usage != "" &&
          start_date != "" &&
          end_date != "" &&
          energy_type == "" &&
          cust_status == ""
        ) {
          var searchData = searchData.filter(function (e) {
            const EndDate = e.EndDate.split("T");
            const StartDate = e.StartDate.split("T");
            const CompanyName = `${e.CompanyName}`.toLowerCase();
            const trimmedSearchValue = `${bussiness_name}`.toLowerCase();
            return (
              CompanyName.includes(trimmedSearchValue) &&
              e.AccountNumber == account_no &&
              e.UtilityName == utility_name &&
              e.Quantity == annual_usage &&
              EndDate[0] <= end_date &&
              StartDate[0] >= start_date
            );
          });
        } else if (
          bussiness_name != "" &&
          account_no != "" &&
          utility_name != "" &&
          annual_usage != "" &&
          start_date != "" &&
          end_date != "" &&
          energy_type != "" &&
          cust_status == ""
        ) {
          var searchData = searchData.filter(function (e) {
            const EndDate = e.EndDate.split("T");
            const StartDate = e.StartDate.split("T");
            const CompanyName = `${e.CompanyName}`.toLowerCase();
            const trimmedSearchValue = `${bussiness_name}`.toLowerCase();
            return (
              CompanyName.includes(trimmedSearchValue) &&
              e.AccountNumber == account_no &&
              e.UtilityName == utility_name &&
              e.Quantity == annual_usage &&
              EndDate[0] <= end_date &&
              StartDate[0] >= start_date &&
              e.Product == energy_type
            );
          });
        } else if (
          bussiness_name != "" &&
          account_no != "" &&
          utility_name != "" &&
          annual_usage != "" &&
          start_date != "" &&
          end_date != "" &&
          energy_type != "" &&
          cust_status != ""
        ) {
          var searchData = searchData.filter(function (e) {
            const EndDate = e.EndDate.split("T");
            const StartDate = e.StartDate.split("T");
            const CompanyName = `${e.CompanyName}`.toLowerCase();
            const trimmedSearchValue = `${bussiness_name}`.toLowerCase();
            return (
              CompanyName.includes(trimmedSearchValue) &&
              e.AccountNumber == account_no &&
              e.UtilityName == utility_name &&
              e.Quantity == annual_usage &&
              EndDate[0] <= end_date &&
              StartDate[0] >= start_date &&
              e.Product == energy_type &&
              e.Status == cust_status
            );
          });
        } else {
          if (
            annual_usage != "" &&
            bussiness_name == "" &&
            account_no == "" &&
            utility_name == ""
          ) {
            var searchData = searchData.filter(function (e) {
              return e.Quantity == annual_usage;
            });
          }

          if (account_no != "" && bussiness_name == "" && utility_name == "") {
            var searchData = searchData.filter(function (e) {
              return e.AccountNumber == account_no;
            });
          }

          if (utility_name != "" && bussiness_name == "" && account_no == "") {
            var searchData = searchData.filter(function (e) {
              return e.UtilityName == utility_name;
            });
          }

          if (annual_usage != "" && account_no != "") {
            var searchData = searchData.filter(function (e) {
              return (
                e.Quantity == annual_usage && e.AccountNumber == account_no
              );
            });
          }

          if (utility_name != "" && account_no != "") {
            var searchData = searchData.filter(function (e) {
              return (
                e.UtilityName == utility_name && e.AccountNumber == account_no
              );
            });
          }

          if (utility_name != "" && bussiness_name == "" && account_no == "") {
            var searchData = searchData.filter(function (e) {
              return e.UtilityName == utility_name;
            });
          }

          if (bussiness_name != "" && account_no != "") {
            var searchData = searchData.filter(function (e) {
              const CompanyName = `${e.CompanyName}`.toLowerCase();
              const trimmedSearchValue = `${bussiness_name}`.toLowerCase();
              return (
                CompanyName.includes(trimmedSearchValue) &&
                e.AccountNumber == account_no
              );
            });
          }

          if (start_date != "" && end_date == "") {
            var searchData = searchData.filter(function (e) {
              const StartDate = e.StartDate.split("T");
              return StartDate[0] == start_date;
            });
          }

          if (energy_type != "") {
            var searchData = searchData.filter(function (e) {
              return e.Product == energy_type;
            });
          }

          if (cust_status != "") {
            var searchData = searchData.filter(function (e) {
              return e.Status == cust_status;
            });
          }

          if (cust_status != "" && energy_type != "") {
            var searchData = searchData.filter(function (e) {
              return e.Status == cust_status && e.Product == energy_type;
            });
          }

          if (start_date != "" && end_date != "" && energy_type != "") {
            var searchData = searchData.filter(function (e) {
              const EndDate = e.EndDate.split("T");
              const StartDate = e.StartDate.split("T");
              const CompanyName = `${e.CompanyName}`.toLowerCase();
              const trimmedSearchValue = `${bussiness_name}`.toLowerCase();
              return (
                EndDate[0] <= end_date &&
                StartDate[0] >= start_date &&
                e.Product == energy_type &&
                CompanyName.includes(trimmedSearchValue)
              );
            });
          }

          if (start_date != "" && end_date != "" && cust_status != "") {
            var searchData = searchData.filter(function (e) {
              const EndDate = e.EndDate.split("T");
              const StartDate = e.StartDate.split("T");
              const CompanyName = `${e.CompanyName}`.toLowerCase();
              const trimmedSearchValue = `${bussiness_name}`.toLowerCase();
              return (
                EndDate[0] <= end_date &&
                StartDate[0] >= start_date &&
                e.Status == cust_status &&
                CompanyName.includes(trimmedSearchValue)
              );
            });
          }

          if (start_date != "" && end_date != "" && bussiness_name != "") {
            var searchData = searchData.filter(function (e) {
              const EndDate = e.EndDate.split("T");
              const StartDate = e.StartDate.split("T");
              const CompanyName = `${e.CompanyName}`.toLowerCase();
              const trimmedSearchValue = `${bussiness_name}`.toLowerCase();
              return (
                EndDate[0] <= end_date &&
                StartDate[0] >= start_date &&
                CompanyName.includes(trimmedSearchValue)
              );
            });
          }

          if (
            start_date != "" &&
            end_date != "" &&
            bussiness_name != "" &&
            annual_usage != ""
          ) {
            var searchData = searchData.filter(function (e) {
              const EndDate = e.EndDate.split("T");
              const StartDate = e.StartDate.split("T");

              const CompanyName = `${e.CompanyName}`.toLowerCase();
              const trimmedSearchValue = `${bussiness_name}`.toLowerCase();
              return (
                EndDate[0] <= end_date &&
                StartDate[0] >= start_date &&
                CompanyName.includes(trimmedSearchValue) &&
                e.Quantity == annual_usage
              );
            });
          }

          if (start_date != "" && end_date != "") {
            var searchData = searchData.filter(function (e) {
              const EndDate = e.EndDate.split("T");
              const StartDate = e.StartDate.split("T");
              return EndDate[0] <= end_date && StartDate[0] >= start_date;
            });
          }
        }

        $("#myTable").DataTable().destroy();
        this.search_Data = searchData;
        this.getCustomers();
      }
    },

    viewCustomerData(CustomerId) {
      localStorage.removeItem("Customer_new_id");
      localStorage.setItem("Customer_new_id", CustomerId);

      if (localStorage.getItem("role") == "1") {
        var routename = "/admin/customerViewPage";
      } else {
        var routename = "/agent/customerViewPage";
      }
      const token = localStorage.getItem("token");
      this.$router.push({
        name: routename
      });
    },

    editCustomerData(CustomerId, utilityId, productId, companyStatus) {
      if (companyStatus == 1) {
        alert("Quote Already Submitted");
        return false;
      }
      var routename = "/agent/pricequote";
      const token = localStorage.getItem("token");

      this.$router.push({
        name: routename,
        query: {
          edit_customer: 1,
          Customer_id: CustomerId[0],
          UtilityId: utilityId[0],
          CustomerProductId: productId[0]
        }
      });
    },

    checkbox() {
      var value = $("#topcheckbox").val();
      if (value == 0) {
        $(".allcheck").prop("checked", true);

        $("#topcheckbox").val("1");
      } else {
        $(".allcheck").prop("checked", false);

        $("#topcheckbox").val("0");
      }
    },

    closeModal() {
      $("#deletemodel").modal("hide");
    },

    dataDelete(id) {
      $("#deletemodel").modal("show");

      this.delete_id = id;
    },

    deleteCustomer(e) {
      axios({
        method: "delete",
        url: constant.apiUrl + "/deleteCustomer",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token")
        },

        data: {
          id: this.delete_id,

          deletedById: localStorage.getItem("userId")
        }
      })
        .then((result) => {
          if (result.status == 200) {
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);
            $("#deletemodel").modal("hide");
            $("#myTable").DataTable().destroy();
            this.getCustomers();
          }
        })

        .catch((error) => {
          if (error) {
            this.error = error;
          }
        });
    },

    multiDelete(e) {
      var deleteids_arr = [];

      // Read all checked checkboxes

      $("input:checkbox[class=allcheck]:checked").each(function () {
        deleteids_arr.push($(this).val());
      });

      // Check checkbox checked or not

      if (deleteids_arr.length > 0) {
        this.dataDelete(deleteids_arr);
      } else {
        alert("Please select at least one customer!");
      }
    },

    // Download Contract start here

    downloadContract(sfId) {
      $(`#${sfId}`).text("Please wait ...").attr("disabled", "disabled");

      axios({
        method: "post",
        url: constant.apiUrl + "/ContractGeneartionFinal",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token")
        },
        data: {
          oppurtunityId: sfId
        }
      })
        .then((result) => {
          if (result.data.success === true) {
            const fileName = result.data.data.Title;

            var myHeaders = new Headers();

            myHeaders.append(
              "Authorization",
              `Bearer ${result.data.downloadToken}`
            );

            var requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow"
            };

            fetch(
              `https://integrityenergy123.my.salesforce.com/services/data/v53.0/sobjects/contentversion/${result.data.data.LatestPublishedVersionId}/versiondata`,
              requestOptions
            )
              .then((response) => response.blob())
              .then((data) => {
                const url = URL.createObjectURL(data);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();

                $(`#${sfId}`).text("Downlaod Contract").prop("disabled", false);
              })

              .catch((err) => {
                console.log(err.message);
              });
          } else if (result.data.success === false) {
            alert(result.data.message);
            $(`#${sfId}`).text("Downlaod Failed").prop("disabled", false);
          }
        })

        .catch((err) => {
          $("#customer_loader").hide();
          $("#main-section").show();

          alert(err.message);
          console.log(err.message);
        });
    }
    // Download Contract end here
  }
};
</script>

<style>
.dataTables_wrapper .dataTables_filter input {
  background-color: white;
}

.dataTables_wrapper .dataTables_length select {
  background-color: white;
}

div.dataTables_paginate {
  text-align: center;
}
.view-customer img {
  width: 41px;
  height: 39px;
  margin-right: 5px;
}

@media (max-width: 767px) {
  .view-customer img {
    width: 41px;
    height: 29px !important;
    margin-right: 5px;
  }
}

.tbl_input {
  width: 100% !important;
}
</style>
