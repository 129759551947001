<template>
  <section class="my-customer">
    <div id="utility_loader" style="text-align: center; margin-top: 120px">
      <img src="/images/exp_loader.gif" alt="" style="height: 80px; width: 80px" />
    </div>
    <div class="container" id="utilityDataTable" style="display: none">
      <div class="row">
        <div class="col-md-6 col-6">
          <h3>PARENT UTILITIES</h3>
        </div>

        <div class="col-md-12 col-12">
          <div class="add-remv">
            <button @click="addModel">+ Add Utility</button>
          </div>

          <div class="add-remv">
            <button>
              <router-link
                style="color: white"
                to="/admin/utilities"
                :class="$route.name === '/admin/utilities' ? 'active' : 'inactive'"
                >Refrence Utility
              </router-link>
            </button>
          </div>
        </div>

        <div class="col-md-12">
          <div class="cus-table">
            <div class="alert alert-success message" v-if="addmessage" role="alert">
              {{ addmessage }}
            </div>
            <table style="text-align: center" id="myTable">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Utility Name</th>
                  <th>Display Name</th>
                  <th>Child</th>
                  <th>Notes</th>
                  <th>State</th>
                  <th>Energy Type</th>
                  <th>SalesforceId</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- add unilities -->
    <div
      class="modal fade common-pop"
      id="addModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="alert alert-success message"
            v-if="successMessagePopup"
            role="alert"
          >
            {{ successMessagePopup }}
          </div>

          <div
            class="alert alert-danger message"
            id="errorLoading"
            v-if="errorMessagePopup"
            role="alert"
          >
            {{ errorMessagePopup }}
          </div>

          <div class="modal-header">
            <h2>Add Utility</h2>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="p-q">
              <form id="addForm" @submit="addUtility">
                <input
                  type="hidden"
                  class="form-control"
                  name="UserIdAdded"
                  id="addUserIdAdded"
                />

                <div class="row">
                  <div class="form-group col-md-12">
                    <label>Utility Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="utilitie_name"
                      placeholder="Please Enter Utility Name"
                      id="utilitie"
                    />

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.utilitie"
                    >
                      {{ error.utilitie }}
                    </span>
                  </div>

                  <div class="form-group col-md-12">
                    <label>Display Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="display_name"
                      placeholder="Please Enter Display Name"
                      id="display_name"
                    />
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.display_name"
                    >
                      {{ error.display_name }}
                    </span>
                  </div>

                  <div class="form-group col-md-12">
                    <label>Notes</label>
                    <textarea
                      type="text"
                      class="form-control"
                      name="notes"
                      rows="2"
                      placeholder="Please Enter Notes"
                      id="notes_add"
                    >
                    </textarea>
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.notes_add"
                    >
                      {{ error.notes_add }}
                    </span>
                  </div>

                  <div class="form-group col-md-4">
                    <label>State</label>
                    <select class="form-control" id="states_add" name="state">
                      <option value="">--Please Select state--</option>
                      <option
                        v-for="state in newstates"
                        :value="state.StateCode"
                        :key="state.id"
                      >
                        {{ state.State }}
                      </option>
                    </select>

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.states_add"
                    >
                      {{ error.states_add }}
                    </span>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Status</label>
                    <select class="form-control" id="status_add" name="status">
                      <option value="1" selected>Enabled</option>
                      <option value="0">Disabled</option>
                    </select>
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.status_add"
                    >
                      {{ error.status_add }}
                    </span>
                  </div>

                  <div class="form-group col-md-4">
                    <label>Energy Type</label>
                    <select class="form-control" id="product_add" name="product">
                      <option value="1" selected>Electric</option>
                      <option value="2">Gas</option>
                    </select>
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.product_add"
                    >
                      {{ error.product_add }}
                    </span>
                  </div>

                  <div class="form-group col-md-12">
                    <div class="c-btn">
                      <button class="delt" @click="closeModal" type="button">
                        Cancel
                      </button>
                      <button class="cncl">Save</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- edit unilities -->
    <div
      class="modal fade common-pop"
      id="editModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="alert alert-success message"
            v-if="successMessagePopup"
            role="alert"
          >
            {{ successMessagePopup }}
          </div>
          <div
            class="alert alert-danger message"
            id="errorLoading"
            v-if="errorMessagePopup"
            role="alert"
          >
            {{ errorMessagePopup }}
          </div>
          <div class="modal-header">
            <h2>Edit Utility</h2>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="p-q">
              <form id="editForm">
                <input
                  type="hidden"
                  class="form-control"
                  name="utilityId"
                  value=""
                  id="utilityId"
                />

                <div class="row">
                  <div class="form-group col-md-12">
                    <label>Utility Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="utilitie_name"
                      placeholder="Please Enter Utility Name"
                      id="edit_utilitie_name"
                    />

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.edit_utilitie_name"
                    >
                      {{ error.edit_utilitie_name }}
                    </span>
                  </div>

                  <div class="form-group col-md-12">
                    <label>Display Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="display_name"
                      placeholder="Please Enter Display Name"
                      id="edit_display_name"
                    />

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.edit_display_name"
                    >
                      {{ error.edit_display_name }}
                    </span>
                  </div>

                  <div class="form-group col-md-12">
                    <label>Notes</label>
                    <textarea
                      type="text"
                      class="form-control"
                      name="notes"
                      rows="2"
                      placeholder="Please Enter Notes"
                      id="edit_notes"
                    >
                    </textarea>

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.edit_notes"
                    >
                      {{ error.edit_notes }}
                    </span>
                  </div>

                  <div class="form-group col-md-4">
                    <label>State</label>
                    <select class="form-control" id="edit_State_ID" name="state">
                      <option value="">--Please Select state--</option>
                      <option
                        :value="state.StateCode"
                        v-for="state in newstates"
                        :key="state.id"
                        :data-stateId="state.id"
                      >
                        {{ state.State }}
                      </option>
                    </select>
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.edit_State_ID"
                    >
                      {{ error.edit_State_ID }}
                    </span>
                  </div>

                  <div class="form-group col-md-4">
                    <label>Status</label>
                    <select
                      class="form-control"
                      id="edit_status"
                      :value="edit_status"
                      name="status"
                    >
                      <option :value="1">Enabled</option>
                      <option :value="0">Disabled</option>
                    </select>

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.edit_status"
                    >
                      {{ error.edit_status }}
                    </span>
                  </div>

                  <div class="form-group col-md-4">
                    <label>Energy Type</label>
                    <select
                      class="form-control"
                      id="edit_product"
                      :value="edit_product"
                      name="product"
                    >
                      <option value="1">Electric</option>
                      <option value="2">Gas</option>
                    </select>
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.edit_product"
                    >
                      {{ error.edit_product }}
                    </span>
                  </div>

                  <div class="form-group col-md-12">
                    <div class="c-btn">
                      <button class="delt" @click="closeModal" type="button">
                        Cancel
                      </button>
                      <button class="cncl" type="submit" @click="UpdateUtility">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- edit unilities -->
    <div
      class="modal fade common-pop"
      id="mergeModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="alert alert-success message"
            v-if="successMessagePopup"
            role="alert"
          >
            {{ successMessagePopup }}
          </div>
          <div
            class="alert alert-danger message"
            id="errorLoading"
            v-if="errorMessagePopup"
            role="alert"
          >
            {{ errorMessagePopup }}
          </div>
          <div class="modal-header">
            <h2>Select Utility For Merge With</h2>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="p-q">
              <form id="editForm">
                <input
                  type="hidden"
                  class="form-control"
                  name="merge_utility_1"
                  value=""
                  id="merge_utility_1"
                />
                <input
                  type="hidden"
                  class="form-control"
                  name="mergeUtilityName"
                  value=""
                  id="mergeUtilityName"
                />

                <div class="row">
                  <div class="form-group col-md-12">
                    <label>Utility Name</label>
                    <select class="form-control" id="merge_utility" name="merge_utility">
                      <option value="">--Please Select Utility Name--</option>
                      <option
                        v-for="items in utilityMerge"
                        :value="items.UtilityId"
                        :key="items.UtilityId"
                      >
                        {{ items.UtilityName }}
                      </option>
                    </select>

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.edit_utilitie_name"
                    >
                      {{ error.edit_utilitie_name }}
                    </span>
                  </div>

                  <div class="form-group col-md-12">
                    <div class="c-btn">
                      <button class="delt" @click="closeModal" type="button">
                        Cancel
                      </button>
                      <button class="cncl" type="submit" @click="mergeUtility">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- delete model -->
    <div
      class="modal fade"
      id="deletemodel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <h4>Are you sure, you want to delete Utility?</h4>
            <div class="c-btn">
              <button class="cncl" @click="closeModal">Cancel</button>
              <button class="delt" @click="dataDelete">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end delete model -->
  </section>
</template>

<script>
import axios from "axios";
import constant from "../../constant";

export default {
  name: "ParentUtilities",

  data() {
    return {
      utilityData: [],
      delete_id: "",
      addmessage: null,
      utilityId: "",
      errors: [],
      successMessagePopup: null,
      errorMessagePopup: null,
      states: [],
      newstates: [],
      utilityMerge: [],
      edit_status: true,
      delete_id: null,
      editutilitie_name: null,
      edit_product: true,
    };
  },

  created() {
    this.getUtilities();
    this.getStates();
  },

  mounted: function () {
    const table = $(this.$refs.table).dataTable();
    const self = this;

    $("tbody", this.$refs.table).on("click", ".edit-data", function (e) {
      const utilityId = $(this).attr("data-id");
      self.editModel(utilityId);
    });

    $("tbody", this.$refs.table).on("click", ".merge-data", function (e) {
      const utilityId = $(this).attr("data-id");
      var UtilityName = $("#report-" + utilityId).text();
      self.mergeModel(utilityId, UtilityName);
    });

    $("tbody", this.$refs.table).on("click", ".delete-data", function (e) {
      const utilityId = $(this).attr("data-id");

      self.deleteUtility(utilityId);
    });
  },
  methods: {
    deleteUtility(id) {
      $("#deletemodel").modal("show");
      this.delete_id = id;
    },

    dataDelete() {
      axios({
        method: "post",
        url: constant.apiUrl + "/deleteUtility",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },

        data: {
          utilityId: this.delete_id,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);
            $("#deletemodel").modal("hide");

            $("#myTable").DataTable().destroy();

            this.getUtilities();
          }

          if (result.status === 202) {
            this.errors = result.data.errors;
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    addUtility(e) {
      e.preventDefault();

      this.errors = [];
      var utilitie = $("#utilitie").val();
      var display_name = $("#display_name").val();
      var notes_add = $("#notes_add").val();
      var states_add = $("#states_add").val();
      var status_add = $("#status_add").val();
      var product_add = $("#product_add").val();

      if (!utilitie) {
        this.errors.push({ utilitie: "Utility Name is required" });
        return false;
      }

      if (!display_name) {
        this.errors.push({ display_name: "Display Name is required" });
        return false;
      }

      if (!states_add) {
        this.errors.push({ states_add: "State is required" });
        return false;
      }

      if (!status_add) {
        this.errors.push({ status_add: "Status is required" });
        return false;
      }

      if (!product_add) {
        this.errors.push({ product_add: "Energy Type is required" });
        return false;
      }

      const form = new FormData(e.target);
      const inputs = Object.fromEntries(form.entries());
      axios({
        method: "post",
        url: constant.apiUrl + "/addUtility",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: inputs,
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);
            $("#addModel").modal("hide");
            $("#myTable").DataTable().destroy();
            $("#addForm")[0].reset();
            this.getUtilities();
            this.resetData();
          } else if (result.status === 202) {
            this.errors = result.data.errors;
          } else if (result.status === 203) {
            this.errors.push({ utilitie: "Utility Name must be unique" });
            return false;
          } else if (result.status === 204) {
            this.errors.push({ display_name: "Utility Display Name must be unique" });
            return false;
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    getUtilityforMerge(UtilityId, UtilityName) {
      axios({
        method: "post",
        url: constant.apiUrl + "/getUtilityforMerge",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          UtilityId: UtilityId,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.utilityMerge = result.data.data;
            $("#merge_utility_1").val(UtilityId);
            $("#mergeUtilityName").val(UtilityName);
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    mergeUtility(e) {
      e.preventDefault();
      this.errors = [];

      var old_utility_1 = $("#merge_utility_1").val();

      var new_utlity = $("#merge_utility").val();

      var mergeUtilityName = $("#mergeUtilityName").val();

      if (!new_utlity) {
        this.errors.push({ edit_utilitie_name: "Utility is required" });
        return false;
      }

      let text = "Are you sure, you want to merge Utility?";
      if (confirm(text) == false) {
        return false;
      }

      axios({
        method: "post",
        url: constant.apiUrl + "/mergeUtility",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          old_utility_1: old_utility_1,
          new_utlity: new_utlity,
          mergeUtilityName: mergeUtilityName,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);

            $("#mergeModel").modal("hide");
            $("#myTable").DataTable().destroy();

            this.getUtilities();
            this.resetData();
          }

        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    getStates() {
      axios({
        method: "get",
        url: constant.apiUrl + "/getStates",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.newstates = result.data.enabledStateData;
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    getUtility(UtilityId) {
      axios({
        method: "post",
        url: constant.apiUrl + "/getUtility",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          UtilityId: UtilityId,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            $("#utilityId").val(UtilityId);
            $("#edit_utilitie_name").val(result.data.data.UtilityName);
            $("#edit_display_name").val(result.data.data.DisplayName);
            $("#edit_notes").val(result.data.data.Notes);
            $("#edit_State_ID").val(result.data.data.StateCode);
            $("#edit_product").val(result.data.data.ProductId);

            if (result.data.data.Enabled == 1) {
              this.edit_status = 1;
            } else {
              this.edit_status = 0;
            }

            if (result.data.data.ProductId == 1) {
              this.edit_product = 1;
            } else if (result.data.data.ProductId == 2) {
              this.edit_product = 2;
            } else {
              this.edit_product = "";
            }
            this.editutilitie_name = result.data.data.UtilityName;
            this.editdisplay_name = result.data.data.DisplayName;
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    UpdateUtility(e) {
      e.preventDefault();
      this.errors = [];

      var utilityId = $("#utilityId").val();

      var utilitie = $("#edit_utilitie_name").val();
      var display_name = $("#edit_display_name").val();
      var notes_edit = $("#edit_notes").val();
      var states_edit = $("#edit_State_ID").val();
      var stateId = $("#edit_State_ID option:selected").attr("data-stateId");

      var status_edit = $("#edit_status").val();
      var product_edit = $("#edit_product").val();

      if (!utilitie) {
        this.errors.push({ edit_utilitie_name: "Utility Name is required" });
        return false;
      }

      if (!display_name) {
        this.errors.push({ edit_display_name: "Display Name is required" });
        return false;
      }

      if (!states_edit) {
        this.errors.push({ edit_State_ID: "State is required" });
        return false;
      }

      if (!status_edit) {
        this.errors.push({ edit_status: "Status is required" });
        return false;
      }
      if (!product_edit) {
        this.errors.push({ edit_product: "Energy Type is required" });
        return false;
      }

      axios({
        method: "post",
        url: constant.apiUrl + "/editUtility",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          UserIdAdded: localStorage.getItem("userId"),
          utilityId: utilityId,
          utilitie_name: utilitie,
          display_name: display_name,
          notes: notes_edit,
          state: states_edit,
          status: status_edit,
          editutilitie_name: this.editutilitie_name,
          editdisplay_name: this.editdisplay_name,
          product: product_edit,
          StateId: stateId,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);

            $("#editModel").modal("hide");
            $("#myTable").DataTable().destroy();
            $("#editForm")[0].reset();

            this.getUtilities();
          } else if (result.status === 202) {
            this.errors = result.data.errors;
          } else if (result.status === 203) {
            this.errors.push({ edit_utilitie_name: "Utility Name must be unique" });
            return false;
          } else if (result.status === 204) {
            this.errors.push({
              edit_display_name: "Utility Display Name must be unique",
            });
            return false;
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    getUtilities(e) {
      axios({
        method: "get",
        url: constant.apiUrl + "/getParentUtilities",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {},
      })
        .then((result) => {
          if (result.status === 200) {
            let a = 1;

            $("#utility_loader").hide();
            $("#utilityDataTable").show();

            $("#myTable").DataTable({
              lengthChange: false,
              language: {
                search: "",
              },

              bInfo: false,
              data: result.data.data,
              columns: [
                {
                  render: function (data, type, row, meta) {
                    return a++;
                  },
                },

                {
                  data: "UtilityName",
                },
                {
                  data: "DisplayName",
                },
                {
                  data: "Alias",
                },
                {
                  data: "Notes",
                },
                {
                  data: "State",
                },

                {
                  render: function (data, type, row, meta) {
                    let Product = "";
                    if (row.ProductId === 1) {
                      Product = "Electric";
                    } else if (row.ProductId === 2) {
                      Product = "Gas";
                    } else {
                      Product = "";
                    }
                    return Product;
                  },
                },
                {
                  data: "SalesForceId",
                },

                {
                  render: function (data, type, row, meta) {
                    let status = "";
                    if (row.Enabled === true) {
                      status = "Enabled";
                    } else {
                      status = "Disabled";
                    }
                    return status;
                  },
                },

                {
                  data: "",
                  name: "",
                  orderable: false,
                  searchable: false,
                  render: function (data, type, row, meta) {
                    let button =
                      `<div class="e-d"> <a href="#" class="edit-data" data-id=` +
                      row.UtilityId +
                      ` ><img src="/images/edit.png" /></a> 


                                            <span id=report-` +
                      row.UtilityId +
                      ` style="display:none;">` +
                      row.UtilityName +
                      `</span>
														
                                            <a href="#" class="delete-data" data-id=` +
                      row.UtilityId +
                      ` ><img src="/images/dlt.png" /></a> 
                                            
                                            </div>`;
                    return button;
                  },
                },
              ],
            });
            if ($(window).width() < 1260) {
              $("#myTable").wrapAll('<div style="overflow: scroll;width: 100%">');
            }
            $(".dataTables_filter").addClass("search");
            $(".dataTables_length").addClass("search");
          } else {
            this.errormessage = result.data;
            setTimeout(() => (this.errormessage = false), 3000);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },

    addModel() {
      $("#addUserIdAdded").val(localStorage.getItem("userId"));

      $("#addModel").modal("show");
      $("#notes_add").val("");
      this.getStates();
    },

    editModel(id) {
      $("#editModel").modal("show");
      $("#edit_notes").val();
      this.getUtility(id);
    },

    mergeModel(id, UtilityName) {
      $("#mergeModel").modal("show");
      this.getUtilityforMerge(id, UtilityName);
    },

    closeModal() {
      $("#addModel").modal("hide");
      $("#editModel").modal("hide");
      $("#deletemodel").modal("hide");
      $("#mergeModel").modal("hide");
      $(".form-control").val("");

      this.errors = [];
      this.resetData();
    },

    resetData() {
      this.errors = [];
      $("#utilitie ,#edit_utilitie_name").val();
      $("#display_name, #edit_display_name").val();
      $("#notes_add, #edit_notes").val();
      $("#states_add, #edit_State_ID").val();
      $("#status_add, #edit_status").val();

      $("#UserIdAdded").val("");
      $("#utilityId").val("");
      $("#merge_utility_1").val("");
      $("#merge_utility").val("");
      $("#mergeUtilityName").val("");
    },
  },
};
</script>

<style>
.dataTables_wrapper .dataTables_filter input {
  background-color: white;
}

.dataTables_wrapper .dataTables_length select {
  background-color: white;
}

div.dataTables_paginate {
  text-align: center;
}

.merge-data img {
  width: 39px;
  height: 40px;
  margin-right: 5px;
  margin-left: 5px;
}
</style>
