<template>
  <section class="my-customer">
    <div id="utility_loader" style="text-align: center; margin-top: 120px">
      <img src="/images/exp_loader.gif" alt="" style="height: 80px; width: 80px" />
    </div>
    <div class="container" id="utilityDataTable" style="display: none">
      <div class="row">
        <div class="col-md-6 col-6">
          <h3>UTILITIES ACCOUNTS</h3>
        </div>

        <div class="col-md-12">
          <div class="cus-table">
            <div class="alert alert-success message" v-if="addmessage" role="alert">
              {{ addmessage }}
            </div>
            <table style="text-align: center" id="myTable">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Utility Name</th>
                  <th>Display Name</th>
                  <th>Account Length</th>
                  <th>Account Unique</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- edit unilities -->
    <div
      class="modal fade common-pop"
      id="editModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="alert alert-success message"
            v-if="successMessagePopup"
            role="alert"
          >
            {{ successMessagePopup }}
          </div>
          <div
            class="alert alert-danger message"
            id="errorLoading"
            v-if="errorMessagePopup"
            role="alert"
          >
            {{ errorMessagePopup }}
          </div>
          <div class="modal-header">
            <h2>Edit Account</h2>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="p-q">
              <form id="editForm">
                <input
                  type="hidden"
                  class="form-control"
                  name="utilityId"
                  value=""
                  id="utilityId"
                />

                <div class="row">
                  <div class="form-group col-md-12">
                    <label>Length</label>
                    <input
                      type="text"
                      class="form-control"
                      name="utilitie_name"
                      placeholder="Length"
                      id="editLength"
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <label>Unique</label>
                    <select class="form-control" id="editAccounUniqueId">
                      <option value="0">--Please Select Unique--</option>
                      <option
                        v-for="unique in accountUnique"
                        :value="unique.Id"
                        :key="unique.Id"
                      >
                        {{ unique.Name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-12">
                    <div class="c-btn">
                      <button class="delt" @click="closeModal" type="button">
                        Cancel
                      </button>
                      <button class="cncl" type="submit" @click="updateUtilityAccount">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import constant from "../../constant";

export default {
  name: "UtilitiesAccount",

  data() {
    return {
      addmessage: null,
      utilityId: "",
      errors: [],
      successMessagePopup: null,
      errorMessagePopup: null,
      accountUnique: [],
    };
  },

  created() {
    this.getUtilityAccount();
    this.getAccountUnique();
  },

  mounted: function () {
    const table = $(this.$refs.table).dataTable();
    const self = this;

    $("tbody", this.$refs.table).on("click", ".edit-data", function (e) {
      const utilityId = $(this).attr("data-id");
      self.editModel(utilityId);
    });
  },
  methods: {
    getAccountUnique() {
      axios({
        method: "get",
        url: constant.apiUrl + "/getAccountUnique",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.accountUnique = result.data.data;          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    getSingleUtilityAccount(UtilityId) {
      axios({
        method: "post",
        url: constant.apiUrl + "/getSingleUtilityAccount",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          UtilityId: UtilityId,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            $("#utilityId").val(UtilityId);
            $("#editLength").val(result.data.data.accountLength);
            $("#editAccounUniqueId").val(0);

            if (result.data.data.accountUniqueValue !== null) {
              $("#editAccounUniqueId").val(result.data.data.accountUniqueValue);
            }
          }
        })

        .catch((err) => {
          console.log("Error", err.message);
        });
    },

    updateUtilityAccount(e) {
      e.preventDefault();
      this.errors = [];

      axios({
        method: "post",
        url: constant.apiUrl + "/updateUtilityAccount",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          UtilityId: $("#utilityId").val(),
          AccountLength: $("#editLength").val(),
          AccountUniqueId: $("#editAccounUniqueId").val(),
        },
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);

            $("#editModel").modal("hide");
            $("#myTable").DataTable().destroy();
            $("#editForm")[0].reset();

            this.getUtilityAccount();
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    getUtilityAccount(e) {
      axios({
        method: "get",
        url: constant.apiUrl + "/getUtilityAccount",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      })
        .then((result) => {
          if (result.status === 200) {
            let a = 1;

            $("#utility_loader").hide();
            $("#utilityDataTable").show();

            $("#myTable").DataTable({
              lengthChange: false,
              language: {
                search: "",
              },

              bInfo: false,
              data: result.data.data,
              columns: [
                {
                  render: function (data, type, row, meta) {
                    return a++;
                  },
                },

                {
                  data: "UtilityName",
                },
                {
                  data: "DisplayName",
                },
                {
                  data: "Length",
                },

                {
                  data: "UniqueLabel",
                },

                {
                  data: "",
                  name: "",
                  orderable: false,
                  searchable: false,
                  render: function (data, type, row, meta) {
                    let button =
                      `<div class="e-d"> <a href="#" class="edit-data" data-id=` +
                      row.UtilityId +
                      ` ><img src="/images/edit.png" /></a> 
                          <span id=report-` +
                      row.UtilityId +
                      ` style="display:none;">` +
                      row.UtilityName +
                      `</span>
                      </div>`;
                    return button;
                  },
                },
              ],
            });
            if ($(window).width() < 1260) {
              $("#myTable").wrapAll('<div style="overflow: scroll;width: 100%">');
            }
            $(".dataTables_filter").addClass("search");
            $(".dataTables_length").addClass("search");
          } else {
            this.errormessage = result.data;
            setTimeout(() => (this.errormessage = false), 3000);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },

    editModel(id) {
      $("#editModel").modal("show");
      this.getSingleUtilityAccount(id);
    },
    closeModal() {
      $("#editModel").modal("hide");
      $(".form-control").val("");
      this.errors = [];
      this.resetData();
    },
    resetData() {
      this.errors = [];
      $("#utilityId").val("");
    },
  },
};
</script>

<style>
.dataTables_wrapper .dataTables_filter input {
  background-color: white;
}

.dataTables_wrapper .dataTables_length select {
  background-color: white;
}
div.dataTables_paginate {
  text-align: center;
}

.merge-data img {
  width: 39px;
  height: 40px;
  margin-right: 5px;
  margin-left: 5px;
}
</style>
