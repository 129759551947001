<template>
  <section class="new-quote my-customer">
    <div id="pageLoader" style="text-align: center; margin-top: 120px">
      <img src="/exp_loader.gif" style="height: 80px; width: 80px" />
    </div>
    <div class="container" id="containerHide" style="display: none">
      <div class="row">
        <div
          class="alert alert-success message"
          id="errorLoading"
          v-if="successMessageProfile"
          role="alert"
        >
          {{ successMessageProfile }}
        </div>

        <div class="col-md-6 col-6">
          <h3>Edit Your Profile</h3>
        </div>
        <div class="col-md-6 col-6">
          <div class="add-remv">
            <button @click="showModal">Change Password</button>
          </div>
        </div>
        <div class="p-q">
          <form>
            <div class="row">
              <div class="form-group col-md-6">
                <label>First Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  ref="firstName"
                  placeholder="Please Enter First Name"
                />
                <span style="color: red" v-for="error in errors" :key="error.firstName">{{
                  error.firstName
                }}</span>
              </div>
              <div class="form-group col-md-6">
                <label>Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="lastName"
                  ref="lastName"
                  placeholder="Please Enter Last Name"
                />
                <span style="color: red" v-for="error in errors" :key="error.lastName">{{
                  error.lastName
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>User Name</label>
                <input
                  type="text"
                  class="form-control"
                  :value="userData.userName"
                  disabled
                  placeholder="Please Enter User Name"
                />
              </div>
              <div class="form-group col-md-6">
                <label>Email</label>
                <input
                  type="email"
                  class="form-control"
                  :value="userData.email"
                  id="email"
                  ref="email"
                  disabled
                  placeholder="Please Enter Email"
                />
                <span style="color: red" v-for="error in errors" :key="error.email">{{
                  error.email
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>Phone</label>
                <input
                  type="number"
                  class="form-control"
                  id="phone"
                  ref="phone"
                  placeholder="Please Enter Phone"
                />
                <span style="color: red" v-for="error in errors" :key="error.phone">{{
                  error.phone
                }}</span>
              </div>

              <div class="form-group col-md-6">
                <label>Fax</label>
                <input
                  type="text"
                  class="form-control"
                  id="fax"
                  ref="fax"
                  placeholder="Please Enter Fax"
                />

                <span
                  style="color: red"
                  v-for="error in errors"
                  :key="error.lasfaxtName"
                  >{{ error.fax }}</span
                >
              </div>
            </div>
            <button type="submit" @click="updateProfile">Update Profile</button>
          </form>
        </div>
      </div>
    </div>
  </section>

  <div
    class="modal fade common-pop"
    id="changePasswordmodel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div
          class="alert alert-success message"
          v-if="successMessagePassword"
          role="alert"
        >
          {{ successMessagePassword }}
        </div>
        <div
          class="alert alert-danger message"
          id="errorLoading"
          v-if="errorMessagePassword"
          role="alert"
        >
          {{ errorMessagePassword }}
        </div>
        <div class="modal-header">
          <h2>Change Password</h2>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <div class="p-q">
            <form>
              <div class="row">
                <div class="form-group col-md-12">
                  <label>Current Password</label>
                  <input
                    type="password"
                    class="form-control changePassword"
                    id="current_password"
                    ref="current_password"
                    placeholder="Please Enter Current Password"
                  />
                  <span
                    toggle="#current_password"
                    class="fa fa-fw fa-eye field-icon toggle-password"
                  ></span>

                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.current_password"
                  >
                    {{ error.current_password }}
                  </span>
                </div>

                <div class="form-group col-md-12">
                  <label>New Password</label>
                  <input
                    type="password"
                    class="form-control changePassword"
                    id="new_password"
                    ref="new_password"
                    placeholder="Please Enter New Password"
                  />
                  <span
                    toggle="#new_password"
                    class="fa fa-fw fa-eye field-icon toggle-password"
                  ></span>

                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.new_password"
                  >
                    {{ error.new_password }}
                  </span>
                </div>
                <div class="form-group col-md-12">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    class="form-control changePassword"
                    id="confirm_password"
                    ref="confirm_password"
                    placeholder="Please Enter Confirm Password"
                  />
                  <span
                    toggle="#confirm_password"
                    class="fa fa-fw fa-eye field-icon toggle-password"
                  ></span>

                  <span
                    class="errorInput"
                    style="color: red"
                    v-for="error in errors"
                    :key="error.confirm_password"
                  >
                    {{ error.confirm_password }}
                  </span>
                </div>

                <div class="form-group col-md-12">
                  <div class="c-btn">
                    <button class="delt" type="button" @click="closeModal">Cancel</button>
                    <button class="cncl" @click="changePassword">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import constant from "../constant";

export default {
  name: "Profile",

  data() {
    return {
      userData: [],
      errors: [],
      successMessagePassword: null,
      errorMessagePassword: null,
      successMessageProfile: null,
    };
  },
  created() {
    this.getUserProfile();
  },
  mounted: function () {
    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  },
  methods: {
    getUserProfile() {
      axios({
        method: "post",
        url: constant.apiUrl + "/getUserProfile",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          user_id: localStorage.getItem("userId"),
          user_role: localStorage.getItem("role"),
        },
      })
        .then((result) => {
          if (result.status === 200) {
            $("#pageLoader").hide();
            $("#containerHide").show();
            this.userData = result.data.data;
            $("#firstName").val(result.data.data.firstName);
            $("#lastName").val(result.data.data.lastName);
            $("#phone").val(result.data.data.phone);
            $("#fax").val(result.data.data.fax);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    updateProfile(e) {
      e.preventDefault();
      this.errors = [];
      if (!this.$refs["firstName"].value) {
        this.errors.push({ firstName: "First Name is required" });
        return false;
      }
      if (!this.$refs["lastName"].value) {
        this.errors.push({ lastName: "Last Name is required" });
        return false;
      }
      if (!this.$refs["email"].value) {
        this.errors.push({ email: "Email is required" });
        return false;
      }

      var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      if (!pattern.test(this.$refs["email"].value)) {
        this.errors.push({ email: "Email must be valid formate" });
        return false;
      }

      if (!this.$refs["phone"].value) {
        this.errors.push({ phone: "Phone is required" });
        return false;
      }

      axios({
        method: "post",
        url: constant.apiUrl + "/updateUserProfile",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          userId: localStorage.getItem("userId"),
          firstName: this.$refs["firstName"].value,
          lastName: this.$refs["lastName"].value,
          email: this.$refs["email"].value,
          phone: this.$refs["phone"].value,
          fax: this.$refs["fax"].value,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.getUserProfile();
            this.successMessageProfile = result.data.message;
            setTimeout(() => (this.successMessageProfile = false), 3000);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    closeModal() {
      $("#changePasswordmodel").modal("hide");
      $(".changePassword").val("");
      $("span").html("");
      $(".toggle-password").removeClass("fa-eye-slash").addClass("fa-eye");
      $(".changePassword").attr("type", "password");
    },
    showModal() {
      $("#changePasswordmodel").modal("show");
    },
    changePassword(e) {
      e.preventDefault();

      this.errors = [];
      if (!this.$refs["current_password"].value) {
        this.errors.push({ current_password: "Current Password is required" });
        return false;
      }
      if (!this.$refs["new_password"].value) {
        this.errors.push({ new_password: "New Password is required" });
        return false;
      }

      if (this.$refs["current_password"].value == this.$refs["new_password"].value) {
        this.errors.push({
          new_password: "New Password and Current Password should not be same",
        });
        return false;
      }
      if (!this.$refs["confirm_password"].value) {
        this.errors.push({ confirm_password: "Confirm Password is required" });
        return false;
      }
      if (this.$refs["confirm_password"].value != this.$refs["new_password"].value) {
        this.errors.push({
          confirm_password: "New Password and Confirm Password should be same",
        });
        return false;
      }
      axios({
        method: "post",
        url: constant.apiUrl + "/changePassword",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          user_id: localStorage.getItem("userId"),
          current_password: this.$refs["current_password"].value,
          new_password: this.$refs["new_password"].value,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.successMessagePassword = result.data.message;
            setTimeout(() => {
              this.successMessagePassword = false;
              $("#changePasswordmodel").modal("hide");
              $(".changePassword").val("");
              $("span").html("");
              $(".toggle-password").removeClass("fa-eye-slash").addClass("fa-eye");
              $(".changePassword").attr("type", "password");
            }, 3000);
          } else {
            this.errorMessagePassword = result.data.message;
            setTimeout(() => (this.errorMessagePassword = false), 3000);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
  },
};
</script>
<style>
.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}
</style>
