<template>
  <section class="my-customer">
    <div id="exp_loader" style="text-align: center; margin-top: 120px">
      <img src="/images/exp_loader.gif" alt="" style="height: 80px; width: 80px" />
    </div>
    <div class="container" id="showDataTable" style="display: none">
      <div class="row">
        <div class="col-md-6 col-6">
          <h3>Supplier List</h3>
        </div>

        <div class="col-md-6 col-6">
          <div class="add-remv">
            <button @click="showModel">+ Add Supplier</button>
            <button>
              <router-link style="color: #f9fafb" to="/admin/managematrix"
                >Manage Matrix</router-link
              >
            </button>
            <button>
              <router-link style="color: #f9fafb" to="/admin/approverate"
                >Approved Rate
              </router-link>
            </button>
          </div>
        </div>

        <div class="col-md-12">
          <div class="cus-table">
            <div class="alert alert-success message" v-if="addmessage" role="alert">
              {{ addmessage }}
            </div>
            <div
              class="alert alert-success message"
              id="errorLoading"
              v-if="successMessageProfile"
              role="alert"
            >
              {{ successMessageProfile }}
            </div>
            <table style="text-align: center" id="myTable">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Supplier Name</th>
                  <th>Short Name</th>
                  <th>Electric</th>
                  <th>Gas</th>
                  <th class="newWidth">Notes</th>
                  <th>Load Factor</th>
                  <th>SalesforceId</th>
                  <th>Status</th>
                  <th>Logo</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade common-pop"
      id="addModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="alert alert-success message"
            v-if="successMessagePassword"
            role="alert"
          >
            {{ successMessagePassword }}
          </div>
          <div
            class="alert alert-danger message"
            id="errorLoading"
            v-if="errorMessagePassword"
            role="alert"
          >
            {{ errorMessagePassword }}
          </div>

          <div class="modal-header">
            <h2>Add Supplier</h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              @click="closeModal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="p-q">
              <form id="addForm" enctype="multipart/form-data">
                <div class="row">
                  <div class="form-group col-lg-12">
                    <input
                      type="hidden"
                      id="supplier_id2"
                      ref="supplier_id2"
                      :value="rowData.id"
                    />
                    <label>Supplier Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="supplier_name2"
                      ref="supplier_name2"
                      placeholder="Please Enter Supplier Name"
                    />

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.supplier_name2"
                    >
                      {{ error.supplier_name2 }}
                    </span>
                  </div>

                  <div class="form-group col-lg-12">
                    <label
                      >Short Name
                      <span style="font-size: 11px; font-family: ui-monospace"
                        >(Space and Special characters not allowed*)</span
                      ></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="short_name2"
                      ref="short_name2"
                      placeholder="Please Enter Short Name"
                      @change="ChangeEvent2($event)"
                    />

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.short_name2"
                    >
                      {{ error.short_name2 }}
                    </span>
                  </div>

                   <div class="form-group col-lg-12">
                    
                      <label>SalesForceId</label>
                      <input
                        type="text"
                        class="form-control"
                        id="addSalesForceId"
                        ref="addSalesForceId"
                        placeholder="Please Enter SalesForceId"
                      />
                  </div>

                  <div class="form-group col-lg-12">
                    <label>Deal with</label>
                    <div>
                      <input type="checkbox" id="check_electric" />
                      <label class="checkboxLebel" style="margin-left: 5px">
                        Electric</label
                      >
                    </div>
                    <input type="checkbox" id="check_gas" />
                    <label class="checkboxLebel" style="margin-left: 5px"> Gas</label>
                  </div>

                  <div class="form-group">
                    <label>Upload Logo</label>

                    <div>
                      <input
                        type="file"
                        @change="handleChange"
                        class="form-control"
                        id="file"
                      />
                    </div>

                    <span
                      class="errorInput"
                      id="imageFileError"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.file"
                    >
                      {{ error.file }}
                    </span>
                  </div>

                  <div class="form-group col-lg-12">
                    <label>Note</label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder="Please Enter Note"
                      id="notes2"
                      ref="addnotes2"
                    >
                    </textarea>

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.notes2"
                    >
                      {{ error.notes2 }}
                    </span>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Pricing Level (Demand) </label>
                    <select class="form-control" id="price_level_add" name="price_level">
                      <option value="Low">Low</option>
                      <option value="Med" selected>Medium</option>
                      <option value="High">High</option>
                    </select>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Status</label>
                    <select class="form-control" id="status_add" name="status">
                      <option value="1">Enabled</option>
                      <option value="0">Disabled</option>
                    </select>
                  </div>

                  <div class="form-group col-md-12">
                    <div class="c-btn">
                      <button class="delt" type="button" @click="closeModal">
                        Cancel
                      </button>
                      <button class="cncl" @click="AddSupplier">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade common-pop"
      id="changePasswordmodel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="alert alert-success message"
            v-if="successMessagePassword"
            role="alert"
          >
            {{ successMessagePassword }}
          </div>
          <div
            class="alert alert-danger message"
            id="errorLoading"
            v-if="errorMessagePassword"
            role="alert"
          >
            {{ errorMessagePassword }}
          </div>
          <div class="modal-header">
            <h2>Edit Supplier</h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              @click="closeModal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="p-q">
              <form
                enctype="multipart/form-data"
                id="editForm"
                @submit.prevent="updateSupplier"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <input
                      type="hidden"
                      id="supplier_id"
                      ref="supplier_id"
                      :value="rowData.id"
                    />
                    <div class="form-group">
                      <label>Supplier Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="supplier_name"
                        ref="supplier_name"
                        placeholder="Please Enter Supplier Name"
                      />
                    </div>

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.supplier_name"
                    >
                      {{ error.supplier_name }}
                    </span>
                  </div>

                   <div class="col-lg-12">
                    
                    <div class="form-group">
                      <label>SalesForceId</label>
                      <input
                        type="text"
                        class="form-control"
                        id="salesForceId"
                        ref="salesForceId"
                        placeholder="Please Enter SalesForceId"
                      />
                    </div>
                  </div>


                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Deal with</label>
                      <div>
                        <input type="checkbox" id="check_electric2" />
                        <label class="checkboxLebel" style="margin-left: 5px">
                          Electric</label
                        >
                      </div>
                      <input type="checkbox" id="check_gas2" />
                      <label class="checkboxLebel" style="margin-left: 5px"> Gas</label>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Upload Logo</label>

                      <input
                        type="file"
                        @change="handleChange"
                        class="form-control"
                        id="file"
                      />

                      <span
                        class="errorInput"
                        id="imageFileError"
                        style="color: red"
                        v-for="error in errors"
                        :key="error.file"
                      >
                        {{ error.file }}
                      </span>

                      <img class="" id="logo" style="height: 110px" />

                      <input type="hidden" id="logo_url" :value="logo_url" />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Note</label>
                      <quill-editor
                        v-model:content="notesmodels"
                        contentType="html"
                        theme="snow"
                      ></quill-editor>
                    </div>

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.edit_notes"
                    >
                      {{ error.edit_notes }}
                    </span>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Pricing Level (Demand) </label>
                    <select
                      class="form-control"
                      id="price_level_edit"
                      :value="price_level_edit"
                      name="price_level"
                    >
                      <option value="Low">Low</option>
                      <option value="Med">Medium</option>
                      <option value="High">High</option>
                    </select>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Status</label>
                    <select
                      class="form-control"
                      id="status_edit"
                      :value="status_edit"
                      name="status"
                    >
                      <option value="1">Enabled</option>
                      <option value="0">Disabled</option>
                    </select>
                  </div>

                  <div class="form-group col-md-12">
                    <div class="c-btn">
                      <button class="delt" type="button" @click="closeModal">
                        Cancel
                      </button>
                      <button class="cncl" type="button" @click="updateConfirmation">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- delete model -->
    <div
      class="modal fade"
      id="deletemodel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <h4>Are you sure, you want to delete Supplier?</h4>
            <div class="c-btn">
              <button class="cncl" @click="closeModal">Cancel</button>
              <button class="delt" @click="dataDelete">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end delete model -->

    <!-- update confirmation model -->
    <div
      class="modal fade"
      id="updateConfirmation"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document" style="width: 30%">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              @click="closeConf"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <h4>Are you sure, you want to update Supplier?</h4>
            <div class="c-btn">
              <button class="cncl" @click="closeConf">Cancel</button>
              <button class="delt" @click.prevent="updateSupplier">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end update confirmation model -->
  </section>
</template>

<script>
import axios from "axios";
import constant from "../../constant";
import moment from "moment";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "Providers",
  components: { QuillEditor },

  data() {
    return {
      errors: [],
      usersdata: [],
      rowData: [],
      delete_id: "",
      addmessage: null,
      supplier_name: null,
      note: null,
      successMessageProfile: null,
      supplierData: null,
      edit_notes: null,
      backendURL: constant.apiUrl,
      i: 1,
      status_edit: 1,
      price_level_edit: 2,
      logo_url: "",
      selectedFile: "",
      notesmodels: "",
    };
  },

  created() {
    this.getUsers();
  },

  methods: {
    deleteSuppliers(id) {
      $("#deletemodel").modal("show");
      this.delete_id = id;
    },

    updateConfirmation() {
      $("#updateConfirmation").modal("show");
    },

    dataDelete() {
      axios({
        method: "post",
        url: constant.apiUrl + "/deleteSupplier",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },

        data: {
          id: this.delete_id,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);

            $("#deletemodel").modal("hide");
            $("#myTable").DataTable().destroy();
            this.resetData();
            this.getUsers();
          }

          if (result.status === 202) {
            this.errors = result.data.errors;
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    getUsers(e) {
      axios({
        method: "get",
        url: constant.apiUrl + "/getProviders",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {},
      })
        .then((result) => {
          if (result.status === 200) {
            let a = 1;

            $("#exp_loader").hide();
            $("#showDataTable").show();

            $("#myTable").DataTable({
              lengthChange: false,
              language: {
                search: "",
              },
              bInfo: false,
              data: result.data.data,
              columns: [
                {
                  render: function (data, type, row, meta) {
                    return a++;
                  },
                },

                {
                  data: "providername",
                },
                {
                  data: "shortname",
                },

                {
                  render: function (data, type, row, meta) {
                    let electric = "";
                    if (row.electric === 1) {
                      electric = "Yes";
                    } else {
                      electric = "No";
                    }
                    return electric;
                  },
                },
                {
                  render: function (data, type, row, meta) {
                    let gas = "";
                    if (row.gas === 1) {
                      gas = "Yes";
                    } else {
                      gas = "No";
                    }
                    return gas;
                  },
                },

                {
                  data: "notes",
                },

                {
                  render: function (data, type, row, meta) {
                    let loadFactor = "";
                    if (row.pricing_lable === "Med") {
                      loadFactor = "Medium";
                    } else {
                      loadFactor = row.pricing_lable;
                    }
                    return loadFactor;
                  },
                },
                {
                  data: "SalesForceId",
                },

                {
                  render: function (data, type, row, meta) {
                    let status = "";
                    if (row.status === 1) {
                      status = "Enabled";
                    } else {
                      status = "Disabled";
                    }
                    return status;
                  },
                },

                {
                  render: function (data, type, row, meta) {
                    if (row.logo == "" || row.logo === null || row.logo === "null") {
                      var logo = "N/A";
                    } else {
                      var image = `${constant.backendUrl}/${row.logo}`;
                      var logo =
                        `<div class="e-d">
                                                  <img src=` +
                        image +
                        ` style="width:70px" /> 
                                                </div>`;
                    }

                    return logo;
                  },
                },

                {
                  render: function (data, type, row, meta) {
                    let button =
                      `<div class="e-d">
                      <a href="#"  class="edit-data" data-id=` +
                      row.id +
                      `><img src="/images/edit.png" /></a>

                      <a href="#" class="delete-data" data-id=` +
                      row.id +
                      ` ><img src="/images/dlt.png" /></a> 
                    </div>`;
                    return button;
                  },
                },
              ],
            });
            if ($(window).width() < 1260) {
              $("#myTable").wrapAll('<div style="overflow: scroll;width: 100%">');
            }
            $(".dataTables_filter").addClass("search");
            $(".dataTables_length").addClass("search");
          } else {
            this.errormessage = result.data;
            setTimeout(() => (this.errormessage = false), 3000);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },

    getOneSupplier(id) {
      axios({
        method: "post",
        url: constant.apiUrl + "/getOneSupplier",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          id: id,
        },
      })
        .then((result) => {
          this.resetData();
          if (result.status === 200) {
            this.resetData();
            $("#file").val("");

            $("#editForm")[0].reset();

            this.rowData = result.data.data;

            $("#supplier_name").val(result.data.data.providername);
            $("#salesForceId").val(result.data.data.SalesForceId);


            

            this.notesmodels = result.data.data.notes;

            this.status_edit = result.data.data.status;
            this.price_level_edit = result.data.data.pricing_lable;

            if (
              result.data.data.logo == "" ||
              result.data.data.logo === null ||
              result.data.data.logo === "null"
            ) {
              $("#logo").attr("src", "");
            } else {
              $("#logo").attr("src", constant.backendUrl + "/" + result.data.data.logo);
            }

            this.logo_url = result.data.data.logo;

            if (result.data.data.gas == 1) {
              $("#check_gas2").prop("checked", true);
            }
            if (result.data.data.electric == 1) {
              $("#check_electric2").prop("checked", true);
            }
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    getAllSupplier(id = null) {
      axios({
        method: "get",

        url: constant.apiUrl + "/getProviders",

        headers: {
          "Content-Type": "application/json",

          "x-access-token": localStorage.getItem("token"),
        },

        data: {
          id: id,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.supplierData = result.data.data;
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    handleChange(event) {
      $("#imageFileError").html("");
      this.selectedFile = event.target.files[0];

      const fileExtension = this.selectedFile.name.split(".")[1];

      if ($.inArray(fileExtension, ["gif", "png", "jpg", "jpeg"]) == -1) {
        this.errors.push({
          file: "Only Image file  is allowed",
        });
        return false;
      }

      if (this.selectedFile) {
        this.blah = URL.createObjectURL(this.selectedFile);

        $(".image-box2").attr("src", this.blah);
      }
    },

    updateSupplier(e) {
      $(".unique_other_error").html("");
      this.errors = [];
      var supplier_name = $("#supplier_name").val();
      var edit_notes = $(".ql-editor").html();
      var supplier_id = $("#supplier_id").val();
      var price_level_edit = $("#price_level_edit").val();
      var status_edit = $("#status_edit").val();

      var logo_url = $("#logo_url").val();
      var gas = $("#check_gas2").is(":checked");

      if (gas == true) {
        gas = 1;
      } else {
        gas = 0;
      }
      var electric = $("#check_electric2").is(":checked");

      if (electric == true) {
        electric = 1;
      } else {
        electric = 0;
      }

      if (!supplier_name) {
        this.errors.push({
          supplier_name: "Supplier Name is required",
        });
        return false;
      }

      const logo = this.selectedFile;

      if (logo !== "") {
        const fileExtension = this.selectedFile.name.split(".")[1];
        if ($.inArray(fileExtension, ["gif", "png", "jpg", "jpeg"]) == -1) {
          this.errors.push({
            file: "Only Image file  is allowed",
          });
          return false;
        }
      }

      var bodyFormData = new FormData();
      bodyFormData.append("Id", supplier_id);
      bodyFormData.append("providername", supplier_name);
      bodyFormData.append("gas", gas);
      bodyFormData.append("electric", electric);
      bodyFormData.append("notes", edit_notes);
      bodyFormData.append("file", this.selectedFile);
      bodyFormData.append("logo_url", this.logo_url);
      bodyFormData.append("status", status_edit);
      bodyFormData.append("price_level", price_level_edit);
      bodyFormData.append("SalesForceId", $("#salesForceId").val());


      axios({
        method: "post",
        url: constant.apiUrl + "/updateProvider",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: bodyFormData,
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.successMessageProfile = result.data.message;
            setTimeout(() => (this.successMessageProfile = false), 3000);

            $("#updateConfirmation").modal("hide");
            $("#changePasswordmodel").modal("hide");
            $("#myTable").DataTable().destroy();
            this.resetData();
            this.getUsers();
          }
          if (result.status === 202) {
            $("#supplier_name").after(
              '<span class="unique_other_error errorInput" style="color:red;">' +
                result.data.message +
                "</span>"
            );
            $("html, #addModel").animate(
              {
                scrollTop: $("#supplier_name").offset().top,
              },
              500
            );
            return false;
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    AddSupplier(e) {
      e.preventDefault();
      $(".unique_other_error").html("");
      $(".unique_other_error2").html("");
      this.errors = [];

      var supplier_name2 = $("#supplier_name2").val();
      var short_name2 = $("#short_name2").val();
      var notes2 = $("#notes2").val();
      var price_level_add = $("#price_level_add").val();
      var status_add = $("#status_add").val();

      var status = 1;
      var gas = $("#check_gas").is(":checked");
      if (gas == true) {
        gas = 1;
      } else {
        gas = 0;
      }
      var electric = $("#check_electric").is(":checked");

      if (electric == true) {
        electric = 1;
      } else {
        electric = 0;
      }

      if (!supplier_name2) {
        this.errors.push({
          supplier_name2: "Supplier Name is required",
        });
        return false;
      }

      if (!short_name2) {
        this.errors.push({
          short_name2: "Short Name is required",
        });
        return false;
      }

      const logo = this.selectedFile;

      if (logo !== "") {
        const fileExtension = this.selectedFile.name.split(".")[1];
        if ($.inArray(fileExtension, ["gif", "png", "jpg", "jpeg"]) == -1) {
          this.errors.push({
            file: "Only Image file  is allowed",
          });
          return false;
        }
      }

      var bodyFormData = new FormData();

      bodyFormData.append("providername", supplier_name2);
      bodyFormData.append("shortname", short_name2);
      bodyFormData.append("notes", notes2);
      bodyFormData.append("gas", gas);
      bodyFormData.append("electric", electric);
      bodyFormData.append("file", this.selectedFile);
      bodyFormData.append("status", status_add);
      bodyFormData.append("price_level", price_level_add);
      bodyFormData.append("SalesForceId", $("#addSalesForceId").val());


      

      axios({
        method: "post",
        url: constant.apiUrl + "/addSupplier",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },

        data: bodyFormData,
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.successMessageProfile = result.data.message;
            setTimeout(() => (this.successMessageProfile = false), 3000);
            this.closeModal();
            $("#myTable").DataTable().destroy();
            this.resetData();
            this.getUsers();
          }
          if (result.status === 202) {
            $("#supplier_name2").after(
              '<span class="unique_other_error errorInput" style="color:red;">' +
                result.data.message +
                "</span>"
            );
            $("html, #addModel").animate(
              {
                scrollTop: $("#supplier_name2").offset().top,
              },
              500
            );

            return false;
          }
          if (result.status === 203) {
            $("#short_name2").after(
              '<span class="unique_other_error2 errorInput" style="color:red;">' +
                result.data.message +
                "</span>"
            );
            $("html, #addModel").animate(
              {
                scrollTop: $("#short_name2").offset().top,
              },
              500
            );

            return false;
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    ChangeEvent(event) {
      $(".unique_other_error").html("");
      var providername = event.target.value;
      var supplier_id = $("#supplier_id").val();
      axios({
        method: "post",
        url: constant.apiUrl + "/checkUniqueName",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          providername: providername,
          id: supplier_id,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            if (result.data.data.length > 0) {
              $("#supplier_name").after(
                '<span class="unique_other_error errorInput" style="color:red;">Supplier Name should be unique</span>'
              );
              $("html, #changePasswordmodel").animate(
                {
                  scrollTop: $("#supplier_name").offset().top,
                },
                500
              );

              $("#supplier_name").val("");
              return false;
            }
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    async ChangeEvent2(event) {
      var providername = event.target.value;
      var short_name_test = providername.replace(/[^A-Z0-9]/gi, "");
      $("#short_name2").val(short_name_test);
    },

    showModel() {
      this.selectedFile = "";
      $("#addModel").modal("show");
      $(".ql-editor").html("");
    },

    showModel2() {
      $("#addMatrixModel").modal("show");
      this.getAllSupplier();
    },

    showEditModel(id) {
      $("#file").val("");
      $("#changePasswordmodel").modal("show");
      this.getOneSupplier(id);
    },

    closeModal() {
      this.resetData();
      this.selectedFile = "";

      $("#deletemodel").modal("hide");
      $("#changePasswordmodel, #addModel").modal("hide");
    },

    closeConf() {
      $("#updateConfirmation").modal("hide");
    },

    resetData() {
      this.errors = [];
      this.supplier_id = "";
      this.supplier_name = "";
      this.notes = "";

      $("#supplier_name2, #supplier_name").val("");
      $("#short_name2, #short_name").val("");
      $("#notes2, #notes").val("");
      $("#edit_notes").val("");
      $(".unique_other_error").html("");
      $("#check_gas2").prop("checked", false);
      $("#check_electric2").prop("checked", false);
      $("#check_gas").prop("checked", false);
      $("#check_electric").prop("checked", false);
      $("#file").val("");
      $("#file").val("");
      $("#addSalesForceId, #salesForceId").val("");

    },
  },

  mounted: function () {
    const table = $(this.$refs.table).dataTable();
    const self = this;

    $("tbody", this.$refs.table).on("click", ".edit-data", function (e) {
      var ddid = $(this).attr("data-id").split(",");
      self.showEditModel(ddid);
    });

    $("tbody", this.$refs.table).on("click", ".delete-data", function (e) {
      const id = $(this).attr("data-id");

      self.deleteSuppliers(id);
    });
  },
};
</script>

<style>
.dataTables_wrapper .dataTables_filter input {
  background-color: white;
}

.dataTables_wrapper .dataTables_length select {
  background-color: white;
}

div.dataTables_paginate {
  text-align: center;
}

.message {
  text-align: center;
}

.newWidth {
  width: 35% !important;
}
</style>
