<template>
  <section class="my-customer">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-6">
          <h3>Matrix List</h3>
        </div>

        <div class="col-md-6 col-6">
          <div class="add-remv">
            <button @click="showModel">+ Add Matrix</button>
          </div>
        </div>

        <div class="col-md-12">
          <div class="cus-table">
            <div class="alert alert-success message" v-if="addmessage" role="alert">
              {{ addmessage }}
            </div>

            <div
              class="alert alert-success message"
              id="errorLoading"
              v-if="successMessageProfile"
              role="alert"
            >
              {{ successMessageProfile }}
            </div>

            <table style="text-align: center" id="myTable">
              <thead>
                <tr>
                  <th class="newWidth">Supplier Name</th>
                  <th class="newWidth">Matrix Name</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade common-pop"
      id="addMatrixModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="alert alert-success message"
            v-if="successMessagePassword"
            role="alert"
          >
            {{ successMessagePassword }}
          </div>
          <div
            class="alert alert-danger message"
            id="errorLoading"
            v-if="errorMessagePassword"
            role="alert"
          >
            {{ errorMessagePassword }}
          </div>
          <div class="modal-header">
            <h2>Add Matrix</h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              @click="closeModal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="p-q">
              <form enctype="multipart/form-data" id="form-addMatrix">
                <div class="row">
                  <div class="form-group col-md-12" id="userTypeSelect">
                    <label>Supplier</label>
                    <select class="form-control" id="suppliermid">
                      <option value="" selected="selected">
                        Please Select Supplier Name
                      </option>

                      <option
                        v-for="item in supplierData"
                        :value="item.id"
                        :key="item.id"
                      >
                        {{ item.providername }}
                      </option>
                    </select>
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.suppliermid"
                    >
                      {{ error.suppliermid }}
                    </span>
                  </div>

                  <div class="form-group col-lg-12">
                    <input
                      type="hidden"
                      id="supplier_id2"
                      ref="supplier_id2"
                      :value="rowData.id"
                    />

                    <label>Matrix Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="matrix_name"
                      ref="matrix_name"
                      placeholder="Please Enter Matrix Name"
                    />

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.matrix_name"
                    >
                      {{ error.matrix_name }}
                    </span>
                  </div>

                  <div class="form-group col-md-12" id="userTypeSelect">
                    <label>Energy Type</label>
                    <select class="form-control" id="energy_type">
                      <option value="" selected="selected">
                        Please Select Energy Type
                      </option>
                      <option :value="1">Electric</option>
                      <option :value="2">Gas</option>
                      <option :value="3">Both</option>
                    </select>

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.energy_type"
                    >
                      {{ error.energy_type }}
                    </span>
                  </div>

                  <div class="form-group col-md-12 exe-section" id="userTypeSelect">
                    <label>Exe File</label>
                    <input
                      type="file"
                      @change="handleChange"
                      class="form-control"
                      id="file"
                    />

                    <span
                      class="errorInput"
                      id="exeFileError"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.file"
                    >
                      {{ error.file }}
                    </span>
                  </div>
                  <div class="form-group col-md-12">
                    <div class="c-btn">
                      <button class="delt" type="button" @click="closeModal">
                        Cancel
                      </button>
                      <button class="cncl" @click="AddMnageMatrix">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade common-pop"
      id="updateMatrixModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="alert alert-success message"
            v-if="successMessagePassword"
            role="alert"
          >
            {{ successMessagePassword }}
          </div>
          <div
            class="alert alert-danger message"
            id="errorLoading"
            v-if="errorMessagePassword"
            role="alert"
          >
            {{ errorMessagePassword }}
          </div>
          <div class="modal-header">
            <h2>Update Matrix</h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              @click="closeModal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="p-q">
              <form enctype="multipart/form-data" id="form-editManageMatrix">
                <input type="hidden" id="edit_matrix_id" ref="edit_matrix_id" />
                <div class="row">
                  <div class="form-group col-md-12" id="userTypeSelect">
                    <label>Supplier</label>

                    <select
                      class="form-control"
                      id="edit_supplier_id"
                      :value="edit_supplier_id"
                    >
                      <option value="" selected="selected">
                        Please Select Supplier Name
                      </option>

                      <option
                        v-for="item in supplierData"
                        :value="item.id"
                        :key="item.id"
                      >
                        {{ item.providername }}
                      </option>
                    </select>
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.edit_supplier_id"
                    >
                      {{ error.edit_supplier_id }}
                    </span>
                  </div>

                  <div class="form-group col-lg-12">
                    <label>Matrix Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="edit_matrix_name"
                      ref="edit_matrix_name"
                      placeholder="Please Enter Matrix Name"
                    />

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.edit_matrix_name"
                    >
                      {{ error.edit_matrix_name }}
                    </span>
                  </div>

                  <div class="form-group col-md-12" id="userTypeSelect">
                    <label>Energy Type</label>
                    <select class="form-control" id="edit_energy_type">
                      <option value="" selected="selected">
                        Please Select Energy Type
                      </option>
                      <option :value="1">Electric</option>
                      <option :value="2">Gas</option>
                      <option :value="3">Both</option>
                    </select>
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.energy_type"
                    >
                      {{ error.energy_type }}
                    </span>
                  </div>

                  <div class="form-group col-md-12 exe-section" id="userTypeSelect">
                    <label>Exe File</label>
                    <input
                      type="file"
                      @change="handleChange"
                      class="form-control"
                      id="file"
                    />

                    <span
                      class="errorInput"
                      id="exeFileError"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.file"
                    >
                      {{ error.file }}
                    </span>

                    <input type="hidden" name="exe_path" id="exe_path" />
                  </div>

                  <div class="form-group col-md-12">
                    <div class="c-btn">
                      <button class="delt" type="button" @click="closeModal">
                        Cancel
                      </button>
                      <button class="cncl" @click="updateManageMatrix">Update</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

import constant from "../../constant";

import moment from "moment";
import { file } from "@babel/types";

export default {
  name: "ManageMatrix",

  data() {
    return {
      errors: [],
      usersdata: [],
      edit_supplier_id: "",
      rowData: [],
      delete_id: "",
      addmessage: null,
      supplier_name: null,
      note: null,
      successMessageProfile: null,
      supplierData: [],
      matrixData: [],
    };
  },

  created() {
    this.getManageMatrix();
  },

  methods: {
    getManageMatrix(e) {
      axios({
        method: "get",
        url: constant.apiUrl + "/getManageMatrix",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },

        data: {},
      })
        .then((result) => {
          if (result.status === 200) {
            $("#myTable").DataTable({
              lengthChange: false,

              language: { search: "" },

              bInfo: false,

              data: result.data.data,

              columns: [
                { data: "providername" },
                { data: "matrix_name" },
                {
                  render: function (data, type, row, meta) {
                    let button =
                      `<div class="e-d">
                      <a href="#"  class="edit-data" data-id=` +
                      row.id +
                      `><img src="/images/edit.png" /></a>
                    </div>`;
                    return button;
                  },
                },
              ],
            });

            $(".dataTables_filter").addClass("search");

            $(".dataTables_length").addClass("search");
          } else {
            this.errormessage = result.data;

            setTimeout(() => (this.errormessage = false), 3000);
          }
        })

        .catch((err) => {
          console.log("error", err);
        });
    },

    getAllSupplier(id) {
      axios({
        method: "get",

        url: constant.apiUrl + "/getProviders",

        headers: {
          "Content-Type": "application/json",

          "x-access-token": localStorage.getItem("token"),
        },

        data: {
          id: id,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.supplierData = result.data.data;
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    getAndSetOnChange(event) {
      $("#expnew_loader2").css("display", "block");

      var id = event.target.value;
      var supplierData = this.supplierData;

      const result = supplierData.filter((word) => word.id == id);
      $("#shortname").val(result[0].shortname);

      axios({
        method: "post",

        url: constant.apiUrl + "/getSupplierMatrix",

        headers: {
          "Content-Type": "application/json",

          "x-access-token": localStorage.getItem("token"),
        },

        data: {
          id: id,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.matrixData = result.data.data;
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    handleChange(event) {
      $("#exeFileError").html("");
      this.selectedFile = event.target.files[0];

      const fileExtension = this.selectedFile.name.split(".")[1];

      if ($.inArray(fileExtension, ["exe"]) == -1) {
        this.errors.push({ file: "Only exe file  is allowed" });
        return false;
      }
    },

    AddMnageMatrix(e) {
      e.preventDefault();
      this.errors = [];

      var supplier_id = $("#suppliermid").val();
      var matrix_name = $("#matrix_name").val();
      var energy_type = $("#energy_type").val();

      if (!supplier_id) {
        this.errors.push({ suppliermid: "Supplier Name is required" });
        return false;
      }

      if (!matrix_name) {
        this.errors.push({ matrix_name: "Matrix Name is required" });
        return false;
      }

      if (!energy_type) {
        this.errors.push({ energy_type: "Energy type is required" });
        return false;
      }

      if (this.selectedFile != undefined) {
        const fileExtension = this.selectedFile.name.split(".")[1];

        if ($.inArray(fileExtension, ["exe"]) == -1) {
          this.errors.push({ file: "Only Exe file  is allowed" });
          return false;
        }
      }

      const form = document.getElementById("form-addMatrix");

      var bodyFormData = new FormData();
      bodyFormData.append("supplier_id", supplier_id);
      bodyFormData.append("matrix_name", matrix_name);
      bodyFormData.append("energy_type", energy_type);
      bodyFormData.append("file", this.selectedFile);
      axios({
        method: "post",
        url: constant.apiUrl + "/addManageMatrix",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },

        data: bodyFormData,
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.successMessageProfile = result.data.message;
            setTimeout(() => (this.successMessageProfile = false), 3000);
            this.closeModal();
            $("#myTable").DataTable().destroy();
            this.resetData();
            this.getManageMatrix();
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    getOneMatrix(id) {
      $("#edit_matrix_id").val(id);
      axios({
        method: "post",
        url: constant.apiUrl + "/getOneMatrix",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          id: id,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.edit_supplier_id = result.data.data.provider_id;
            $("#edit_matrix_name").val(result.data.data.matrix_name);
            $("#edit_energy_type").val(result.data.data.energy_type);
            $("#exe_path").val(result.data.data.exe_path);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    updateManageMatrix(e) {
      e.preventDefault();
      $(".unique_other_error").html("");
      this.errors = [];

      const supplier_id = $("#edit_supplier_id").val();
      const matrix_name = $("#edit_matrix_name").val();
      const matrix_id = $("#edit_matrix_id").val();
      const energy_type = $("#edit_energy_type").val();
      const exe_path = $("#exe_path").val();

      if (!supplier_id) {
        this.errors.push({ edit_supplier_id: "Supplier Name is required" });
        return false;
      }

      if (!matrix_name) {
        this.errors.push({ edit_matrix_name: "Matrix Name is required" });
        return false;
      }

      if (!energy_type) {
        this.errors.push({ energy_type: "Energy type is required" });
        return false;
      }

      const form = document.getElementById("form-editManageMatrix");

      var bodyFormData = new FormData();
      bodyFormData.append("Id", matrix_id);
      bodyFormData.append("supplier_id", supplier_id);
      bodyFormData.append("matrix_name", matrix_name);
      bodyFormData.append("energy_type", energy_type);
      bodyFormData.append("exe_path", exe_path);
      bodyFormData.append("file", this.selectedFile);

      axios({
        method: "post",
        url: constant.apiUrl + "/updateMangeMatrix",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: bodyFormData,
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.successMessageProfile = result.data.message;
            setTimeout(() => (this.successMessageProfile = false), 3000);

            $("#updateMatrixModel").modal("hide");
            $("#myTable").DataTable().destroy();
            this.resetData();
            this.getManageMatrix();
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    ChangeEvent(event) {
      $(".unique_other_error").html("");

      var providername = event.target.value;

      var supplier_id = $("#supplier_id").val();

      axios({
        method: "post",

        url: constant.apiUrl + "/checkUniqueName",

        headers: {
          "Content-Type": "application/json",

          "x-access-token": localStorage.getItem("token"),
        },

        data: {
          providername: providername,

          id: supplier_id,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            if (result.data.data.length > 0) {
              $("#supplier_name").after(
                '<span class="unique_other_error errorInput" style="color:red;">Supplier Name should be unique</span>'
              );

              $("html, #changePasswordmodel").animate(
                {
                  scrollTop: $("#supplier_name").offset().top,
                },
                500
              );

              $("#supplier_name").val("");

              return false;
            }
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    async ChangeEvent2(event) {
      var providername = event.target.value;
      var short_name_test = providername.replace(/[^A-Z0-9]/gi, "");
      $("#short_name2").val(short_name_test);
    },

    showModel() {
      $("#addMatrixModel").modal("show");
      this.getAllSupplier();
    },

    showEditModel(id) {
      $("#updateMatrixModel").modal("show");
      this.getOneMatrix(id);
      this.getAllSupplier();
    },

    closeModal() {
      this.resetData();
      $("#updateMatrixModel, #addMatrixModel").modal("hide");
    },

    resetData() {
      this.errors = [];
      this.supplier_id = "";
      this.supplier_name = "";
      this.edit_supplier_id = "";
      this.notes = "";
      $("#uploadMatrix").trigger("reset");

      $("#supplier_name2, #supplier_name").val("");

      $("#short_name2, #short_name").val("");

      $("#notes2, #notes").val("");

      $(".unique_other_error").html("");

      $("#suppliermid").val("");

      $("#matrix_name").val("");

      $("#edit_suppliermid").val("");

      $("#edit_matrix_name").val("");

      $("#energy_type").val("");

      $("#edit_energy_type").val("");

      $("#file").val("");
    },
  },

  mounted: function () {
    const table = $(this.$refs.table).dataTable();

    const self = this;

    $("tbody", this.$refs.table).on("click", ".edit-data", function (e) {
      var ddid = $(this).attr("data-id").split(",");
      self.showEditModel(ddid);
    });
  },
};
</script>

<style>
.dataTables_wrapper .dataTables_filter input {
  background-color: white;
}

.dataTables_wrapper .dataTables_length select {
  background-color: white;
}

div.dataTables_paginate {
  text-align: center;
}

.message {
  text-align: center;
}

.newWidth {
  width: 30% !important;
}
.exe-section{
  display:none;
}
</style>
