<template>
  <section class="my-customer">
    <div id="utility_loader" style="text-align: center; margin-top: 120px">
      <img src="/images/exp_loader.gif" alt="" style="height: 80px; width: 80px" />
    </div>
    <div class="container" id="utilityDataTable" style="display: none">
      <div class="row">
        <div class="col-md-6 col-6">
          <h3>APPROVED RATES</h3>
        </div>
        <div class="col-md-12">
          <div class="cus-table">
            <div class="alert alert-success message" v-if="addmessage" role="alert">
              {{ addmessage }}
            </div>
            <table style="text-align: center" id="myTable">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Supplier</th>
                  <th>Utility Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Merge Utility Model start -->
    <div
      class="modal fade common-pop"
      id="mergeModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="alert alert-success message"
            v-if="successMessagePopup"
            role="alert"
          >
            {{ successMessagePopup }}
          </div>
          <div
            class="alert alert-danger message"
            id="errorLoading"
            v-if="errorMessagePopup"
            role="alert"
          >
            {{ errorMessagePopup }}
          </div>
          <div class="modal-header">
            <h2>Select Parent Utilities For Merge With</h2>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="p-q">
              <form id="editForm">
                <input
                  type="hidden"
                  class="form-control"
                  name="merge_provider_id"
                  value=""
                  id="merge_provider_id"
                />

                <div class="row">
                  <div class="form-group col-md-12">
                    <VueMultiselect
                      v-model="selected"
                      :options="utilityMerge"
                      :multiple="true"
                      :close-on-select="true"
                      placeholder="Pick Parent Utilities"
                      label="UtilityName"
                      track-by="UtilityName"
                      @select="optionSelected"
                      @remove="removeSelected"
                    />
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.edit_utilitie_name"
                    >
                      {{ error.edit_utilitie_name }}
                    </span>
                  </div>

                  <div class="form-group col-md-12">
                    <div class="c-btn">
                      <button class="delt" @click="closeModal" type="button">
                        Cancel
                      </button>
                      <button class="cncl" type="submit" @click="mergeApproveRate">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Merge Utility Model end -->
  </section>
</template>

<script>
import axios from "axios";
import constant from "../../constant";
import VueMultiselect from "vue-multiselect";

export default {
  name: "SupplierUtilityApprovedRate",
  components: {
    VueMultiselect,
  },
  data() {
    return {
      utilityData: [],
      delete_id: "",
      addmessage: null,
      utilityId: "",
      errors: [],
      successMessagePopup: null,
      errorMessagePopup: null,
      states: [],
      utilityMerge: [],
      delete_id: null,
      selected: null,
      //   options:[],
      addParentUtilities: [],
    };
  },

  created() {
    this.getApproveRates();
  },

  mounted: function () {
    const table = $(this.$refs.table).dataTable();
    const self = this;

    $("tbody", this.$refs.table).on("click", ".merge-data", function (e) {
      const providerId = $(this).attr("data-id");
      var UtilityName = $("#report-" + providerId).text();
      var parentId = $(this).attr("data-parent-id");

      self.mergeModel(providerId, UtilityName, parentId);
    });

    $("tbody", this.$refs.table).on("click", ".remove-merge-data", function (e) {
      const providerId = $(this).attr("data-id");
      if ($(this).attr("data-parent-id") === "0") {
        alert("There is no Parent Utility found");
        return false;
      }
      self.removeMergeApproveRate(providerId);
    });
  },
  methods: {
    removeMergeApproveRate(providerId) {
      let text =
        "Are you sure, you want to remove these Utilities from Approved Rate display??";
      if (confirm(text) == false) {
        return false;
      }
      axios({
        method: "post",
        url: constant.apiUrl + "/removeMergeApproveRate",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },

        data: {
          providerId: providerId,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);

            $("#myTable").DataTable().destroy();

            this.getApproveRates();
          }

          if (result.status === 202) {
            this.errors = result.data.errors;
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    getUtilityforMerge(providerId, UtilityName, parentId) {
      axios({
        method: "post",
        url: constant.apiUrl + "/getUtilityforMerge",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          utilityId: providerId,
        },
      })
        .then((result) => {
          if (result.status === 200) {

            // selecting mutliple parent data from dropdown start here
            if (parentId !== "0") {
              var arraydata = parentId.split(",");
              let parentDataFilter = [];
              let parentIdsFilter = [];
              arraydata.map((item) => {
                const filterdata = result.data.data.filter((element) => {
                  return element.UtilityId == item;
                });
                if (filterdata[0] !== undefined) {
                  parentDataFilter.push(filterdata[0]);
                }
                parentIdsFilter.push(parseInt(item));
              });

              this.selected = parentDataFilter;
              this.addParentUtilities = parentIdsFilter;
            }
            // selecting mutliple parent data from dropdown end here

            this.utilityMerge = result.data.data;
            $("#merge_provider_id").val(providerId);
            if (parentId !== null) {
              $("#merge_utility").val(parentId);
            } else {
              $("#merge_utility").val(0);
            }
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    optionSelected(option, id) {
      this.errors = [];
      const addParentUtilities = this.addParentUtilities;
      addParentUtilities.push(option.UtilityId);
      this.addParentUtilities = addParentUtilities;
    },

    removeSelected(option, id) {
      const parentUtilities = this.addParentUtilities;
      const index = parentUtilities.indexOf(option.UtilityId);
      if (index > -1) {
        // only splice array when item is found
        parentUtilities.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.addParentUtilities = parentUtilities;
    },

    mergeApproveRate(e) {
      e.preventDefault();
      this.errors = [];

      var providerId = $("#merge_provider_id").val();
      const parentUtilities = this.addParentUtilities;

      if (parentUtilities.length === 0) {
        this.errors.push({ edit_utilitie_name: "Utility is required" });
        return false;
      }
      if (
        confirm(
          "Are you sure, you want to merge these Utilities to Approved Rate displays?"
        ) == false
      ) {
        return false;
      }

      axios({
        method: "post",
        url: constant.apiUrl + "/mergeApproveRate",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {
          providerId: providerId,
          parentUtilities: parentUtilities,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            window.scrollTo(0, 0);
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);

            $("#mergeModel").modal("hide");
            $("#myTable").DataTable().destroy();

            this.getApproveRates();
            this.resetData();
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    getApproveRates(e) {
      axios({
        method: "get",
        url: constant.apiUrl + "/getApproveRates",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {},
      })
        .then((result) => {
          if (result.status === 200) {
            let a = 1;

            $("#utility_loader").hide();
            $("#utilityDataTable").show();

            $("#myTable").DataTable({
              lengthChange: false,
              language: {
                search: "",
              },

              bInfo: false,
              data: result.data.data,
              columns: [
                {
                  render: function (data, type, row, meta) {
                    return a++;
                  },
                },
                {
                  data: "providername",
                },

                {
                  data: "parentUtility",
                },

                {
                  data: "",
                  name: "",
                  orderable: false,
                  searchable: false,
                  render: function (data, type, row, meta) {
                    let button =
                      `<div class="e-d"> </a> 

                                            <a href="#" class="merge-data" data-id=` +
                      row.providerId +
                      ` data-parent-id=` +
                      row.parentUtilityId +
                      ` title="merging" ><img src="/images/merge.png" /></a>

                                            <a href="#" class="remove-merge-data" data-id=` +
                      row.providerId +
                      `   data-parent-id=` +
                      row.parentUtilityId +
                      ` title="remove merging">
                <img src="/images/seperate.png" style="width:38px;"/></a> 
                                            
                                            </div>`;
                    return button;
                  },
                },
              ],
            });
            if ($(window).width() < 1260) {
              $("#myTable").wrapAll('<div style="overflow: scroll;width: 100%">');
            }
            $(".dataTables_filter").addClass("search");
            $(".dataTables_length").addClass("search");
          } else {
            this.errormessage = result.data;
            setTimeout(() => (this.errormessage = false), 3000);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },

    mergeModel(id, UtilityName, parentId) {
      this.selected = null;
      this.addParentUtilities = [];

      $("#mergeModel").modal("show");
      this.getUtilityforMerge(id, UtilityName, parentId);
    },

    closeModal() {
      $("#mergeModel").modal("hide");
      $(".form-control").val("");

      this.errors = [];
      this.resetData();
    },

    resetData() {
      this.errors = [];
      $("#utilitie ,#edit_utilitie_name").val();
      $("#display_name, #edit_display_name").val();
      $("#notes_add, #edit_notes").val();
      $("#states_add, #edit_State_ID").val();
      $("#status_add, #edit_status").val();

      $("#UserIdAdded").val("");
      $("#utilityId").val("");
      $("#merge_provider_id").val("");
      $("#merge_utility").val("");
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.dataTables_wrapper .dataTables_filter input {
  background-color: white;
}

.dataTables_wrapper .dataTables_length select {
  background-color: white;
}

div.dataTables_paginate {
  text-align: center;
}

.merge-data img {
  width: 39px;
  height: 40px;
  margin-right: 5px;
  margin-left: 5px;
}
</style>
