<template>
  <section class="lf">
    <label class="lf-title">Load Factor Calculator</label>
    <div class="lf-wrap">
      <div class="lf-inputs">
        <div>
          <input
            v-model="monthlyUsage"
            type="number"
            class="form-control lf-input" />
          <label class="lf-label">Monthly Usage (kWh)</label>
        </div>
        <div>
          <input
            v-model="billedDemand"
            type="number"
            class="form-control lf-input" />
          <label class="lf-label">Billed Demand (kW)</label>
        </div>
      </div>
      <div>
        <input
          type="text"
          class="form-control lf-input"
          v-model="loadFactor"
          disabled />
        <label class="lf-label"> Load Factor Result </label>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "LoadFactorCalculator",

  data() {
    return {
      monthlyUsage: null,
      billedDemand: null,
    };
  },
  computed: {
    // Monthly Load Factor = Total kWh throughout the month / (peak load in kW) x 720 hrs
    loadFactor: function () {
      if (!!(this.monthlyUsage  && this.billedDemand)) {
        return ((this.monthlyUsage / (this.billedDemand * 720)) * 100 ).toFixed(2) + "%";
      } 
      else {
        return "N/A";
      }
    }
  },
};
</script>
<style>
.lf {
  padding: 10px 0 10px 0;
}
.lf-title {
  font-weight: bold;
  padding-bottom: 10px;
}
.lf-input {
  display: flex;
  margin: 5px 0 5px 0;
  max-width: 60%;
}
.lf-label {
  color: #515c5c;
  padding-bottom: 10px;
}
.lf-inputs {
  display: flex;
  flex-direction: row;
  border-right: 2px solid black;
  margin-right: 25px;
}
.lf-wrap {
  display: flex;
  flex-direction: row;
  width: 300%;
}
</style>
