<template>
  <section class="my-customer">
    <div id="exp_loader" style="text-align: center; margin-top: 120px">
      <img
        src="/images/exp_loader.gif"
        alt=""
        style="height: 80px; width: 80px" />
    </div>
    <div class="container" id="showDataTable" style="display: none">
      <div class="row">
        <div class="col-md-12 col-6">
          <div class="add-remv" style="float: right">
            <router-link :to="{ path: `/admin/utilities` }" class="backbutton"
              >Back to Utilities
            </router-link>
          </div>

          <h3 v-if="$route.query.id == '1'">Electric Supplier Utility List</h3>
          <h3 v-if="$route.query.id == '2'">Gas Supplier Utility List</h3>
        </div>

        <div class="col-md-12">
          <div class="cus-table">
            <div
              class="alert alert-success message"
              v-if="addmessage"
              role="alert">
              {{ addmessage }}
            </div>

            <div
              class="alert alert-success message"
              id="errorLoading"
              v-if="successMessageProfile"
              role="alert">
              {{ successMessageProfile }}
            </div>

            <div v-for="(item, indexes) in supplierUtilityData">
              <table
                style="text-align: center; margin-bottom: 15px"
                id="myTable">
                <thead>
                  <tr>
                    <th class="column">Supplier Name</th>
                    <th>Display Name</th>
                    <th>Utility Name</th>
                    <th>State Code</th>
                    <th v-if="$route.query.id == '1'">Electric</th>
                    <th v-else>Gas</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(items, index) in item.data">
                    <td v-if="index === 0">{{ item.providername }}</td>
                    <td v-else></td>
                    <td>{{ items.displayName }}</td>
                    <td>{{ items.utilityName }}</td>
                    <td>{{ items.stateCode }}</td>

                    <td>
                      <label class="switch">
                        <input
                          type="checkbox"
                          :id="`${items.supplierId}, ${items.utilityReferenceId}`"
                          @change="
                            toggleCheckbox(
                              items?.supplierId,
                              items?.utilityReferenceId
                            )
                          "
                          :checked="checkIfEnabled(items.enabled)" />
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import constant from "../../constant";

export default {
  name: "ProviderUtility",

  data() {
    return {
      errors: [],
      usersdata: [],
      rowData: [],
      delete_id: "",
      addmessage: null,
      supplier_name: null,
      note: null,
      successMessageProfile: null,
      supplierData: null,
      edit_notes: null,
      backendURL: constant.apiUrl,
      i: 1,
      status_edit: 1,
      price_level_edit: 2,
      logo_url: "",
      selectedFile: "",
      supplierUtilityData: []
    };
  },
  watch: {
    $route(url) {
      this.getProviderUtility();
    }
  },

  created() {},

  mounted() {
    this.getProviderUtility();
  },
  methods: {
    checkIfEnabled(isEnabled) {
      return isEnabled === "TRUE" ? true : false;
    },

    async getProviderUtility(e) {
      $("#exp_loader").show();
      $("#showDataTable").hide();
      await axios({
        method: "post",
        url: constant.apiUrl + "/getProviderUtility",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token")
        },
        data: {
          productId: this.$route.query.id
        }
      })
        .then((result) => {
          if (result.status === 200) {
            result.data.supplierUtilityData.map((product, i) => {
              for (
                var index = 0;
                index < result.data.providerData.length;
                index++
              ) {
                if (
                  result.data.providerData[index].provider_id ===
                  product.supplierId
                ) {
                  if (!result.data.providerData[index].data) {
                    result.data.providerData[index].data = [];
                  }

                  let isInArray = false;

                  if (result.data.providerData[index].data) {
                    result.data.providerData[index].data.map((addedItem, i) => {
                      if (
                        addedItem.utilityReferenceId ==
                        product.utilityReferenceId
                      ) {
                        isInArray = true;
                      }
                    });
                  }

                  if ((isInArray == false)) {
                    result.data.providerData[index].data.push(product);
                    break;
                  } else {
                    break;
                  }

                }
              }
            });
            this.supplierUtilityData = result.data.providerData;
            $("#exp_loader").hide();
            $("#showDataTable").show();
          } else {
            this.errormessage = result.data;
            setTimeout(() => (this.errormessage = false), 3000);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },

    async toggleCheckbox(supplierId, utilityId) {
      if (document.getElementById(`${supplierId}, ${utilityId}`).checked) {
        //remove  sql table row to "enable"
        await axios({
          method: "delete",
          url: constant.apiUrl + "/enableUtility",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token")
          },
          data: {
            supplierId: supplierId,
            utilityId: utilityId
          }
        });
      } else {
        //add sql table row to "disable"
        await axios({
          method: "post",
          url: constant.apiUrl + "/disableUtility",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token")
          },
          data: {
            supplierId: supplierId,
            utilityId: utilityId
          }
        }).catch((err) => {
          console.log("error", err);
        });
      }
    }
  }
};
</script>

<style>
.dataTables_wrapper .dataTables_filter input {
  background-color: white;
}

.dataTables_wrapper .dataTables_length select {
  background-color: white;
}

div.dataTables_paginate {
  text-align: center;
}

.message {
  text-align: center;
}

.newWidth {
  width: 35% !important;
}
.my-customer th:nth-child(1),
.my-customer td:nth-child(1) {
  width: 25%;
  text-align: center;
}

.my-customer th:nth-child(2),
.my-customer td:nth-child(2) {
  width: 50%;
  text-align: center;
}
.my-customer th:nth-child(3),
.my-customer td:nth-child(3) {
  width: 25%;
  text-align: center;
}
*/ .my-customer th:nth-child(4),
.my-customer td:nth-child(4) {
  width: 20%;
  text-align: center;
}

.my-customer th:nth-child(5),
.my-customer td:nth-child(5) {
  width: 20%;
  text-align: center;
}
*/

/* Toggle Css Start*/
.off-switch {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #4e63af;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4e63af;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Toggle Css End*/
</style>
