<template>
  <section class="my-customer">
    <div id="exp_loader" style="text-align: center; margin-top: 120px">
      <img src="/images/exp_loader.gif" alt="" style="height: 80px; width: 80px" />
    </div>
    <div class="container" id="showDataTable" style="display: none">
      <div class="row">
        <div class="col-md-6 col-6">
          <h3>Matrix Upload List</h3>
        </div>

        <div class="col-md-6 col-6">
          <div class="add-remv">
            <button @click="showModel">+ Matrix Upload</button>
          </div>
        </div>

        <div class="col-md-12">
          <div class="cus-table">
            <div class="alert alert-success message" v-if="addmessage" role="alert">
              {{ addmessage }}
            </div>

            <div
              class="alert alert-success message"
              id="errorLoading"
              v-if="successMessageProfile"
              role="alert"
            >
              {{ successMessageProfile }}
            </div>

            <table style="text-align: center" id="myTable">
              <thead>
                <tr>
                  <th class="smallWidth">Supplier Name</th>
                  <th class="smallWidth">Matrix Name</th>
                  <th>Uploaded On</th>
                  <th>Path</th>
                  <th>Import Completed</th>
                  <th class="smallWidth">Uploaded By</th>
                </tr>
              </thead>

              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade common-pop"
      id="uploadModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="alert alert-success message"
            v-if="successMessagePassword"
            role="alert"
          >
            {{ successMessagePassword }}
          </div>

          <div
            class="alert alert-danger message"
            id="errorLoading"
            v-if="errorMessagePassword"
            role="alert"
          >
            {{ errorMessagePassword }}
          </div>

          <div class="modal-header">
            <div id="expoader2" class="addeditloader2" style="display: none">
              <img
                src="/images/exp_loader.gif"
                alt=""
                style="height: 80px; width: 80px"
              />
            </div>
            <h2>Upload Matrix</h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              @click="closeModal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="p-q">
              <form enctype="multipart/form-data" id="uploadMatrix">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group col-md-12" id="userTypeSelect">
                      <span
                        class="errorInput"
                        style="color: red"
                        v-for="error in errors"
                        :key="error.warning"
                      >
                        {{ error.warning }}
                      </span>
                    </div>

                    <input type="hidden" id="upload_energy_type" />

                    <input type="hidden" id="check_file" />

                    <div class="form-group col-md-12" id="userTypeSelect">
                      <label>Supplier</label>

                      <select
                        class="form-control"
                        id="supplierid"
                        @change="getAndSetOnChange($event)"
                      >
                        <option value="" selected="selected">
                          Please Select Supplier Name
                        </option>

                        <option
                          v-for="item in supplierData"
                          :value="item.id"
                          :key="item.id"
                        >
                          {{ item.providername }}
                        </option>
                      </select>
                      <span
                        class="errorInput"
                        style="color: red"
                        v-for="error in errors"
                        :key="error.supplier_id"
                      >
                        {{ error.supplier_id }}
                      </span>
                    </div>
                  </div>
                  <input
                    type="hidden"
                    class="form-control"
                    id="shortname"
                    ref="shortname"
                  />

                  <div class="col-lg-12">
                    <div class="form-group col-md-12" id="userTypeSelect">
                      <label>Matrix</label>

                      <select
                        class="form-control"
                        id="matrixid"
                        @change="getAndSetMatrix($event)"
                      >
                        <option value="" selected="selected">
                          Please Select Matrix Name
                        </option>

                        <option
                          v-for="item in matrixData"
                          :value="item.id"
                          :key="item.id"
                        >
                          {{ item.matrix_name }}
                        </option>
                      </select>
                      <span
                        class="errorInput"
                        style="color: red"
                        v-for="error in errors"
                        :key="error.matrix_id"
                      >
                        {{ error.matrix_id }}
                      </span>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Upload File</label>

                      <input
                        type="file"
                        @change="handleChange"
                        class="form-control"
                        id="file"
                      />
                      <span
                        class="errorInput"
                        id="excelFileError"
                        style="color: red"
                        v-for="error in errors"
                        :key="error.file"
                      >
                        {{ error.file }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group col-md-12">
                    <div class="c-btn">
                      <button class="delt" type="button" @click="closeModal">
                        Cancel
                      </button>

                      <button class="cncl" id="#target" @click="uploadMatrix">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade common-pop"
      id="changePasswordmodel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="alert alert-success message"
            v-if="successMessagePassword"
            role="alert"
          >
            {{ successMessagePassword }}
          </div>

          <div
            class="alert alert-danger message"
            id="errorLoading"
            v-if="errorMessagePassword"
            role="alert"
          >
            {{ errorMessagePassword }}
          </div>

          <div class="modal-header">
            <h2>Edit Supplier</h2>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              @click="closeModal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="p-q">
              <form>
                <div class="row">
                  <div class="col-lg-12">
                    <input
                      type="hidden"
                      id="supplier_id"
                      ref="supplier_id"
                      :value="rowData.id"
                    />

                    <div class="form-group">
                      <label>Supplier Name</label>

                      <input
                        type="text"
                        class="form-control"
                        id="supplier_name"
                        ref="supplier_name"
                      />
                    </div>

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.supplier_name"
                    >
                      {{ error.supplier_name }}
                    </span>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Deal with</label>

                      <div>
                        <input type="checkbox" id="check_electric2" />

                        <label class="checkboxLebel" style="margin-left: 5px">
                          Electric</label
                        >
                      </div>

                      <input type="checkbox" id="check_gas2" />

                      <label class="checkboxLebel" style="margin-left: 5px"> Gas</label>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Note</label>

                      <textarea
                        class="form-control"
                        rows="5"
                        placeholder="Note"
                        id="notes"
                        ref="notes"
                      >
                      </textarea>
                    </div>

                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.notes"
                    >
                      {{ error.notes }}
                    </span>
                  </div>

                  <div class="form-group col-md-12">
                    <div class="c-btn">
                      <button class="delt" type="button" @click="closeModal">
                        Cancel
                      </button>

                      <button class="cncl" @click="updateSupplier">Update</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import constant from "../../constant";
import moment from "moment";

export default {
  name: "Imports",

  data() {
    return {
      errors: [],
      usersdata: [],
      rowData: [],
      delete_id: "",
      addmessage: null,
      supplier_name: null,
      note: null,
      successMessageProfile: null,
      supplierData: [],
      matrixData: [],
    };
  },

  created() {
    this.getUsers();
  },

  methods: {
    getUsers(e) {
      axios({
        method: "get",
        url: constant.apiUrl + "/getImports",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: {},
      })
        .then((result) => {
          if (result.status === 200) {
            $("#exp_loader").hide();
            $("#showDataTable").show();
            $("#myTable").DataTable({
              lengthChange: false,
              language: { search: "" },
              bInfo: false,
              order: [[2, "desc"]],
              data: result.data.data,

              columns: [
                { data: "providername" },
                { data: "matrix_name" },

                {
                  render: function (data, type, row, meta) {
                    let uploadDate = `<span style="display:none;">${
                      row.uploaded_datetime
                    }</span>${moment
                      .utc(row.uploaded_datetime)
                      .format("MM/DD/YYYY,hh:mm:ss")}`;

                    return uploadDate;
                  },
                },
                {
                  render: function (data, type, row, meta) {
                    return `<a href=../` + row.path + ` download>` + row.path + `</a>`;
                  },
                },

                {
                  render: function (data, type, row, meta) {
                    let is_import = "";
                    if (row.is_import === 0) {
                      is_import = "No";
                    } else {
                      is_import = "Yes";
                    }
                    return is_import;
                  },
                },

                {
                  render: function (data, type, row, meta) {
                    return row.firstName + " " + row.lastName;
                  },
                },
              ],
            });

            $(".dataTables_filter").addClass("search");

            $(".dataTables_length").addClass("search");
          } else {
            this.errormessage = result.data;
            setTimeout(() => (this.errormessage = false), 3000);
          }
        })

        .catch((err) => {
          console.log("error", err);
        });
    },

    getAllSupplier(id) {
      axios({
        method: "get",
        url: constant.apiUrl + "/getProviders",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },

        data: {
          id: id,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.supplierData = result.data.data;
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    getAndSetOnChange(event) {
      $("#expnew_loader2").css("display", "block");

      var id = event.target.value;
      var supplierData = this.supplierData;

      const result = supplierData.filter((word) => word.id == id);
      $("#shortname").val(result[0].shortname);

      axios({
        method: "post",

        url: constant.apiUrl + "/getSupplierMatrix",

        headers: {
          "Content-Type": "application/json",

          "x-access-token": localStorage.getItem("token"),
        },

        data: {
          id: id,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            this.matrixData = result.data.data;
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    getAndSetMatrix(event) {
      $("#expnew_loader2").css("display", "block");
      var id = event.target.value;

      axios({
        method: "post",
        url: constant.apiUrl + "/getOneMatrix",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },

        data: {
          id: id,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            $("#upload_energy_type").val(result.data.data.energy_type);
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    handleChange(event) {
      $("#excelFileError").html("");
      this.selectedFile = event.target.files[0];
      const fileExtension = this.selectedFile.name.split(".")[1];
      if (
        $.inArray(fileExtension, [
          "xlsx",
          "xlsm",
          "xlsb",
          "xltx",
          "xltm",
          "xls",
          "xlt",
          "xlam",
          "xml",
          "xla",
          "xlw",
          "xlr",
          "XLSM",
          "XLS",
          "XLSX",
          "XLSB",
        ]) == -1
      ) {
        this.errors.push({ file: "Only Excel file  is allowed" });
        return false;
      }

      if (this.selectedFile) {
        this.blah = URL.createObjectURL(this.selectedFile);
        $(".image-box2").attr("src", this.blah);
      }
    },

    uploadMatrix(e) {
      e.preventDefault();
      this.errors = [];
      $("#excelFileError").html("");
      const supplier_id = $("#supplierid").val();
      const matrix_id = $("#matrixid").val();
      const shortname = $("#shortname").val();
      var check_file = $("#check_file").val();

      const file = $("#file").val();
      const energy_type = $("#upload_energy_type").val();
      var status = 1;

      if (!supplier_id) {
        this.errors.push({ supplier_id: "Supplier Name is required" });
        return false;
      }

      if (!matrix_id) {
        this.errors.push({ matrix_id: "Matrix Name is required" });
        return false;
      }

      if (!file) {
        this.errors.push({ file: "Excel file is required" });
        return false;
      }

      const fileExtension = this.selectedFile.name.split(".")[1];
      if (
        $.inArray(fileExtension, [
          "xlsx",
          "xlsm",
          "xlsb",
          "xltx",
          "xltm",
          "xls",
          "xlt",
          "xlam",
          "xml",
          "xla",
          "xlw",
          "xlr",
          "XLSM",
          "XLS",
          "XLSX",
          "XLSB",
        ]) == -1
      ) {
        this.errors.push({ file: "Only Excel file  is allowed" });
        return false;
      }

      const fileName = this.selectedFile.name.split(".")[0];
      const matrixName = $("#matrixid option:selected").text();

      if (fileName.indexOf(matrixName) === -1) {
        this.errors.push({ file: "File name not matched with matrix" });
        $("#excelFileError").html("File name not matched with matrix");
        return false;
      }

      $("#expoader2").show();

      var bodyFormData = new FormData();
      bodyFormData.append("supplier_id", supplier_id);
      bodyFormData.append("matrix_id", matrix_id);
      bodyFormData.append("shortname", shortname);
      bodyFormData.append("energy_type", energy_type);
      bodyFormData.append("file", this.selectedFile);
      bodyFormData.append("check_file", check_file ?? 0);
      bodyFormData.append("uploaded_by_id", localStorage.getItem("userId"));

      axios({
        method: "post",
        url: constant.apiUrl + "/uploadMatrix",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        data: bodyFormData,
      })
        .then((result) => {
          if (result.status === 200) {
            $("#expoader2").hide();
            window.scrollTo(0, 0);

            this.successMessageProfile = result.data.message;

            setTimeout(() => (this.successMessageProfile = false), 3000);

            this.closeModal();
            $("#check_file").val("");

            $("#myTable").DataTable().destroy();

            this.resetData();

            this.getUsers();
          } else if (result.status === 202) {
            let text = result.data.message;
            if (confirm(text) == false) {
              $("#expoader2").hide();
              return false;
            } else {
              $("#check_file").val(1);
              this.runnewfunction(e);
            }
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    async runnewfunction(e) {
      this.uploadMatrix(e);
    },

    ChangeEvent(event) {
      $(".unique_other_error").html("");

      var providername = event.target.value;

      var supplier_id = $("#supplier_id").val();

      axios({
        method: "post",

        url: constant.apiUrl + "/checkUniqueName",

        headers: {
          "Content-Type": "application/json",

          "x-access-token": localStorage.getItem("token"),
        },

        data: {
          providername: providername,

          id: supplier_id,
        },
      })
        .then((result) => {
          if (result.status === 200) {
            if (result.data.data.length > 0) {
              $("#supplier_name").after(
                '<span class="unique_other_error errorInput" style="color:red;">Supplier Name should be unique</span>'
              );

              $("html, #changePasswordmodel").animate(
                {
                  scrollTop: $("#supplier_name").offset().top,
                },
                500
              );

              $("#supplier_name").val("");

              return false;
            }
          }
        })

        .catch((err) => {
          console.log("Error", err);
        });
    },

    async ChangeEvent2(event) {
      var providername = event.target.value;

      var short_name_test = providername.replace(/[^A-Z0-9]/gi, "");

      $("#short_name2").val(short_name_test);
    },

    showModel() {
      $("#uploadMatrix").trigger("reset");
      $("#uploadModel").modal("show");
      this.getAllSupplier();
    },

    showEditModel(id) {
      $("#changePasswordmodel").modal("show");
      this.getOneSupplier(id);
    },

    closeModal() {
      this.resetData();
      $("#changePasswordmodel, #uploadModel").modal("hide");
    },

    resetData() {
      this.errors = [];
      this.supplier_id = "";
      this.supplier_name = "";
      this.notes = "";
      $("#uploadMatrix").trigger("reset");
      $("#supplier_name2, #supplier_name").val("");
      $("#short_name2, #short_name").val("");
      $("#notes2, #notes").val("");
      $(".unique_other_error").html("");
      $("#check_gas2").prop("checked", false);
      $("#check_electric2").prop("checked", false);
      $("#check_gas").prop("checked", false);
      $("#check_electric").prop("checked", false);
      $("#upload_energy_type").val("");
    },
  },

  mounted: function () {
    const table = $(this.$refs.table).dataTable();
    const self = this;
  },
};
</script>

<style>
.dataTables_wrapper .dataTables_filter input {
  background-color: white;
}

.dataTables_wrapper .dataTables_length select {
  background-color: white;
}

div.dataTables_paginate {
  text-align: center;
}

.message {
  text-align: center;
}

.newWidth {
  width: 50% !important;
}
.addeditloader2 {
  position: absolute;
  bottom: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
}
</style>
