<template>
  <div class="rate-table">
    <iframe
      id="EngieAR"
      width="100%"
      height="100%"
      src="https://docs.google.com/spreadsheets/d/1UgdpuzdQvvcQXBDp8rOEbuOTnNkaJY4I8TONXDtb4X0/edit?usp=sharing"></iframe>
  </div>
</template>
<script>
export default {
  name: "EngieApprovedRates",
  data() {
    return {};
  },
  mounted() {
    //const intervalId = setInterval(this.refreshRates, 60000);
  },
  methods: {
    refreshRates() {
      document.getElementById("EngieAR").src =
        document.getElementById("EngieAR").src;
    }
  }
};
</script>
<style>
.rate-table {
  height: 1000px;
}
</style>
