<template>
  <div class="container">
    <h2>404</h2>
    <h3>Oops, nothing here...</h3>
    <p>Please Check the URL</p>
    <p v-if="userRole == 1">
      Otherwise, <router-link to="/admin/users">Click here</router-link> to redirect to
      Manage Users.
    </p>
    <p v-if="userRole == 2">
      Otherwise, <router-link to="/pricequote">Click here</router-link> to redirect to
      Price Quote.
    </p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  created() {
    const role = JSON.parse(localStorage.getItem("role"));
    this.userRole = role;
  },
};
</script>

<style scoped>
body {
  font-family: "Merriweather", serif;
  margin: 0;
  text-align: center;
  color: white;
  user-select: none;
  padding-top: 18vh;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
h2 {
  font-size: 150px;
  margin: 0;
  text-shadow: 15px 5px 2px black;
}
h3 {
  font-size: 40px;
  margin: 20px;
}
p {
  font-size: 18px;
  margin: 5px;
}
p:last-of-type {
  margin-bottom: 35px;
}
a {
  text-decoration: none;
}
</style>
