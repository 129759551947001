<template>
    <div class="p-q lookup-wrapper">
        <h3 class="lookup-heading">Existing Account Lookup</h3>
        <form>
            <div class="row lookup-row">
                <div class="form-group col-3">
                    <select class="form-control" v-model="commodity">
                        <option value="" disabled selected>Please Select Commodity</option>
                        <option value="Electric">Electric</option>
                        <option value="Gas">Gas</option>
                    </select>
                    <div class="error" v-if="commodityMissing">Please select a commodity</div>
                </div>
                <div class="form-group col-6">
                    <input type="text" class="form-control" id="electic-customer-number"
                        placeholder="Please Enter Utility Account Number" v-model="accountNumber" />
                    <div class="error" v-if="accountNumberMissing">Please enter an account number</div>
                </div>
                <div class="form-group col-3">
                    <button type="submit" @click="lookupAcccountSF($event)">Search</button>
                </div>
            </div>
            <div v-if="isLoading">
                <div class="loader">
                    <h4>Searching for Accounts...</h4>
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div class="p-q" v-if="accountsFound">
                <h4>Found Account(s):</h4>
                <div class="row account-section p-q" v-for="account in foundAccounts">
                    <div class="row">
                        <div class="col-6">
                            <div><span class="account-label">Account:</span> <span class="account-name-data">{{
                                account[0].DL_Account__c }}</span></div>
                        </div>
                    </div>
                    <div>
                        <div class="account-row p-q" v-for="(meterNumber, index) in account">
                            <div class="row account-name-row">
                                <div class="col-7">
                                    <div class="account-name"><span class="account-label">Opportunity: #</span> {{
                                        index + 1
                                    }}
                                    </div>
                                </div>
                                <div class="col-5">
                                    <div class="account-name"><span class="utility-account-label">Utility Account
                                            Number:</span> {{
                                                meterNumber.Account_No__c
                                            }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col-4">
                                    <h6 class="label">Meter Status:</h6>
                                    <div>{{ meterNumber.Status__c }}</div>
                                </div>
                                <div class="col-4">
                                    <h6 class="label">Opportunity Status:</h6>
                                    <div>{{ meterNumber.Opportunity_Status__c }}</div>
                                </div>
                                <div class="col-4">
                                    <h6 class="label">Utility:</h6>
                                    <div>{{ meterNumber.DL_Utility_Name__c }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import axios from "axios";
import constant from "../constant";
export default {
    name: "AccountLookup",

    data() {
        return {
            accountNumber: null,
            commodity: "",
            accountNumberMissing: false,
            commodityMissing: false,
            foundAccounts: {},
            accountsFound: false,
            isLoading: false
        };
    },
    methods: {
        lookupAcccountSF(event) {
            event.preventDefault();
            this.resetForm();
            var formValid = true;
            if (!!!this.accountNumber) {
                this.accountNumberMissing = true;
                formValid = false;
            }
            if (!!!this.commodity) {
                this.commodityMissing = true;
                formValid = false;
            }
            if (formValid) {
                this.isLoading = true;
                axios({
                    method: "post",
                    url: constant.apiUrl + "/sfMeterNumberLookup",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": localStorage.getItem("token"),
                    },
                    data: {
                        accountNo: this.accountNumber,
                        commodity: this.commodity
                    },
                })
                    .then((result) => {
                        if (result.status === 200) {
                            if (result.data.success === true) {
                                this.foundAccounts = result.data.data;
                                this.sortAccounts();
                                this.filterAccounts();
                                this.isLoading = false;
                                this.accountsFound = true;
                            } else if (result.data.success === false) {
                                alert("No Accounts Found");
                            }
                        } else {
                            alert("something went wrong");
                        }
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        alert("something went wrong");
                    });
            }
        },
        resetForm() {
            this.accountNumberMissing = false;
            this.commodityMissing = false;
            this.foundAccounts = {},
            this.accountsFound = false;
        },
        sortAccounts() {
            for (var prop in this.foundAccounts) {
                this.foundAccounts[prop].sort((a, b) => {
                    if (a.CreatedDate < b.CreatedDate) {
                        return 1;
                    }
                    else if (a.CreatedDate > b.CreatedDate) {
                        return -1;
                    }
                    return 0;
                })
            }
        },
        filterAccounts() {
            let acceptableStatuses = ["Submitted", "Confirmed", "Rejected","Cleared", "Submitted Reject", "Confirmed Reject", "Cancel Re-Do"]
            for (var prop in this.foundAccounts){
                this.foundAccounts[prop] = this.foundAccounts[prop].filter((mn) => {
                    if (acceptableStatuses.includes(mn.Status__c)){
                        return true
                    }
                })
                if (this.foundAccounts[prop].length < 1){
                    delete this.foundAccounts[prop]
                }
            }
        }
    },
};
</script>
<style>
.lookup-wrapper {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    margin-top: 0px;
}

.lookup-heading {
    margin: 0px;
}

.lookup-row {
    margin: 0px !important;
}

.error {
    color: red;
}

.label {
    font-weight: bold;
}

.account-section {
    margin-top: 20px;
}

.account-label {
    margin-top: 0px !important;
    font-size: 1.2rem;
    font-weight: bold;
    padding-right: 10px;
}

.utility-account-label {
    margin-top: 0px !important;
    font-size: 1.2rem;
    font-weight: bold;
    padding-right: 10px;
}


.account-name-row {
    border-bottom: 1px black solid;
}

.account-row {
    margin: 5px !important;
    padding: 10px !important;
}

.account-name-data {
    font-size: 1.25rem;
}

.utility-account-name-data {
    font-size: 1.25rem;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid green;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: green transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}</style>