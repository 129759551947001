<template>
  <section class="my-customer">
    <div id="utility_loader" style="text-align: center; margin-top: 120px">
      <img
        src="/images/exp_loader.gif"
        alt=""
        style="height: 80px; width: 80px" />
    </div>
    <div class="container" id="utilityDataTable" style="display: none">
      <div class="row">
        <div class="col-md-6 col-6">
          <h3>CONTRACT PACKAGES</h3>
        </div>
        <div class="col-md-12">
          <div class="cus-table">
            <div
              class="alert alert-success message"
              v-if="addmessage"
              role="alert">
              {{ addmessage }}
            </div>
            <table style="text-align: center" id="myTable">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Package</th>
                  <th>Usage Start</th>
                  <th>Usage End</th>
                  <th>Demand</th>
                  <th>Service Address Count</th>
                  <th>Electric</th>
                  <th>Gas</th>
                  <th>Supplier</th>
                  <th>State</th>
                  <th>Utility</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- edit Modal -->
    <div
      class="modal fade common-pop add-us"
      id="editModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Contract Package</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              @click="closeModal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="p-q">
              <form id="editForm">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label>Package Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Please Enter Package Name"
                      id="packageName"
                      ref="packageName" />
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.packageName">
                      {{ error.packageName }}
                    </span>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Usage Start</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Please Enter Usage Start"
                      id="usageStart"
                      ref="usageStart" />
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.usageStart">
                      {{ error.usageStart }}
                    </span>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Usage End</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Please Enter Usage End"
                      id="usageEnd"
                      ref="usageEnd" />
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.usageEnd">
                      {{ error.usageEnd }}
                    </span>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Service Address Count</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Please Enter Service Address Count"
                      id="serviceAddressCount"
                      ref="serviceAddressCount" />
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.serviceAddressCount">
                      {{ error.serviceAddressCount }}
                    </span>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Status</label>
                    <select class="form-control" id="status" ref="status">
                      <option value="1">Enabled</option>
                      <option value="0">Disabled</option>
                    </select>
                    <span
                      class="errorInput"
                      style="color: red"
                      v-for="error in errors"
                      :key="error.status">
                      {{ error.status }}
                    </span>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Demand</label>
                    <select class="form-control" id="demand" ref="demand">
                      <option value="2" selected>None</option>
                      <option value="0">Below 50KW</option>
                      <option value="1">Above 50KW</option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label>State</label>
                    <select class="form-control" id="state" ref="state">
                      <option value="0" selected>None</option>

                      <option
                        v-for="item in statesData"
                        :value="item.StateCode"
                        :key="item.StateCode">
                        {{ item.State }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Utility</label>
                    <select class="form-control" id="utility" ref="utility">
                      <option value="0" selected>None</option>
                      <option
                        v-for="item in utilitiesData"
                        :value="item.UtilityId"
                        :key="item.UtilityId">
                        {{ item.UtilityName }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Commodity</label>
                    <div>
                      <input type="checkbox" id="check_electric" /><label
                        class="checkboxLebel"
                        style="margin-left: 5px">
                        Electric</label
                      >
                    </div>
                    <input type="checkbox" id="check_gas" /><label
                      class="checkboxLebel"
                      style="margin-left: 5px">
                      Gas</label
                    >
                  </div>

                  <div class="form-group col-md-12">
                    <div class="c-btn">
                      <button class="delt" @click="closeModal" type="button">
                        Cancel
                      </button>
                      <button class="cncl" type="submit" @click="updatePackage">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- edit Modal -->
  </section>
</template>

<script>
import axios from "axios";
import constant from "../../constant";
import VueMultiselect from "vue-multiselect";

export default {
  name: "ContractPackages",
  components: {
    VueMultiselect
  },
  data() {
    return {
      utilityData: [],
      delete_id: "",
      addmessage: null,
      utilityId: "",
      errors: [],
      successMessagePopup: null,
      errorMessagePopup: null,
      statesData: [],
      utilitiesData: [],
      delete_id: null,
      selected: null,
      addParentUtilities: []
    };
  },

  created() {
    this.getContractPackags();
  },

  mounted: function () {
    const table = $(this.$refs.table).dataTable();
    const self = this;

    $("tbody", this.$refs.table).on("click", ".edit-data", function (e) {
      const id = $(this).attr("data-id");
      self.getSingleContractPackage(id);
    });

    $("tbody", this.$refs.table).on(
      "click",
      ".remove-merge-data",
      function (e) {
        const providerId = $(this).attr("data-id");
        if ($(this).attr("data-parent-id") === "0") {
          alert("There is no Parent Utility found");
          return false;
        }
        self.removeMergeApproveRate(providerId);
      }
    );
  },
  methods: {
    getContractPackags(e) {
      axios({
        method: "get",
        url: constant.apiUrl + "/getContractPackags",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token")
        },
        data: {}
      })
        .then((result) => {
          if (result.status === 200) {
            this.statesData = result.data.state;
            this.utilitiesData = result.data.utility;
            let a = 1;
            $("#utility_loader").hide();
            $("#utilityDataTable").show();

            $("#myTable").DataTable({
              lengthChange: false,
              language: {
                search: ""
              },
              bInfo: false,
              data: result.data.data,
              columns: [
                {
                  render: function (data, type, row, meta) {
                    return a++;
                  }
                },
                {
                  data: "Name"
                },

                {
                  data: "usage_band_start"
                },
                {
                  data: "usage_band_end"
                },
                {
                  render: function (data, type, row, meta) {
                    let status = "";
                    if (row.demand === 0) {
                      status = "Below 50 KW";
                    } else if (row.demand === 1) {
                      status = "Above 50 KW";
                    } else {
                      status = "";
                    }
                    return status;
                  }
                },

                {
                  data: "service_address_count"
                },

                {
                  render: function (data, type, row, meta) {
                    let status = "";
                    if (row.electric === 1) {
                      status = "Yes";
                    } else {
                      status = "No";
                    }
                    return status;
                  }
                },
                {
                  render: function (data, type, row, meta) {
                    let status = "";
                    if (row.gas === 1) {
                      status = "Yes";
                    } else {
                      status = "No";
                    }
                    return status;
                  }
                },

                {
                  data: "providername"
                },

                {
                  data: "stateName"
                },

                {
                  data: "UtilityName"
                },

                {
                  render: function (data, type, row, meta) {
                    let status = "";
                    if (row.Enabled === 1) {
                      status = "Enabled";
                    } else {
                      status = "Disabled";
                    }
                    return status;
                  }
                },

                {
                  data: "",
                  name: "",
                  orderable: false,
                  searchable: false,
                  render: function (data, type, row, meta) {
                    let button =
                      `<div class="e-d">
                      <a href="#"class="edit-data" data-id=` +
                      row.Id +
                      `><img src="/images/edit.png" /></a
                      >
                    </div>`;
                    return button;
                  }
                }
              ]
            });
            if ($(window).width() < 1260) {
              $("#myTable").wrapAll(
                '<div style="overflow: scroll;width: 100%">'
              );
            }
            $(".dataTables_filter").addClass("search");
            $(".dataTables_length").addClass("search");
          } else {
            this.errormessage = result.data;
            setTimeout(() => (this.errormessage = false), 3000);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },

    updatePackage(e) {
      e.preventDefault();
      this.errors = [];
      if (!this.$refs["packageName"].value) {
        this.errors.push({ packageName: "Package Name is required" });
        return false;
      }
      if (!this.$refs["usageStart"].value) {
        this.errors.push({ usageStart: "Usage Start is required" });
        return false;
      }
      if (!this.$refs["usageEnd"].value) {
        this.errors.push({ usageEnd: "Usage End is required" });
        return false;
      }
      if (!this.$refs["serviceAddressCount"].value) {
        this.errors.push({
          serviceAddressCount: "Service Address Count is required"
        });
        return false;
      }

      var gas = $("#check_gas").is(":checked");

      if (gas == true) {
        gas = 1;
      } else {
        gas = 0;
      }
      var electric = $("#check_electric").is(":checked");

      if (electric == true) {
        electric = 1;
      } else {
        electric = 0;
      }

      axios({
        method: "post",
        url: constant.apiUrl + "/updatePackage",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token")
        },
        data: {
          contractId: this.contractId,
          packageName: this.$refs["packageName"].value,
          usageStart: this.$refs["usageStart"].value,
          usageEnd: this.$refs["usageEnd"].value,
          serviceAddressCount: this.$refs["serviceAddressCount"].value,
          demand: this.$refs["demand"].value,
          status: this.$refs["status"].value,
          gas: gas,
          electric: electric,
          utility: this.$refs["utility"].value,
          state: this.$refs["state"].value
        }
      })
        .then((result) => {
          if (result.status === 200) {
            this.addmessage = result.data.message;
            setTimeout(() => (this.addmessage = false), 3000);
            $("#editForm").trigger("reset");
            $("#editModel").modal("hide");
            $("#myTable").DataTable().destroy();
            this.getContractPackags();
            // this.errorMessagePopup = result.data.message;
            // setTimeout(() => (this.errorMessagePopup = false), 3000);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    getSingleContractPackage(id) {
      this.contractId = id;
      axios({
        method: "post",
        url: constant.apiUrl + "/getSingleContractPackage",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token")
        },
        data: {
          contractId: this.contractId
        }
      })
        .then((result) => {
          if (result.status === 200) {
            $("#packageName").val(result.data.data.Name);
            $("#usageStart").val(result.data.data.usage_band_start);
            $("#usageEnd").val(result.data.data.usage_band_end);
            $("#serviceAddressCount").val(
              result.data.data.service_address_count
            );
            if (
              result.data.data.demand !== 0 &&
              result.data.data.demand !== 1
            ) {
              $("#demand").val("2");
            } else {
              $("#demand").val(result.data.data.demand);
            }
            $("#status").val(result.data.data.Enabled);
            if (result.data.data.gas == 1) {
              $("#check_gas").prop("checked", true);
            }
            if (result.data.data.electric == 1) {
              $("#check_electric").prop("checked", true);
            }
            $("#state").val(result.data.data.stateCode);
            $("#utility").val(result.data.data.UtilityId);
            $("#editModel").modal("show");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    closeModal() {
      $("#editModel").modal("hide");
      $(".form-control").val("");
      this.errors = [];
      this.resetData();
    },

    resetData() {
      this.errors = [];
      $("#utilitie ,#edit_utilitie_name").val();
      $("#display_name, #edit_display_name").val();
      $("#notes_add, #edit_notes").val();
      $("#states_add, #edit_State_ID").val();
      $("#status_add, #edit_status").val();
      $("#UserIdAdded").val("");
      $("#utilityId").val("");
      $("#merge_provider_id").val("");
      $("#merge_utility").val("");
      $("#check_gas").prop("checked", false);
      $("#check_electric").prop("checked", false);
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.dataTables_wrapper .dataTables_filter input {
  background-color: white;
}

.dataTables_wrapper .dataTables_length select {
  background-color: white;
}
div.dataTables_paginate {
  text-align: center;
}

.merge-data img {
  width: 39px;
  height: 40px;
  margin-right: 5px;
  margin-left: 5px;
}
</style>
