<template>
  <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <meta name="description" content="" />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/images/favicon/apple-touch-icon.png" />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/images/favicon/favicon-32x32.png" />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/images/favicon/favicon-16x16.png" />
    <link rel="manifest" href="images/favicon/site.webmanifest" />
    <title>Price Our Power</title>
  </head>

  <body>
    <wrapper class="d-flex flex-column">
      <section class="login">
        <div class="left-side">
          <div class="l-contnt">
            <img src="/images/login-img.png" class="img-fluid" />
            <h3>Turn your ideas into reality.</h3>
          </div>
        </div>
        <div class="right-side">
          <div
            class="alert alert-danger alert-message"
            id="errorLoading"
            v-if="errormessage"
            role="alert">
            {{ errormessage }}
          </div>
          <div class="r-contnt">
            <img src="/images/logo.png" />

            <h5>Welcome to Integrity Energy</h5>
            <h4>Pricing Portal</h4>
            <form>
              <div class="form-group">
                <label>User Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="User Name"
                  v-model="userName" />
                <span
                  style="color: red"
                  v-for="error in errors"
                  :key="error.userName"
                  >{{ error.userName }}</span
                >
              </div>
              <div class="form-group">
                <label>Password</label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="*********"
                  v-model="password" />
                <span
                  style="color: red"
                  v-for="error in errors"
                  :key="error.password"
                  >{{ error.password }}</span
                >
              </div>
              <button type="submit" @click="login">Login</button>
            </form>
          </div>
        </div>
      </section>
    </wrapper>
  </body>
</template>

<script>
import axios from "axios";
import constant from "../constant";

export default {
  name: "Login",
  data() {
    return {
      errors: [],
      errormessage: null,
      userName: null,
      password: null,
      baseURL: constant.baseUrl
    };
  },
  created() {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    localStorage.removeItem("userData");
  },
  mounted: function () {
    $(".owl-carousel").owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      nav: false,
      dots: true,
      loop: false,
      animateIn: "fadeIn",
      animateOut: "fadeOut",
      slideSpeed: 300,
      paginationSpeed: 900,
      autoPlay: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    });
  },
  methods: {
    login(e) {
      e.preventDefault();
      this.errors = [];
      if (!this.userName) {
        this.errors.push({
          userName: "User Name is required"
        });
        return false;
      }
      if (!this.password) {
        this.errors.push({
          password: "Password is required"
        });
        return false;
      }

      $("#addedit_loader").css("display", "block");

      const data = axios({
        method: "post",
        url: constant.apiUrl + "/login",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          userName: this.userName,
          password: this.password
        }
      })
        .then((result) => {
          console.log("Result: " + JSON.stringify(result));
          if (result.status === 200) {
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("role", "2");
            localStorage.setItem("userId", JSON.stringify(result.data.data.id));
            localStorage.setItem(
              "firstName",
              JSON.stringify(result.data.data.firstName)
            );
            localStorage.setItem(
              "lastName",
              JSON.stringify(result.data.data.lastName)
            );

            localStorage.setItem(
              "salesforceContactId",
              JSON.stringify(result.data.data.salesforceContactId)
            );

            this.$router.push("/pricequote");
          } else {
            this.errormessage = result.data;
            setTimeout(() => (this.errormessage = false), 3000);
          }
        })
        .catch((err) => {
          console.log("error", err.message);
        });
    }
  }
};
</script>
