const apiUrl = "https://www.priceourpower.com/api"; //Live
const backendUrl = "https://www.priceourpower.com/"; //Live
//const backendUrl = "http://localhost:9199/";//Live
//const baseUrl = "http://localhost:9198";
const baseUrl = "https://www.priceourpower.com";
const copyRight = new Date().getFullYear() + " © All rights reserved by Integrity Energy.";

export default {
  apiUrl: apiUrl,
  baseUrl: baseUrl,
  backendUrl: backendUrl,
  copyRight: copyRight
};
