<template>
  <div class="rate-table">
    <iframe
      id="sparkAR"
      width="100%"
      height="100%"
      src="https://docs.google.com/spreadsheets/d/1GdESeJ4iBKPcauXfBdAzyLNEfpXrbgbxxezEd46FSPA/edit?usp=sharing"></iframe>
  </div>
</template>
<script>
export default {
  name: "SparkApprovedRates",
  data() {
    return {};
  },
  mounted() {
    //const intervalId = setInterval(this.refreshRates, 60000);
  },
  methods: {
    refreshRates() {
      document.getElementById("sparkAR").src =
        document.getElementById("sparkAR").src;
    }
  }
};
</script>
<style>
.rate-table {
  height: 1000px;
}
</style>
