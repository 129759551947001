<template>
    <footer>
	    <div class="container">
			<div class="row">
				<div class="col-md-12 text-center">
					<p>{{copyRight}}</p>
				</div>
			</div>
		</div>
	</footer>
</template>
<script>
    import constant from '../../constant'
	export default {
	name:'Footer',
		data() {
		return{
			copyRight:constant.copyRight,
			}
		},
	}
</script>