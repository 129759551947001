<template>
  <div id="nav">
    <div v-if="currentRouteName === 'login' || currentRouteName === 'adminlogin'">
      <router-view></router-view>
    </div>
    <div v-else>
      <Header />
      <body id="dashboard">
        <wrapper class="d-flex flex-column">
          <Navbar />
          <router-view />
          <Footer />
        </wrapper>
      </body>
    </div>
  </div>
</template>

<script>
import Header from "./components/layouts/Header.vue";
import Footer from "./components/layouts/Footer.vue";
import Navbar from "./components/layouts/Navbar.vue";

export default {
  components: {
    Header,
    Footer,
    Navbar
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>