<template>
  <section class="dash-main">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="dash">
            <div class="dash-cn">
              <h1>Dashboard</h1>
              <h6>Overview</h6>
            </div>

            <div class="slt-bx">
              <select>
                <option>This Month</option>
                <option value="0">1</option>
                <option value="0">1</option>
                <option value="0">1</option>
                <option value="0">1</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="white-port">
            <a href="#">
              <div class="white-cnt">
                <h5>
                  <img src="/images/cht-icn.png" />
                  <p>Number of <br />Quotes <span>Last Month Quotes</span></p>
                </h5>

                <div class="rcrd">
                  <div class="up">
                    <h4>+58% <img src="/images/up.png" /></h4>
                    <h6>On Track</h6>
                  </div>

                  <div class="down">
                    <h4>-42% <img src="/images/down.png" /></h4>
                    <h6>Below</h6>
                  </div>
                </div>
              </div>

              <div class="chrt">
                <img src="/images/chart-1.png" />
              </div>
            </a>
          </div>
        </div>

        <div class="col-md-4">
          <div class="white-port cntr">
            <a href="#">
              <div class="white-cnt">
                <h5>
                  <img src="/images/cht-icn-2.png" />
                  <p>New <br />Quotes <span>Last Week Quotes</span></p>
                </h5>

                <div class="t-b">
                  <div class="grn"><img src="/images/grn.png" /> On Track</div>
                  <div class="wht"><img src="/images/wht.png" /> Below</div>
                </div>
              </div>

              <div class="chrt">
                <img src="/images/chart-2.png" />
              </div>
            </a>
          </div>
        </div>

        <div class="col-md-4">
          <div class="white-port">
            <a href="#">
              <div class="white-cnt">
                <h5>
                  <img src="/images/cht-icn-3.png" />
                  <p>Total <br />Quotes <span>Last Year Quotes</span></p>
                </h5>

                <div class="rcrd">
                  <div class="up">
                    <h4>+65% <img src="/images/up.png" /></h4>
                    <h6>On Track</h6>
                  </div>

                  <div class="down">
                    <h4>-35% <img src="/images/down.png" /></h4>
                    <h6>Below</h6>
                  </div>
                </div>
              </div>

              <div class="chrt">
                <img src="/images/chart-3.png" />
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="row secnd">
        <div class="col-md-8">
          <div class="white-port">
            <div class="dash">
              <div class="dash-cn">
                <h1>Top 5 Quotations</h1>
              </div>

              <div class="slt-bx">
                <select>
                  <option>Sort By</option>
                  <option value="0">1</option>
                  <option value="0">1</option>
                  <option value="0">1</option>
                  <option value="0">1</option>
                </select>
              </div>
            </div>

            <div class="tbl-prt">
              <table>
                <tr>
                  <th>Company</th>
                  <th>Name / Email</th>
                  <th>Rates</th>
                  <th>Terms</th>
                  <th></th>
                </tr>
                <tr>
                  <td><img src="/images/t-1.png" /></td>
                  <td>Edmond Bertrand <span>Ed.be@gmail.com</span></td>
                  <td>(kwh) <span>.09856</span></td>
                  <td>36 Months</td>
                  <td><button class="tbl-btn">Sign Up</button></td>
                </tr>
                <tr>
                  <td><img src="/images/t-2.png" /></td>
                  <td>Vesta Florence <span>vesta.fl@gmail.com</span></td>
                  <td>(kwh) <span>.10345</span></td>
                  <td>30 Months</td>
                  <td><button class="tbl-btn">Sign Up</button></td>
                </tr>
                <tr>
                  <td><img src="/images/t-2.png" /></td>
                  <td>Johnna Althouse <span>johnna.al@gmail.com</span></td>
                  <td>(kwh) <span>.11756</span></td>
                  <td>30 Months</td>
                  <td><button class="tbl-btn">Sign Up</button></td>
                </tr>
                <tr>
                  <td><img src="/images/t-1.png" /></td>
                  <td>Betsy Black <span>betsy.bl.fl@gmail.com</span></td>
                  <td>(kwh) <span>.12345</span></td>
                  <td>24 Months</td>
                  <td><button class="tbl-btn">Sign Up</button></td>
                </tr>
                <tr>
                  <td><img src="/images/t-1.png" /></td>
                  <td>Debra Smith <span>debra.s@gmail.com</span></td>
                  <td>(kwh) <span>.13445</span></td>
                  <td>12 Months</td>
                  <td><button class="tbl-btn">Sign Up</button></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="white-port customer">
            <div class="dash">
              <div class="dash-cn">
                <h1>My Customers</h1>
              </div>

              <div class="slt-bx">
                <select>
                  <option>This Month</option>
                  <option value="0">1</option>
                  <option value="0">1</option>
                  <option value="0">1</option>
                  <option value="0">1</option>
                </select>
              </div>
            </div>

            <div class="cust-img">
              <img src="/images/my-cust.png" />
            </div>

            <div class="rcrd">
              <img src="/images/graph.png" />
              <div class="up">
                <h4><img src="/images/up.png" /> +65%</h4>
                <h6>Projection is higher then previous year.</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import constant from "../constant";

export default {
  name: "Dashboard",

  data() {
    return {};
  },
};
</script>