<template>
  <div class="rate-table">
    <iframe
      id="SFEAR"
      width="100%"
      height="100%"
      src="https://docs.google.com/spreadsheets/d/1XjD3k6xdg34qTT4HGijaNVwb7VRmkjjYSI8k08IZKI4/edit?usp=sharing"></iframe>
  </div>
</template>
<script>
export default {
  name: "SFEApprovedRates",
  data() {
    return {};
  },
  mounted() {
    //const intervalId = setInterval(this.refreshRates, 60000);
  },
  methods: {
    refreshRates() {
      document.getElementById("SFEAR").src =
        document.getElementById("SFEAR").src;
    }
  }
};
</script>
<style>
.rate-table {
  height: 1000px;
}
</style>
